import { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import CardView from 'components/Layout/CardView';
import CreateMessageTemplate from 'pages/Settings/MessageTemplates/CreateMessageTemplate';
import List from './List';
import { useSelector, useDispatch } from 'react-redux';
import { MessageTemplateMode } from 'utils/enums';
import { setMessageTemplateMode } from 'redux/actions/messageTemplates';

const { TabPane } = Tabs;

const MessageTemplates = () => {
    const messageTemplateState = useSelector(state => state.messageTemplates);
    const [draw, setDraw] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setMessageTemplateMode(MessageTemplateMode.LIST));
        }
    }, []);

    const localActiveKey = () => {
        switch(messageTemplateState.mode) {
            case MessageTemplateMode.CREATE: return '2';
            case MessageTemplateMode.EDIT: return '2';
            case MessageTemplateMode.LIST: return '1';
        }
    }
    
    return  <CardView>
                <Tabs
                    className='messageTemplateTab'
                    renderTabBar={null}
                    activeKey={localActiveKey()}
                    animated
                >
                    <TabPane tab="List" key="1">
                        <List
                            draw={draw}
                            setDraw={setDraw}
                        />
                    </TabPane>
                    <TabPane tab="Create" key="2">
                        <CreateMessageTemplate
                            draw={draw}
                            setDraw={setDraw}
                        />
                    </TabPane>
                </Tabs>
            </CardView>;
};

export default MessageTemplates;
