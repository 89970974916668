import React from 'react';
import { Route } from 'react-router';
import { useUserAuthentication } from 'hooks/useUserAuthentication';

const AuthenticatedRoute = ({
  onAuthenticated,
  onUnauthenticated,
  ...props
}) => {
  const { authenticated } = useUserAuthentication();

  return (
    <Route {...props}>
      {!authenticated && onUnauthenticated()}
      {authenticated && onAuthenticated()}
      {/* do nothing if isAuthenticated is null*/}
    </Route>
  );
};

export default AuthenticatedRoute;
