import React from 'react';
import ImagePreviewUploader from 'components/ImagePreview';

const UploadLogo = ({ branding, uploadButtonVisible = true }) => {
    return  <>
                <ImagePreviewUploader
                    branding={branding}
                    uploadButtonVisible={uploadButtonVisible}
                />
            </>;
};

export default UploadLogo;
