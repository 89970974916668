import React, { useEffect, useState } from 'react';

import { getAllSettings } from 'api/systemSettings';

import { Container as BsContainer, Row, Col } from 'react-bootstrap';
import { Button as AntdButton, message } from 'antd';

import Text from 'pages/FormBuilder/components/Text';
import Input from 'pages/FormBuilder/components/Input';
import Select from 'pages/FormBuilder/components/Select';
import Panel from 'pages/FormBuilder/components/Panel';
import Grid from 'pages/FormBuilder/components/Grid';
import Column from 'pages/FormBuilder/components/Column';
import ContainerRow from 'pages/FormBuilder/components/ContainerRow';

import { Element, useEditor } from '@craftjs/core';

import {
  BiRectangle,
  VscSymbolString,
  CgFormatText,
  MdVideoLabel,
  ImRadioChecked,
  CgSelectR,
  MdCheckBox,
  BsBoundingBoxCircles,
  AiOutlineCreditCard,
  RiLayoutColumnLine,
  ImCalendar,
  FiSquare,
  BsLayoutThreeColumns,
  IoMdMove,
  ImMenu,
  MdFormatListNumbered,
  CgViewGrid,
} from 'react-icons/all';

let toolboxItems = [
  // {
  //     id: 'Button',
  //     content: <Button title='Submit' />,
  //     isCanvas: true,
  //     title: 'Button',
  //     icon: <BiRectangle className='Toolbar-Icon' />,
  // },
  {
    id: '1Column',
    component: ContainerRow,
    content: (
      <Element id='1Column' is={ContainerRow} canvas>
        <Element id='Column' is={Column} canvas />
      </Element>
    ),
    isCanvas: true,
    title: '1-Column',
    icon: <FiSquare className='Toolbar-Icon' />,
  },
  {
    id: '2Columns',
    component: ContainerRow,
    content: (
      <Element id='2Columns' is={ContainerRow} canvas>
        <Element id='Column' is={Column} canvas />
        <Element id='Column' is={Column} canvas />
      </Element>
    ),
    isCanvas: true,
    title: '2-Columns',
    icon: <RiLayoutColumnLine className='Toolbar-Icon' />,
  },
  {
    id: '3Columns',
    component: ContainerRow,
    content: (
      <Element id='3Columns' is={ContainerRow} canvas>
        <Element id='Column' is={Column} canvas />
        <Element id='Column' is={Column} canvas />
        <Element id='Column' is={Column} canvas />
      </Element>
    ),
    isCanvas: true,
    title: '3-Columns',
    icon: <BsLayoutThreeColumns className='Toolbar-Icon' />,
  },
  {
    id: 'Container',
    component: ContainerRow,
    content: <Element id='Container' is={Panel} canvas />,
    isCanvas: true,
    title: 'Section',
    icon: <AiOutlineCreditCard className='Toolbar-Icon' />,
  },
  {
    id: 'Text',
    content: <Text />,
    isCanvas: false,
    title: 'Text',
    icon: <CgFormatText className='Toolbar-Icon' />,
  },
  {
    id: 'TextField',
    content: <Input />,
    isCanvas: false,
    title: 'Text Field',
    icon: <VscSymbolString className='Toolbar-Icon' />,
  },
  {
    id: 'NumberField',
    content: <Input type='number' />,
    isCanvas: false,
    title: 'Number Field',
    icon: <MdFormatListNumbered className='Toolbar-Icon' />,
  },
  {
    id: 'Select',
    content: <Select />,
    isCanvas: false,
    title: 'Select',
    icon: <CgSelectR className='Toolbar-Icon' />,
  },
  {
    id: 'DatePicker',
    content: <Input type='datetime' />,
    isCanvas: false,
    title: 'Date Picker',
    icon: <ImCalendar className='Toolbar-Icon' />,
  },
  {
    id: 'RadioButton',
    content: <Input type='radio' />,
    isCanvas: false,
    title: 'Radio Button',
    icon: <ImRadioChecked className='Toolbar-Icon' />,
  },
  {
    id: 'Checkbox',
    content: <Input type='checkbox' />,
    isCanvas: false,
    title: 'Checkbox',
    icon: <MdCheckBox className='Toolbar-Icon' />,
  },
  {
    id: 'Grid',
    component: ContainerRow,
    content: <Element id='Container' is={Grid} canvas />,
    isCanvas: true,
    title: 'Grid',
    icon: <CgViewGrid className='Toolbar-Icon' />,
  },
];



const Toolbox = () => {
  const { connectors, query } = useEditor();
  const [permittedToolboxItems, setPermittedToolboxItems] = useState(toolboxItems);

  useEffect(() => {
    fetchFormSettings();
  }, []);

  const fetchFormSettings = async () => {
    try { 
        const response = await getAllSettings('FORMS');
        const formSettings = response.data?.FORMS;

        if (formSettings) {
            const enableGridComponentProperty = formSettings.find(formSetting => (
                formSetting.name === 'ENABLE_GRID_COMPONENT'
            ));

            if (enableGridComponentProperty
                && (enableGridComponentProperty?.value !== ''
                && enableGridComponentProperty?.value !== 'true')) {
                    const newPermittedToolboxItems = permittedToolboxItems.filter(permittedToolboxItem => (
                        permittedToolboxItem.id !== 'Grid'
                    ));

                    setPermittedToolboxItems(newPermittedToolboxItems);
                }
        }
    } catch (err) {
        message.error('Failed to fetch system settings');
    }
  };

  return (
    <div className='Form-Builder-Toolbox'>
      <BsContainer fluid>
        <Row>
          <Col>
            <h5 style={{ fontWeight: '800' }}>COMPONENTS</h5>
          </Col>
        </Row>
        <Row>
          {permittedToolboxItems &&
            permittedToolboxItems &&
            permittedToolboxItems.map((toolboxItem) => (
              <Col
                xs={12}
                key={toolboxItem.id}
                ref={(ref) => connectors.create(ref, toolboxItem.content)}
              >
                <AntdButton
                  className='Toolbar-Item'
                  size='large'
                  style={{
                    width: '100%',
                    marginTop: '5px',
                    marginBottom: '5px',
                    textAlign: 'left',
                  }}
                >
                  {toolboxItem.icon} {toolboxItem.title}
                  <IoMdMove
                    style={{
                      position: 'absolute',
                      top: '15px',
                      right: '10px',
                      color: '#ddd',
                    }}
                  />
                </AntdButton>
              </Col>
            ))}
        </Row>
      </BsContainer>
    </div>
  );
};

export default Toolbox;
