import React, {useState, useEffect} from 'react';
import { Col, Row } from "react-bootstrap";
import { Button, Checkbox, Divider, Dropdown, Input, Menu, Select, Switch } from "antd";

import 'bootstrap/dist/css/bootstrap.min.css';
import { DownOutlined } from '@ant-design/icons';
import ControlSelector from "../ControlSelector";
import {FiMousePointer, FiTrash} from "react-icons/all";
import {useEditor, useNode} from "@craftjs/core";
import { useDispatch } from 'react-redux';
import { setFormBuilderEventSelecting } from 'redux/actions/ui';
import uuid from "react-uuid";

const {Option} = Select;

const EventSettings = () => {
    const [ isSelectTargetControl, setIsSelectTargetControl ] = useState(false);
    const dispatch = useDispatch();
    const {
        id,
        actions: { setProp },
        eventProperties: {
            conditionalEventEnabled,
            evaluateOnPageLoad,
            action,
            selectedControls,
            conditions
        }
    } = useNode( node => ({ id:node.id, eventProperties: node.data.props.eventProperties }));

    const editorGlobalState = useEditor();

    return (
        <>
            <Row>
                <Col xs={9}>
                    <span>Enable conditional event</span>
                </Col>
                <Col xs={3} style={{ textAlign: 'right' }}>
                    <Switch
                        checked={conditionalEventEnabled}
                        onChange={ e => {
                            const currentNodeControl = editorGlobalState.query.getSerializedNodes()[id];

                            setProp( p => {
                                p.eventProperties.conditionalEventEnabled = e;
                            });
                            
                            if (currentNodeControl?.props?.eventProperties?.selectedControls) {
                                currentNodeControl.props.eventProperties.selectedControls.map(selectedControl => {
                                    editorGlobalState.actions.setProp(selectedControl?.id, props => {
                                        props.isEventChild = e;
                                    });
                                });
                            }
                        }}
                    />
                </Col>
            </Row>
            {
                conditionalEventEnabled?
                    <>
                        <Row>
                            <Col xs={9}>
                                <label>Evaluate upon page load</label>
                            </Col>
                            <Col xs={3}  style={{ textAlign: 'right' }}>
                                <Switch
                                    checked={evaluateOnPageLoad}
                                    onChange={ e => {
                                        setProp( p => {
                                            p.eventProperties.evaluateOnPageLoad = e;
                                        });
                                    }}
                                    style={{
                                        marginTop: '10px'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={5}>
                                <label className='Form-Builder-Label'>Condition</label>
                            </Col>
                            <Col xs={5}>
                                <label className='Form-Builder-Label'>Value</label>
                            </Col>
                            <Col xs={2}></Col>
                        </Row>
                        <div>
                        {
                            conditions && conditions.map( c => {
                                return (
                                    <div key={c.id}>
                                        { c.logicalOperator && <Divider orientation='left'>{c.logicalOperator.displayText}</Divider> }
                                        <Row key={c.id} style={{ marginBottom: '10px' }}>
                                            <Col xs={5}>
                                                <Select
                                                    style={{ width: '100%'}}
                                                    defaultValue={ c.condition.value }
                                                    onChange={e => {
                                                        setProp( p => {
                                                            const condition = EventControlValues.conditions.filter(condition => condition.value == e )[0];
                                                            const eCon = p.eventProperties.conditions.filter( _con => _con.id === c.id )[0];
                                                            eCon.condition = condition;
                                                        })
                                                    }}>
                                                    {
                                                        EventControlValues && EventControlValues.conditions.length > 0 && EventControlValues.conditions.map(action => {
                                                            return <Option key={ action.value } value={ action.value }>{ action.displayText }</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Col>
                                            <Col xs={5}>
                                                <Input
                                                    placeholder='Value'
                                                    value={c.conditionalValue}
                                                    onChange={ e => setProp(p => {
                                                            const eCon = p.eventProperties.conditions.filter( _con => _con.id === c.id )[0];
                                                            eCon.conditionalValue = e.target.value;
                                                        }
                                                    )}
                                                />
                                            </Col>
                                            <Col xs={2}>
                                                <FiTrash onClick={() => {
                                                    setProp( p => {
                                                        const newConditions = p.eventProperties.conditions.filter( _con => _con.id !== c.id );
                                                        p.eventProperties.conditions = newConditions;
                                                    })
                                                }} />
                                            </Col>
                                        </Row>
                                    </div>);
                            })
                        }
                        </div>
                        <Row>
                            <Col>
                                {
                                    conditions.length < 1 ?
                                        <Button
                                            type='link'
                                            onClick={() => setProp( p=>
                                                p.eventProperties.conditions.push({...EventValues.defaultCondition, id: uuid()})
                                            )}
                                        >+ Add Condition</Button>

                                        : <Dropdown overlay={
                                            <Menu onClick={({ key }) => {
                                                const condition = {...EventValues.defaultCondition};
                                                setProp(p => {
                                                    condition.id = uuid();
                                                    condition.logicalOperator = EventControlValues.operators[key];
                                                    p.eventProperties.conditions.push(condition);
                                                });
                                            }}>
                                                {
                                                    EventControlValues.operators.map((opera, i) => (
                                                        <Menu.Item key={i}>
                                                            {`using '${opera.displayText}' operator`}
                                                        </Menu.Item>
                                                    ))
                                                }
                                            </Menu>
                                        }>
                                            <Button type='link'>
                                                + Add Condition <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <label className='Form-Builder-Label'>Action</label>
                            </Col>
                            <Col>
                                <Select
                                    style={{ width: '100%' }}
                                    defaultValue={ action.value }
                                    onChange={ e => {
                                        setProp(p => {
                                            const val = EventControlValues.actions.filter(act => act.value == e )[0];
                                            p.eventProperties.action = val;
                                        });
                                    }}>
                                    {
                                        EventControlValues && EventControlValues.actions && EventControlValues.actions.length > 0
                                        && EventControlValues.actions.map(action => {
                                            return <Option key={ action.value } value={ action.value }>{ action.displayText }</Option>
                                        })
                                    }
                                </Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <label className='Form-Builder-Label'>Target Controls</label>
                            </Col>
                        </Row>
                            {
                                selectedControls && selectedControls.length > 0 && selectedControls.map(control => {
                                    return <Row key={control.id} style={{ padding: '5px', marginLeft: '5px', marginRight: '5px', marginBottom: '5px', border: '2px solid #FF9800', background: '#FFE0B2'}}>
                                        <Col xs={10}>
                                            { `${control.name}_${control.id}` }
                                        </Col>
                                        {
                                            isSelectTargetControl? null
                                                :<Col xs={2}>
                                                    <FiTrash
                                                        style={{ marginLeft: 'auto' }}
                                                        onClick={ e => {
                                                            const newSelectedControls =
                                                              selectedControls.filter(
                                                                (c) =>
                                                                  c.id !==
                                                                  control.id
                                                              );

                                                            const node =
                                                              editorGlobalState.query.getSerializedNodes()[
                                                                control.id
                                                              ];

                                                            if (node) {
                                                              editorGlobalState.actions.setProp(
                                                                control.id,
                                                                (props) => {
                                                                  props.isEventChild = false;
                                                                }
                                                              );
                                                            }

                                                            setProp( p => {
                                                                p.eventProperties.selectedControls = newSelectedControls;
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                        }
                                    </Row>
                                })
                            }
                        <Row>
                            <Col>
                                {
                                    isSelectTargetControl?
                                        <Button
                                            type='primary'
                                            onClick={ e => {
                                                setIsSelectTargetControl(false);
                                                dispatch(setFormBuilderEventSelecting(false));
                                            }}
                                        >Finish selecting controls</Button>
                                        : <Button
                                            type='link'
                                            onClick={ e => {
                                                setIsSelectTargetControl(true);
                                                dispatch(setFormBuilderEventSelecting(true));
                                            }}
                                        ><FiMousePointer /> Select controls to { action.displayText.toLowerCase() }</Button>
                                }
                            </Col>
                        </Row>
                    </>
                    : null
            }
            <ControlSelector
                id={id}
                onSelect={ selected => {
                    setProp( p => {
                        const controls = p.eventProperties.selectedControls;
                        if(controls.filter(sel => sel.id === selected.id).length < 1)
                            p.eventProperties.selectedControls.push(selected);
                    } );
                }}
                enabled={isSelectTargetControl}
            />
        </>
    )

    return null;
}

export const EventControlValues = {
    actions: [
        {
            displayText: 'Hide',
            value: 'hide'
        },
        {
            displayText: 'Show',
            value: 'show'
        }
    ],
    conditions: [
        {
            displayText: 'Equal to',
            value: 'equal_to'
        },
        {
            displayText: 'Not equal to',
            value: 'not_equal_to'
        },
        {
            displayText: 'Includes',
            value: 'includes'
        },
        {
            displayText: 'Not includes',
            value: 'not_includes'
        },
    ],
    operators: [
        {
            displayText: 'or',
            value: 'or'
        },
        {
            displayText: 'and',
            value: 'and'
        }
    ]
}

const EventValues = {
    defaultCondition: {
        id: '_temp',
        condition: EventControlValues.conditions[0],
        conditionalValue: '',
        logicalOperator: null
    }
}

export default EventSettings;