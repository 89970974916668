import { Modal, Typography } from "antd";
import React from 'react';

const { Text } = Typography;

const ConfirmationModal = (props) => {
    return (<Modal
        title={props.title}
        visible={props.visible}
        onCancel={props.onCancel}
        okType={props.okType}
        onOk={props.onOk}
    >
        {props.message}
    </Modal>)
}

export default ConfirmationModal;