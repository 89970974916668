import React, { useEffect } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Footer from 'components/Layout/Footer';

import { useSelector, useDispatch } from 'react-redux';
import { setBreadcrumbsText } from 'redux/actions/ui';

function BasePage(props) {
    const { pageName, children } = props;

    const uiState = useSelector(state => state.ui);
    const dispatch = useDispatch();

    useEffect(()=>{
        return () => {
            dispatch(setBreadcrumbsText(''));
        }
    }, [dispatch])

    return (
        <>
            <div id='basePage'>
                {uiState.isTopbarVisible && (
                    <>
                        <Breadcrumbs />
                        <h1 className='fivef-pageTitle'>{pageName}</h1>
                    </>
                )}
                {children}
            </div>
            <Footer />
        </>
    );
}

export default BasePage;