import React, {FunctionComponent, useEffect, useState} from "react";
import {useAppSelector} from "../hooks/use-app-selector";
import {message, notification} from "antd";
import {useDispatch} from "react-redux";
import {ERROR_ACTIONS} from "../redux/types/error-types";

interface ErrorNotifierProps {

}

const ErrorNotifier: FunctionComponent<ErrorNotifierProps> = (props) => {

    const error = useAppSelector(state => state.error)
    const dispatch = useDispatch();

    useEffect(() => {
        if (error.message && !error.details) {
            message.error(error.message)
            dispatch({
                type: ERROR_ACTIONS.SET_ERROR_MESSAGE,
                payload: ""
            })
        }
        if (error.message && error.details) {
            const content = (
                <div>
                    <div style={{ color: 'red'}}><b>{error.message}</b> 
                    <span onClick={() => 
                        {message.destroy()
                            dispatch({
                                type: ERROR_ACTIONS.SET_ERROR_MESSAGE,
                                payload: ""
                            })
                        }}>
                            <button 
                            style={{backgroundColor: '#398887', color: '#FFFFFF', border: 'none', borderRadius:'5px',
                            marginBottom: '5px', marginLeft: '5px'}}>
                                Close</button>
                        </span>
                    </div>
                    <pre style={{width:'95vw', textAlign: 'left'}}>{error.details}</pre>
                </div>
              )

            message.info(content, 0)
        }
        return () => {
            message.destroy();
        }
    }, [error.message, error.details])

    return <></>

}

export default ErrorNotifier;

