import * as React from "react"

function MessageIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.001 512.001"
      {...props}
    >
      <path
        d="M499.628 407.468a53.333 53.333 0 01-40.96 19.2H53.334a53.333 53.333 0 01-40.96-19.2l195.627-162.987 20.267 13.867c16.695 11.52 38.772 11.52 55.467 0l20.267-13.867 195.626 162.987z"
        fill="#1e88e5"
      />
      <path
        d="M502.614 108.588L304.001 244.481l-20.267 13.867c-16.695 11.52-38.772 11.52-55.467 0L208 244.481 9.388 108.374a53.565 53.565 0 0143.947-23.04h405.333a52.627 52.627 0 0143.946 23.254z"
        fill="#64b5f6"
      />
      <g fill="#2196f3">
        <path d="M208.001 244.481L12.374 407.468A53.093 53.093 0 01.001 373.335V138.668a52.561 52.561 0 019.387-30.293l198.613 136.106zM512.001 138.668v234.667a53.099 53.099 0 01-12.373 34.133L304.001 244.481l198.613-135.893a52.386 52.386 0 019.387 30.08z" />
      </g>
    </svg>
  )
}

export default MessageIcon