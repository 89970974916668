import {useState, useEffect, useRef, createContext, useContext} from 'react';

const useRefOutSideClick = callback => {
    const ref = useRef(null);

    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        }
    });

    return { ref };
};

export const AuthContext = createContext(false);
export function useAuthContext() {
    return useContext(AuthContext);
}

export default useRefOutSideClick;