import { MessageTemplateMode } from "utils/enums";
import { SET_ACTIVE_MESAGE_TEMPLATE, SET_MESSAGE_TEMPLATE_MODE } from "redux/actions/types/messageTemplates";

const INITIAL_STATE = {
    mode: MessageTemplateMode.LIST,
    data: {}
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SET_MESSAGE_TEMPLATE_MODE:
            return { 
                ...state, 
                mode: payload.mode
            }

        case SET_ACTIVE_MESAGE_TEMPLATE:
            return {
                ...state,
                data: payload.data
            }

        default:
            return state
    }
};
