import './MainLayout.scss';
import Navigation from './Navigation';
import PageWrapper from './PageWrapper';

import Aux from '../hoc/AuxFunction';
import { useSelector } from 'react-redux';

const MainLayout = props => {
    const { children } = props;
    const permissionsState = useSelector(state => state.permissions);
    return (
        <Aux class>
            {permissionsState?.permissions && Object.keys(permissionsState.permissions).length > 0 ? 
                <>
                    <Navigation fullName={props.fullName} />
                    <PageWrapper page={children} />
                </>
                :
                null
            }
        </Aux>
    );
};

export default MainLayout;
