import React, { useEffect, useState } from 'react';
import {
  Formik
} from 'formik';
import * as Yup from 'yup';
import {
    Select as FormikSelect,
    Input as FormikInput,
    Form as FormikForm,
    SubmitButton,
    ResetButton
} from 'formik-antd';
import {
    Button,
    Divider,
    Input,
    InputNumber,
    TimePicker,
    Select,
    Switch,
    Tabs,
    message,
    Popconfirm,
    Modal
} from 'antd';
import uuid from 'react-uuid';
import { Container, Row, Col } from 'react-bootstrap';
import styled, { createGlobalStyle } from 'styled-components';
import CardView from 'components/Layout/CardView';
import { getAllSettings } from 'api/systemSettings';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { setStaging } from 'redux/actions/systemProperties';
import JSONInput from "./components/JSONInput";

import { FiMinusCircle, FiPlus } from 'react-icons/all';
import 'styles/Settings.scss'

const StyledContainer = styled(Container)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  z-index: -99;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const DeleteIcon = styled(FiMinusCircle)`
  font-size: ${(props) => (props.isSwitch ? '2.5rem' : '3rem')};
  color: #cecece;
  margin-left: 15px;

  &:hover {
    transition: 0.2s;
    cursor: pointer;
    color: #dc3545 !important;
  }
`;

const GlobalStyle = createGlobalStyle`
    .ant-form-item {
        margin-bottom: 10px;
    }
`;

message.config({
    duration: 2
});

const SystemProperties = ({ isReadOnly = false }) => {
  const [systemPropertiesData, setSystemPropertiesData] = useState(null);
  const [propertyModalVisible, setPropertyModalVisibility] = useState(false);
  const [isAdditionalFieldsEnabled, setAdditionalFieldEnabled] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [childrenStatePath, setChildrenStatePath] = useState([]);
  const [activeFieldProperty, setActiveFieldProperty] = useState({});
  const [parentPropertyVisibility, setParentPropertyVisibility] = useState(false);
  const dispatch = useDispatch();
  const [activeProperty, setActiveProperty] = useState(null);
  const systemPropertiesState = useSelector((state) => state.sysProperties);

  useEffect(() => {
    fetchSettings();
  }, []);

  const systemPropertyValidation = Yup.object().shape({
    name: Yup.string()
        .required('Please provide property name'),
    label: Yup.string()
        .required('Please provide property description'),
    dataType: Yup.string()
        .required('Please select data type.')
  });

  const fetchSettings = async () => {
    try {
      const response = await getAllSettings();
      setSystemPropertiesData(response.data);
    } catch (err) {
      message.error(
        'Error occurred while fetching the system settings, please refresh the page.',
        1000
      );
    }
  };

  const parentSystemPropertyValidation = Yup.object().shape({
    name: Yup.string()
        .required('Please provide property name'),
  });

    const onDeleteSetting = async (id, fieldId, systemPropertyField) => {
        if (activeProperty) {
            let newSystemPropertiesData = {...systemPropertiesData};
            const statePath = fieldId
                    .concat('-', systemPropertyField.name)
                    .split('-');

            const json = removeFromTree(id, statePath, newSystemPropertiesData);

            dispatch(setStaging(json));
            setSystemPropertiesData(json);
        }
    };

    const addPropertyToTree = (newProperty, array, json) => {
        if (typeof json === 'object' && Object.keys(json).includes('name')) {
            if (array && array.length > 1) {
                var key = array[0];
                if (json.name === key) {
                    var newJson = json['children'];
                    array.shift();
                    json['children'] = addPropertyToTree(newProperty, array, newJson);
                }
            }
        } else if (Array.isArray(json)) {
            json.forEach((item, i) => {
                var newJson = json[i];
                var updatedJson = {...newJson};
                const criteriaProp = newJson.id ? newJson.id : newJson.tempKey;
                const activeFieldCriteria = activeFieldProperty.id ? activeFieldProperty.id : activeFieldProperty.tempKey;
                if (criteriaProp === activeFieldCriteria) {
                    updatedJson.children = [
                        ...updatedJson.children,
                        newProperty
                    ];

                    json[i] = addPropertyToTree(newProperty, array, updatedJson);
                } else {
                    json[i] = addPropertyToTree(newProperty, array, newJson)
                }
            });
        } else {
            if (array && array.length > 0) {
                var key = array.shift();
                var newJson = json[key];
                json[key] = addPropertyToTree(newProperty, array, newJson);
            } else {
                throw 'Unexpected leaf node';
            }
        }

        return json;
    }

    const removeFromTree = (propertyId, array, json) => {
        if (typeof json === 'object' && Object.keys(json).includes('name')) {
            if (array && array.length > 1) {
                var key = array[0];
                if (json.name === key) {
                    var newJson = json['children'];
                    array.shift();
                    json['children'] = removeFromTree(propertyId, array, newJson);
                }
            }
        } else if (Array.isArray(json)) {
            json.forEach((item, i, object) => {
                var newJson = json[i];
                const criteriaProp = newJson.id ? newJson.id : newJson.tempKey;

                if (criteriaProp === propertyId) {
                    object.splice(i, 1);
                } else {
                    json[i] = removeFromTree(propertyId, array, newJson)
                }
            });
        } else {
            if (array && array.length > 0) {
                var key = array.shift();
                var newJson = json[key];
                json[key] = removeFromTree(propertyId, array, newJson);
            } else {
                throw 'Unexpected leaf node';
            }
        }

        return json;
    }

    const setDropdownOptionValue = (key, value, idx) => {
        let newDropdownOptions = [...dropdownOptions];
        newDropdownOptions[idx][key] = value;
        setDropdownOptions(newDropdownOptions);
    };

  const setData = (array, json, value) => {
    if (typeof json === 'object' && Object.keys(json).includes('name')) {
      if (array && array.length > 1) {
        var key = array[0];
        if (json.name === key) {
          var newJson = json['children'];
          array.shift();
          json['children'] = setData(array, newJson, value);
        }
      } else {
        // expect leaf node here
        if (array && array.length > 0 && json.name === array[0]) {
          json.value = value;
        }
      }
    } else if (Array.isArray(json)) {
      json.forEach((item, i) => {
        var newJson = json[i];
        json[i] = setData(array, newJson, value);
      });
    } else {
      if (array && array.length > 0) {
        var key = array.shift();
        var newJson = json[key];
        json[key] = setData(array, newJson, value);
      } else {
        throw 'Unexpected leaf node';
      }
    }

    return json;
  };

  const generateObjectTypeFieldChildren = (field, parent) => {
    var parentId = `${parent}-${field.name}`;
    if (field.dataType === 'OBJECT') {
      return (
        field &&
        field.children &&
        field.children.map((fieldChild, fcIdx) => {
          if (
            fieldChild.dataType !== 'OBJECT' &&
            fieldChild.dataType !== 'ARRAY'
          ) {
            return (
              <Container key={fcIdx} fluid>
                <Row>
                  <Col className='py-2' xs={12} sm={12} md={8} lg={6} xl={6}>
                    <h4 className='font-weight-bolder Settings-Title text-uppercase'>
                      {fieldChild.name?.replace(/_/g, ' ')}
                      <br />
                      <small style={{ textTransform: 'capitalize' }}>
                        {fieldChild.label?.replace(/_/g, ' ')}
                      </small>
                    </h4>
                  </Col>
                  <Col
                    className='py-2 text-right'
                    xs={12}
                    sm={12}
                    md={4}
                    lg={6}
                    xl={6}
                    style={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {generatePropertyFieldType(fieldChild, parentId)}
                  </Col>
                  <Divider />
                </Row>
              </Container>
            );
          } else {
            return generateObjectTypeField(fieldChild, parentId);
          }
        })
      );
    }
  };

  const deleteIconComponent = (isSwitch = false, fieldId = null, systemPropertyField = null) => (<>
    <Popconfirm
        title="Are you sure you want to delete this setting?"
        okText="Yes"
        cancelText="No"
        onConfirm={() => onDeleteSetting((systemPropertyField.id ? systemPropertyField.id : systemPropertyField.tempKey), fieldId, systemPropertyField)}
    >
      <DeleteIcon isSwitch={isSwitch} />
    </Popconfirm>
  </>)

  const generateObjectTypeField = (field, parent) => {
    var parentId = parent;
    var _fieldName = field.name?.replace(/_/g, ' ');
    var _fieldLabel = field.label?.replace(/_/g, ' ');

    return (
        <Container fluid key={`${parent}-${uuid()}`}>
          <Row>
            <Col xs={12} md={6} lg={6} xl={6}>
              <h4 className='font-weight-bolder Settings-Title text-uppercase'>
                {_fieldName}
                <br />
                <small style={{ textTransform: 'capitalize' }}>
                  {_fieldLabel}
                </small>
              </h4>
            </Col>
            <Col className='text-right' xs={12} md={6} lg={6} xl={6}>
              <Button
                  type='link'
                  style={{
                    marginTop: '-15px',
                    marginBottom: '25px'
                  }}
                  onClick={() => {
                    const statePath = parentId
                        .concat('-', field.name)
                        .split('-');

                    setActiveFieldProperty(field);
                    setChildrenStatePath(statePath);
                    setPropertyModalVisibility(true);
                  }}
              >
                <FiPlus
                    style={{
                      fontSize: '2rem',
                    }}
                />&nbsp;&nbsp;Add Children
              </Button>
              {deleteIconComponent(false, parent, field)}
            </Col>
            <Divider />
          </Row>
          <Row>
            <Col
                style={{
                  paddingLeft: '25px'
                }}
            >
              {generateObjectTypeFieldChildren(field, parentId)}
            </Col>
          </Row>
        </Container>
    );
  };

  const generatePropertyFieldType = (systemPropertyField, parent) => {
    const fieldId = `${parent}`;

    const deleteIconComponent = (isSwitch = false) => (<>
                                    <Popconfirm
                                        title="Are you sure you want to delete this setting?"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={() => onDeleteSetting((systemPropertyField.id ? systemPropertyField.id : systemPropertyField.tempKey),fieldId,systemPropertyField)}
                                    >
                                        <DeleteIcon isSwitch={isSwitch} />
                                    </Popconfirm>
                                </>)

    switch (systemPropertyField.dataType) {
      case 'FREE_TEXT':
        return !isReadOnly ? (
            <FlexContainer>
              <Input
                  id={fieldId}
                  placeholder={systemPropertyField?.label?.replace(/_/g, ' ')}
                  defaultValue={systemPropertyField?.value}
                  onBlur={(e) => {
                    const statePath = fieldId
                        .concat('-', systemPropertyField?.name)
                        .split('-');
                    const newSystemProperty = Object.assign({}, systemPropertiesData);

                    const newState = setData(
                        statePath,
                        newSystemProperty,
                        e.target.value
                    );

                    dispatch(setStaging(newState));
                  }}
              />
              {deleteIconComponent(false, fieldId, systemPropertyField)}
            </FlexContainer>
        ) : (
            systemPropertyField.value
        );
      case 'TIME':
        return !isReadOnly ? (
            <FlexContainer>
              <TimePicker
                  id={fieldId}
                  style={{
                    width: '100%',
                  }}
                  defaultValue={
                    systemPropertyField.value
                        ? moment(systemPropertyField.value, 'h:mm a')
                        : null
                  }
                  use12Hours
                  format={'h:mm a'}
                  placeholder={systemPropertyField.label?.replace(/_/g, ' ')}
                  onChange={(time, timeString) => {
                    const statePath = fieldId
                        .concat('-', systemPropertyField.name)
                        .split('-');
                    const newSystemProperty = Object.assign({}, systemPropertiesData);

                    const newState = setData(
                        statePath,
                        newSystemProperty,
                        timeString
                    );

                    dispatch(setStaging(newState));
                  }}
              />
              {deleteIconComponent(false, fieldId, systemPropertyField)}
            </FlexContainer>
        ) : (
            systemPropertyField.value
        );
      case 'HTML':
        return !isReadOnly ? (
            <FlexContainer>
              <Input.TextArea
                  id={fieldId}
                  rows={10}
                  defaultValue={systemPropertyField.value}
                  onBlur={(e) => {
                    const statePath = fieldId
                        .concat('-', systemPropertyField.name)
                        .split('-');
                    const newSystemProperty = Object.assign({}, systemPropertiesData);

                    const newState = setData(
                        statePath,
                        newSystemProperty,
                        e.target.value
                    );

                    dispatch(setStaging(newState));
                  }}
              />
              {deleteIconComponent(false, fieldId, systemPropertyField)}
            </FlexContainer>
        ) : (
            systemPropertyField.value
        );
      case 'DROPDOWN':
        return !isReadOnly ? (
            <FlexContainer>
              <Select
                  id={fieldId}
                  style={{
                    width: '100%',
                    textAlign: 'left',
                  }}
                  defaultValue={systemPropertyField.value}
                  placeholder={systemPropertyField.label?.replace(/_/g, ' ')}
                  onSelect={(value) => {
                    const statePath = fieldId
                        .concat('-', systemPropertyField.name)
                        .split('-');
                    const newSystemProperty = Object.assign({}, systemPropertiesData);

                    const newState = setData(statePath, newSystemProperty, value);

                    dispatch(setStaging(newState));
                  }}
              >
                {systemPropertyField.options &&
                systemPropertyField.options.length > 0 &&
                systemPropertyField.options.map((option, idx) => {
                  let systemPropertyKey = null;
                  let systemPropertyValue = null;
                  if (Object.keys(option).includes('key')) {
                    systemPropertyKey = option.displayText;
                    systemPropertyValue = option.value;
                  } else {
                    systemPropertyKey = Object.keys(option).toString();
                    systemPropertyValue =
                        systemPropertyField.options[idx][systemPropertyKey];
                  }
                  return (
                      <Select.Option key={idx} value={systemPropertyKey}>
                        {systemPropertyValue}
                      </Select.Option>
                  );
                })}
              </Select>
              {deleteIconComponent(false, fieldId, systemPropertyField)}
            </FlexContainer>
        ) : (
            systemPropertyField.value
        );
      case 'BOOLEAN':
        return !isReadOnly ? (
            <FlexContainer>
              <Switch
                  id={fieldId}
                  defaultChecked={systemPropertyField.value !== 'false'}
                  onChange={(value) => {
                    const statePath = fieldId
                        .concat('-', systemPropertyField.name)
                        .split('-');
                    const newSystemProperty = Object.assign({}, systemPropertiesData);

                    const newState = setData(statePath, newSystemProperty, value);

                    dispatch(setStaging(newState));
                  }}
              />
              {deleteIconComponent(true, fieldId, systemPropertyField)}
            </FlexContainer>
        ) : (
            systemPropertyField.value
        );
      case 'NUMBER':
        return !isReadOnly ? (
            <FlexContainer>
              <InputNumber
                  id={fieldId}
                  style={{
                    width: '100%',
                  }}
                  defaultValue={systemPropertyField.value}
                  placeholder={systemPropertyField.label?.replace(/_/g, ' ')}
                  onBlur={(e) => {
                    const statePath = fieldId
                        .concat('-', systemPropertyField.name)
                        .split('-');
                    const newSystemProperty = Object.assign({}, systemPropertiesData);

                    const newState = setData(
                        statePath,
                        newSystemProperty,
                        e.target.value
                    );

                    dispatch(setStaging(newState));
                  }}
              />
              {deleteIconComponent(false, fieldId, systemPropertyField)}
            </FlexContainer>
        ) : (
            systemPropertyField.value
        );
      case 'OBJECT':
        return generateObjectTypeField(systemPropertyField, fieldId);
      case 'JSON':
        return <FlexContainer>
            <JSONInput defaultValue={systemPropertyField.value} onChange={(value) => {
              const statePath = fieldId
                  .concat('-', systemPropertyField.name)
                  .split('-');
              const newSystemProperty = Object.assign({}, systemPropertiesData);

              const newState = setData(statePath, newSystemProperty, value);

              dispatch(setStaging(newState));
            }}  />
            {deleteIconComponent(false, fieldId, systemPropertyField)}
        </FlexContainer>
      default:
        break;
    }
  };

  return (
      <StyledContainer fluid style={{ minHeight: '200vh' }}>
        <GlobalStyle />
        <Modal
            title="New Property"
            visible={propertyModalVisible}
            okText='Save'
            cancelText='Close'
            closable={true}
            centered
            destroyOnClose
            footer={null}
            onCancel={() => setPropertyModalVisibility(false)}
            keyboard={false}
            maskClosable={false}
        >
          <Formik
              initialValues={{
                name: '',
                label: '',
                dataType: '',
                category: '',
                children: [],
                options: [],
                value: ''
              }}
              validationSchema={systemPropertyValidation}
              onSubmit={(values) => {
                const systemProp = {
                  ...values,
                  id: null,
                  tempKey: `temp_${uuid()}`,
                  options: dropdownOptions
                };
                let newSystemPropertiesData = {...systemPropertiesData};
                if (childrenStatePath && childrenStatePath.length > 0) {
                  newSystemPropertiesData = addPropertyToTree(systemProp, childrenStatePath, newSystemPropertiesData, activeFieldProperty);
                } else {
                  newSystemPropertiesData[activeProperty].push(systemProp);
                }
                setSystemPropertiesData(newSystemPropertiesData);
                dispatch(setStaging(newSystemPropertiesData));
                message.success('Property successfully added, Please click Save System Properties to save your changes.', 5);
                setPropertyModalVisibility(false);
              }}
          >
            <FormikForm layout="vertical">
              <FormikForm.Item label='Name' name='name'>
                <FormikInput name='name' placeholder='e.g. HIDE_SETTINGS' autoFocus />
              </FormikForm.Item>
              <FormikForm.Item label='Description' name='label'>
                <FormikInput name='label' placeholder='e.g. Use to hide settings' />
              </FormikForm.Item>
              <FormikForm.Item label='Data Type' name='dataType'>
                <FormikSelect
                    name='dataType'
                    placeholder='e.g. FREE_TEXT'
                    onChange={(value) => {
                      if (value && value === 'DROPDOWN') {
                        setAdditionalFieldEnabled(true);
                      } else {
                        setAdditionalFieldEnabled(false);
                        setDropdownOptions([]);
                      }
                    }}
                >
                  <FormikSelect.Option value='FREE_TEXT'>
                    FREE_TEXT
                  </FormikSelect.Option>
                  <FormikSelect.Option value='TIME'>
                    TIME
                  </FormikSelect.Option>
                  <FormikSelect.Option value='HTML'>
                    HTML
                  </FormikSelect.Option>
                  <FormikSelect.Option value='DROPDOWN'>
                    DROPDOWN
                  </FormikSelect.Option>
                  <FormikSelect.Option value='BOOLEAN'>
                    BOOLEAN
                  </FormikSelect.Option>
                  <FormikSelect.Option value='NUMBER'>
                    NUMBER
                  </FormikSelect.Option>
                  <FormikSelect.Option value='OBJECT'>
                    OBJECT
                  </FormikSelect.Option>
                </FormikSelect>
              </FormikForm.Item>
              {isAdditionalFieldsEnabled &&
              <Button
                  type='link'
                  onClick={() => {
                    let newOptionList = [...dropdownOptions];
                    let itemKey = 0;

                    if (newOptionList.length > 0) {
                      itemKey = parseInt(newOptionList[newOptionList.length - 1].key) + 1
                    }

                    newOptionList.push({
                      key: itemKey,
                      value: '',
                      displayText: ''
                    });
                    setDropdownOptions(newOptionList);
                  }}
              >
                <FiPlus
                    style={{
                      fontSize: '2rem',
                    }}
                />&nbsp;&nbsp;Add Option
              </Button>
              }
              {isAdditionalFieldsEnabled
              && dropdownOptions
              && dropdownOptions.length > 0 &&
              <div
                  style={{
                    maxHeight: '30vh',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    border: '1px solid #ddd',
                    padding: '10px 0 20px 0'
                  }}
              >
                {dropdownOptions.map((dropdownOption, idx) => (
                    <Row
                        key={dropdownOption.key}
                        style={{
                          marginLeft: '5px'
                        }}
                    >
                      <Col xs={12} md={5}>
                        <label>Value</label>
                        <Input
                            onBlur={(e) => {
                              const value = e.currentTarget.value;
                              setDropdownOptionValue('value', value, dropdownOption.key);
                            }}
                        />
                      </Col>
                      <Col xs={12} md={5}>
                        <label>Display Text</label>
                        <Input
                            onBlur={(e) => {
                              const value = e.currentTarget.value;
                              setDropdownOptionValue('displayText', value, dropdownOption.key);
                            }}
                        />
                      </Col>
                      <Col
                          xs={12}
                          md={2}
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            paddingBottom: '2px'
                          }}
                      >
                        <DeleteIcon
                            isSwitch={null}
                            onClick={() => {
                              let newDropdownOptions = [...dropdownOptions];
                              const filteredList = newDropdownOptions.filter(dropdownOptionItem => dropdownOptionItem.key !== dropdownOption.key)
                              setDropdownOptions(filteredList);
                            }}
                        />
                      </Col>
                    </Row>))
                }
              </div>
              }
              <Row gutter={4} className='d-flex justify-content-end'>
                <Col className='text-right mt-3'>
                  <ResetButton>Reset</ResetButton>&nbsp;&nbsp;
                  <SubmitButton>Save</SubmitButton>
                </Col>
              </Row>
            </FormikForm>
          </Formik>
        </Modal>
        <Modal
            title="New Parent Property"
            visible={parentPropertyVisibility}
            okText='Save'
            cancelText='Close'
            closable={true}
            centered
            destroyOnClose
            footer={null}
            onCancel={() => setParentPropertyVisibility(false)}
            keyboard={false}
            maskClosable={false}
        >
          <Formik
              initialValues={{
                name: '',
              }}
              validationSchema={parentSystemPropertyValidation}
              onSubmit={(values) => {
                let newSystemPropertiesData = {
                  ...systemPropertiesData,
                  [values.name]: []
                };
                setSystemPropertiesData(newSystemPropertiesData);
                dispatch(setStaging(newSystemPropertiesData));

                message.success('Property successfully added, Please click Save System Properties to save your changes.', 5);
                setParentPropertyVisibility(false);
              }}
          >
            <FormikForm layout="vertical">
              <FormikForm.Item label='Name' name='name'>
                <FormikInput name='name' placeholder='e.g. AWS' autoFocus />
              </FormikForm.Item>
              <Row gutter={4} className='d-flex justify-content-end'>
                <Col className='text-right mt-3'>
                  <ResetButton>Reset</ResetButton>&nbsp;&nbsp;
                  <SubmitButton>Save</SubmitButton>
                </Col>
              </Row>
            </FormikForm>
          </Formik>
        </Modal>
        <Row className='mx-1 mb-4 m-h-100v'>
          <CardView
              style={{
                paddingBottom: '20px',
                width: '100%',
              }}
          >
            <Tabs
                // defaultActiveKey='1'
                tabPosition='left'
                onChange={(activeKey) => {
                  var n = activeKey.indexOf('_');
                  var systemProperty = activeKey.substring(n + 1);
                  setActiveProperty(systemProperty);
                }}
                tabBarExtraContent={{
                  left: <Button
                      type='primary'
                      style={{
                        marginTop: '20px',
                        // paddingLeft: '0',
                        marginBottom: '20px',
                      }}
                      onClick={() => setParentPropertyVisibility(true)}
                  >
                    <FiPlus
                        style={{
                          fontSize: '2rem',
                        }}
                    />&nbsp;Add Property Category
                  </Button>
                }}
            >
              {systemPropertiesData &&
              Object.keys(systemPropertiesData).map((systemProperty, i) => {
                if (i === 0 && !activeProperty) {
                  setActiveProperty(systemProperty);
                }
                // as per changes of TDPJ-429 Hide tab if not any devsetting has false value
                if(systemPropertiesData[systemProperty]?.every(x => x.devSettings === true)) {
                  return false;
                }

                return <Tabs.TabPane
                    tab={`${systemProperty} Settings`}
                    key={`${i}_${systemProperty}`}
                    className={systemPropertiesData[systemProperty]?.some(x => x.devSettings === false) ? '' : 'Hide-Tab'}
                >
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container key={i} className='mt-4' fluid>
                        <Row>
                          <Col>
                            <h3 className='font-weight-bolder'>
                              {systemProperty} Settings
                            </h3>
                          </Col>
                          <Col className='text-right'>
                            <Button
                                type='link'
                                style={{
                                  marginTop: '-15px'
                                }}
                                onClick={() => {
                                  setActiveProperty(systemProperty);
                                  setDropdownOptions([]);
                                  setActiveFieldProperty({});
                                  setChildrenStatePath([]);
                                  setAdditionalFieldEnabled(false);
                                  setPropertyModalVisibility(true);
                                }}
                            >
                              <FiPlus
                                  style={{
                                    fontSize: '2rem',
                                  }}
                              />&nbsp;&nbsp;Add Property
                            </Button>
                          </Col>
                          <Divider />
                        </Row>
                        {systemPropertiesData[systemProperty] &&
                          systemPropertiesData[systemProperty].length > 0 &&
                          systemPropertiesData[systemProperty].map(
                            (systemPropertyFields, idx) => (
                              <Row key={idx}>
                                {!systemPropertyFields?.devSettings ? (
                                  <>
                                    {systemPropertyFields.dataType !== 'OBJECT' ? (
                                      <>
                                        <Col
                                          className='py-2 pt-4'
                                          xs={12}
                                          sm={12}
                                          md={8}
                                          lg={6}
                                          xl={6}
                                        >
                                          <h4 className='font-weight-bolder Settings-Title'>
                                            {systemPropertyFields?.name?.replace(
                                              /_/g,
                                              ' '
                                            )}
                                            <br />
                                            <small
                                              style={{
                                                textTransform: 'capitalize',
                                              }}
                                            >
                                              {systemPropertyFields?.label?.replace(
                                                /_/g,
                                                ' '
                                              )}
                                            </small>
                                          </h4>
                                        </Col>
                                        <Col
                                          className='text-right py-2 pt-3'
                                          xs={12}
                                          sm={12}
                                          md={4}
                                          lg={6}
                                          xl={6}
                                          style={{
                                            wordBreak: 'break-word',
                                          }}
                                        >
                                          {generatePropertyFieldType(
                                            systemPropertyFields,
                                            systemProperty
                                          )}
                                        </Col>
                                      </>
                                    ) : (
                                      <>
                                        <Col
                                          className='py-2 pt-4'
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          lg={12}
                                          xl={12}
                                        >
                                          {generatePropertyFieldType(
                                            systemPropertyFields,
                                            systemProperty
                                          )}
                                        </Col>
                                      </>
                                    )}
                                    <Divider />
                                  </>
                                ) : null}
                              </Row>
                            )
                          )}
                      </Container>
                    </Col>
                  </Row>
                </Tabs.TabPane>
              })}
            </Tabs>
          </CardView>
        </Row>
      </StyledContainer>
  );
};

export default SystemProperties;
