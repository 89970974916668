import React, {useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { Tabs, Tag } from "antd";
import DeleteButton from "./common/DeleteButton";
import { useEditorDefaultSelector } from "./components/Selector";
import { useDispatch, useSelector } from 'react-redux';
import { setFormBuilderActiveTab } from 'redux/actions/ui';

import 'styles/Settings.scss'
import { setEditorFocused } from 'redux/actions/formBuilder';

const { TabPane } = Tabs;

const Settings = () => {
    const [selected, setSelected] = useState(null);
    const uiState = useSelector(state => state.ui);
    const dispatch = useDispatch();
    useEditorDefaultSelector(selected => {
        setSelected(selected);
    });

    return (
        selected && selected.id !== 'ROOT' ? (
        <div
            className='Form-Builder-Settings'
            onMouseEnter={() => {
                dispatch(setEditorFocused(true));
            }}
            onMouseLeave={() => {
                dispatch(setEditorFocused(false));
            }}
        >
            <Container style={{ padding: '20px 0 20px 0' }}>
                <Row>
                    <Col xs={8}>
                        <h4 style={{ marginBottom: '0' }}>
                            <strong>{selected.name}</strong><br/>
                            {selected
                                &&  selected.name === 'Input'
                                &&  <Tag color='#2db7f5' style={{ marginTop: '5px' }}>
                                        <strong>{selected.type.toUpperCase()}</strong>
                                    </Tag>}
                        </h4>
                    </Col>
                </Row>
                {selected && selected.settings && <><Row>
                    <Col xs={12}>
                        <Tabs defaultActiveKey='1' activeKey={uiState?.formBuilderActiveTab} onTabClick={(value) => {
                            dispatch(setFormBuilderActiveTab(value));
                        }}>
                            <TabPane tab='Properties' key='1' className='Settings-Tab'>
                                {React.createElement(selected.settings)}
                            </TabPane>
                            {
                                selected.eventSettings && selected.eventSettings != undefined &&
                                <TabPane tab='Events' key='2' className='Settings-Tab'>
                                    { React.createElement(selected.eventSettings) }
                                </TabPane>
                            }
                        </Tabs>
                    </Col>
                </Row>
                <hr /></>}
                <Row>
                    <Col xs={12}>
                        <DeleteButton component={selected} setSelected={setSelected} />
                    </Col>
                </Row>
            </Container>
        </div>) : null
    );
};

export default Settings;
