export function locationsHasErrored(state = false, action) {
    switch (action.type) {
        case 'LOCATIONS_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function locationsIsLoading(state = false, action) {
    switch (action.type) {
        case 'LOCATIONS_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function locations(state = [], action) {
    switch (action.type) {
        case 'LOCATIONS_FETCH_DATA_SUCCESS':
            return action.locations;
        case 'LOCATIONS_REMOVE_LOCATION_SUCCESS':
            const data = state.data;
            // this bit is fancy javascript to replace the data with a new array
            return {
                ...state,
                data: remove(data, data.findIndex(data => data.id === action.locations))
            }
        default:
            return state;
    }
}

const remove = (locations,index) => {
    return [...locations.slice(0,index),
        ...locations.slice(index+1,locations.length)];
};