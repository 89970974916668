import { BASE_API_URL } from 'api/config';
import {createAxios} from './axios';

const documentsApi = createAxios({
    baseURL: BASE_API_URL + '/appointments'
});

export const createDocument = (id, payload) => {
    return documentsApi.post(`/${id}/attachments`, payload, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};

export const deleteDocument = id => {
    return documentsApi.delete('', id);
};

export const fetchAttachments = (id, params) => {
    return documentsApi.get(`/${id}/attachments`, {
        params: params
    })
}

