import {BASE_API_URL} from 'api/config';
import {createAxios} from './axios';

const tasksApi = createAxios({
  baseURL: BASE_API_URL + '/staff-tasks'
});

export const getTaskDatatable = params => {
  return tasksApi.get('', {
    params: params
  })
}

export const createTask = task => {
  return tasksApi.post('', task);
}

export const updateStatus = (id, status) => {
  return tasksApi.patch(`/${id}/status`, status);
}

// not supported
export const getTaskById = id => {
  return tasksApi.get(`/${id}`);
}

//not supported
export const rejectTask = id => {
  return tasksApi.get(`/reject/${id}`)
}

//not supported
export const completeTask = id => {
  return tasksApi.get(`/complete/${id}`)
}