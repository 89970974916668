import React, { useEffect, useRef, useState } from 'react';
import { Col, message, Modal, Row, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, ResetButton, SubmitButton } from 'formik-antd';
import { FormOutlined } from '@ant-design/icons';
import { createForm } from 'api/forms';
import lz from 'lzutf8';
import { FormModes } from 'utils/enums';
import { updateContent } from 'api/forms';
import * as Yup from 'yup';

const { Title } = Typography;

const CreateEditPublicForm = ({
    mode = FormModes.ADD,
    formData = null,
    setFormData = null,
    visible,
    setVisibility,
}) => {
    const formikRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const formsValidationSchema = Yup.object().shape({
        name: Yup.string()
                .required('Form name is required'),
        externalAsmCode: Yup.string()
                .required('Form code is required')
    });

    useEffect(
        () => {
            if (!visible) {
                reset();
            }
        },
        [visible]
    );

    async function create(values) {
        try {
            message.loading('Creating form...', 0);

            // Should find a way to programmatically add a 'Container' via craftjs
            // For the meantime, this is the default container value
            const json = lz.encodeBase64(
                lz.compress(
                    JSON.stringify({
                        ROOT: {
                            type: { resolvedName: 'Container' },
                            isCanvas: true,
                            props: {},
                            displayName: 'ie',
                            custom: {},
                            hidden: false,
                            nodes: ['vlhkMnCbF'],
                        },
                        vlhkMnCbF: {
                            type: { resolvedName: 'Card' },
                            isCanvas: true,
                            props: { id: 'Container' },
                            displayName: 'ie',
                            custom: {},
                            hidden: false,
                            parent: 'ROOT',
                        },
                    })
                )
            );
            values.content = json;
            let res = await createForm(values);
            let form = res.data;
            message.destroy();
            // onSuccess(); because instead of refreshing the datatable,
            // should redirect to form builder
            message.success('Form successfully created.');
            setVisibility(false);
            window.location.replace(`/form-builder/${form.id}`);
        } catch (error) {
            message.destroy();
            let content = error.response.data.message !== undefined && error.response.data.message.length > 0
                ? error.response.data.message
                : 'Form already exists with same external code.';
            message.error(content);
            setVisibility(false);
        }
    }

    const updateForm = async (formValues) => {
        const payload = {
            designUniqueId: formData.designUniqueId,
            externalAsmCode: formValues.externalAsmCode,
            name: formValues.name,
            status: formData.status
        };
        setIsLoading(true);
        try {
            const response = await updateContent(payload);
            message.destroy();
            message.success('Form information successfully updated');

            if (response.data) {
                setFormData(response.data);
            }
            setIsLoading(false);
            setVisibility(false);
        } catch (err) {
            message.destroy();
            message.error('Error occurred while updating the form information, please try again.');
            setIsLoading(false);
        }
    };

    function reset() {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    }

    const getInitialValuesByMode = () => {
        if (mode === FormModes.ADD) {
            return { externalAsmCode: '', name: '' };
        } else {
            return {
                externalAsmCode: formData.externalAsmCode ? formData.externalAsmCode : '',
                name: formData?.name
            }
        }
    };

    return (
        <Modal
            title={
                <Title level={4} className="mb-0" style={{ display: 'flex', alignItems: 'center' }}>
                    <FormOutlined className="mr-2" /> {mode === FormModes.ADD ? 'New Form' : 'Update Form Information'}
                </Title>
            }
            visible={visible}
            onCancel={() => setVisibility(false)}
            footer={null}
            keyboard={false}
            destroyOnClose
            centered
            maskClosable={false}
        >
            <Formik
                innerRef={formikRef}
                initialValues={getInitialValuesByMode()}
                validationSchema={formsValidationSchema}
                onSubmit={values => {
                    if (mode === FormModes.ADD) {
                        create(values);
                    } else {
                        updateForm(values);
                    }
                }}
                render={() =>
                    <Form layout="vertical" colon={false}>
                        <Form.Item label="External Code" name="externalAsmCode" className="mb-4">
                            <Input name="externalAsmCode" autoFocus />
                        </Form.Item>
                        <Form.Item label="Name" name="name" className="mb-4">
                            <Input name="name" />
                        </Form.Item>
                        <Row gutter={4} className="d-flex justify-content-end">
                            <Col>
                                <ResetButton>Reset</ResetButton>
                            </Col>
                            <Col>
                                <SubmitButton loading={isLoading}>
                                    {mode === FormModes.ADD ? 'Save' : 'Update'}
                                </SubmitButton>
                            </Col>
                        </Row>
                    </Form>}
            />
        </Modal>
    );
};

export default CreateEditPublicForm;
