import {SET_ACTIVE_ITEM, SET_DELETE_MODAL_VISIBILITY} from "../actions/types/ui";

const INITIAL_STATE = {
    isDeleteModalVisible: false
};

export const commonUtils = (state = INITIAL_STATE, action) =>{
    switch (action.type) {
        case SET_DELETE_MODAL_VISIBILITY: {
            return {
                ...state,
                isDeleteModalVisible: action.isDeleteModalVisible
            }
        }

        case SET_ACTIVE_ITEM: {
            return {
                ...state,
                item: action.item
            }
        }



        default:
            return state;
    }
}