import { BASE_API_URL } from "api/config";
import { createAxios } from "./axios";

const systemSettingsApi = createAxios({
  baseURL: BASE_API_URL + "/settings",
});

export const getAllSettings = (query = null) => {
  return systemSettingsApi.get(`/categorized${query ? '?systemCategory=' + query : ''}`);
};

export const createSettings = (body) => {
  return systemSettingsApi.post("/categorized", body);
};

export const deleteSettings = (id) => {
    return systemSettingsApi.delete(`/${id}`);
};
  
