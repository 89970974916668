import Keycloak from 'keycloak-js';
import {
  KEYCLOAK_AUTH_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID,
} from './config';

const initOptions = {
  url: KEYCLOAK_AUTH_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
};

console.log(initOptions);

const keycloak = Keycloak(initOptions);

keycloak.onTokenExpired = () => {
  console.log('updating token...');
  keycloak
    .updateToken(0)
    .then((success) => {
      if (success) {
        console.log('done refreshing token');
      } else {
        console.log('failed to refresh token');
      }
    })
    .catch(() => console.log('failed to refresh token'));
};

keycloak.onAuthSuccess = () => console.log('onAuthSuccess');
keycloak.onAuthLogout = (data) => console.log('onLogout', data);

export { keycloak, initOptions };
