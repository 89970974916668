import {BASE_API_URL} from "./config";
import {createAxios} from "./axios";

const messageTemplatesApi = createAxios({
    baseURL: BASE_API_URL + '/message-templates'
});

export const getMessageTemplates = () => {
    return messageTemplatesApi.get('');
};

export const getMessageTemplatesDataTable = params => {
    return messageTemplatesApi.get('/datatable', {
        params: params
    });
};

export const saveMessageTemplate = payload => {
    return messageTemplatesApi.post('', payload);
};

export const updateTemplateMessage = (id, payload) => {
    return messageTemplatesApi.put(`/${id}`, payload);
};

export const deleteTemplateMessage = (id) => {
    return messageTemplatesApi.delete(`/${id}`);
};