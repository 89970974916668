import React from 'react';
import { useSelector } from 'react-redux';
import { isCurrentPermissionKeyPermitted } from 'utils';

const PermissifiedComponent = ({
    permissionKey,
    children
}) => {
    const permissionState = useSelector(state => state.permissions);
    
    return  <>
                {isCurrentPermissionKeyPermitted(permissionKey, permissionState) ? children : null}
            </>;
};

export default PermissifiedComponent;
