import {
    SET_MESSAGE_TEMPLATE_MODE,
    SET_ACTIVE_MESAGE_TEMPLATE
} from 'redux/actions/types/messageTemplates';

export const setMessageTemplateMode = mode => ({
    type: SET_MESSAGE_TEMPLATE_MODE,
    payload: {
        mode
    }
});

export const setActiveMessageTemplate = messageTemplate => ({
    type: SET_ACTIVE_MESAGE_TEMPLATE,
    payload: {
        data: messageTemplate
    }
});