import React from 'react';
import { Card } from 'antd';
import styled from 'styled-components';

const StyledCard = styled(Card)`
    border: none;
    
    .ant-card-head-title {
        font-weight: 700;
    }
`;

const CardView = ({
    title,
    children,
    ...props
}) => {
    return <StyledCard
                title={title}
                style={{
                    width: '100%'
                }}
                {...props}
            >
                {children}
            </StyledCard>;
}

export default CardView;