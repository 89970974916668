import { useSelector, useDispatch } from 'react-redux';
import { setTokens } from 'redux/actions/auth';
import { refreshTheToken } from 'utils/helpers';

export const useUserAuthentication = () => {
  const authState = useSelector((state) => state.auth);
  const { isUserAuthenticated } = authState;
  const dispatch = useDispatch();
  let authenticated = isUserAuthenticated;

  const token = sessionStorage.getItem('token');
  const refreshtoken = sessionStorage.getItem('refresh_token');
  const expiryDate = sessionStorage.getItem('expiryDate');
  if (token && expiryDate) {
    if (new Date(expiryDate) <= new Date()) {
      async function updateToken() {
        return await refreshTheToken();
      }
      (async () => {
        const { access_token, refresh_token } = await updateToken();
        if (access_token && refresh_token) {
          dispatch(
            setTokens({
              access_token: access_token,
              refresh_token: refresh_token,
              isUserAuthenticated: access_token,
            })
          );
        }
      })();
    } else {
      if (!authenticated) {
        authenticated = true;
        dispatch(
          setTokens({
            access_token: token,
            refresh_token: refreshtoken,
            isUserAuthenticated: authenticated,
          })
        );
      }
    }
  } else {
    if (authenticated) {
      dispatch(
        setTokens({
          access_token: '',
          refresh_token: '',
          isUserAuthenticated: false,
        })
      );
    }
    authenticated = false;
  }

  return { authenticated };
};
