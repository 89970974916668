import {BASE_API_URL} from 'api/config';
import {createAxios} from './axios';

const actionApi = createAxios({
  baseURL: BASE_API_URL + '/appointment-patient-action-templates'
});

export const getTemplate = id => {
  return actionApi.get(`${id}`);
}

export const getAll = (params) => {
  return actionApi.get(``, {
    params
  });
}

export const createTemplate = template => {
  return actionApi.post(``, template);
}

export const deleteTemplates = id => {
  return actionApi.delete(`/${id}`);
}
