import { SET_TOKENS } from 'redux/actions/types/auth';

const INITIAL_STATE = {
    access_token: null,
    refresh_token: null,
    isUserAuthenticated: false
};

const AuthReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SET_TOKENS:
            return { 
                ...state, 
                ...payload 
            }

        default:
            return state
    }
}

export default AuthReducer;
