import {BASE_API_URL} from './config';
import {createAxios} from './axios';

const notesApi = createAxios({
  baseURL: BASE_API_URL + '/notes'
})

export const getNotesDatatable = (id, params) => {
  return notesApi.get(``, {
    params: {
      appointmentId: id,
      ...params
    }
  });
}

export const createNote = (id, payload) => {
  return notesApi.post(``, {
    appointmentId: id,
    ...payload
  })
}

export const updateNote = (id, payload) => {
  return notesApi.patch(`/${id}/note`, payload);
}

export const deleteNote = id => {
  return notesApi.delete(`/${id}`);
}