import {BASE_API_URL} from 'api/config';
import {createAxios} from './axios';

const locationApi = createAxios({
    baseURL: BASE_API_URL + '/locations'
});

export const getLocations = (params) => {
    return locationApi.get('/location', {
        params: params
    });
}
export const getLocation = id => {
    return locationApi.get(`/${id}`)
}

export const createLocation = location => {
    return locationApi.post('', location)
}

export const updateLocation = (id, location) => {
    return locationApi.put(`/${id}`, location)
}

export const getAllLocations = () => {
    return locationApi.get('/tree');
}

export const getLocationTree = () => {
    return locationApi.get(`/tree`)
}

export const getLocationChildren = id => {
    return locationApi.get(`/tree/${id}`)
}

export const deleteLocation = id => {
    // return locationApi.delete(`${id}`);
    // this is how it was defined in the api...
    return locationApi.delete(`${id}?id=${id}`);
}
