import { Col, Row, Select } from 'antd';
import { Checkbox } from 'formik-antd';
import { useState } from 'react';
import { groupBy } from '../../../utils/helpers';

const TaskCreation = ({
  addType,
  addValue,
  index,
  updateCheckBox,
  value,
  actionOptionTemplates,
}) => {
  const { Option } = Select;
  const defaultDisplay = (
    <Select size='large' style={{ width: '100%' }}></Select>
  );
  const [option, setOption] = useState(defaultDisplay);

  //function setValues(val) {
  //  let selectOptions = (
  //    <Select
  //      size='large'
  //      style={{ width: '100%' }}
  //      onChange={addTaskValue}
  //      defaultValue={value.requiredValue}
  //    >
  //      {actionOptionTemplates
  //        .filter((f) => f.actionType === 'TASK' && f.valueType === val)
  //        .map((item) => {
  //          return (
  //            <Option value={item.value} key={item.value}>
  //              {item.valueDisplay}
  //            </Option>
  //          );
  //        })}
  //    </Select>
  //  );
  //  setOption(selectOptions);
  //}
  const addTaskType = (val) => {
    //setValues(val);
    addType(val, index);
  };
  const addTaskValue = (val) => {
    addValue(`${val}`, index);
  };

  const updatePatientCheckbox = (val) => {
    updateCheckBox(val.target.checked, index);
  };

  const returnValues = (val) => {
    const selectOptions = (
      <Select
        size='large'
        style={{ width: '100%' }}
        onChange={addTaskValue}
        defaultValue={value.value}
      >
        {actionOptionTemplates
          .filter((f) => f.actionType === 'TASK' && f.valueType === val)
          .map((item) => {
            return (
              <Option value={item.value} key={item.value}>
                {item.valueDisplay}
              </Option>
            );
          })}
      </Select>
    );
    return selectOptions;
  };

  const rules = actionOptionTemplates.filter((f) => f.actionType === 'TASK');
  const groupedTasks = groupBy(rules, 'valueType');

  return (
    <>
      <label>Required Value:</label>

      <Row style={{ width: '100%' }}>
        <Col span={8}>
          <Select
            onChange={addTaskType}
            size='large'
            style={{ width: '100%' }}
            defaultValue={value.taskType ?? 'FORM'}
          >
            {groupedTasks.map((item) => {
              return (
                <Option value={item.valueType} key={item.valueType}>
                  {item.valueType}
                </Option>
              );
            })}
          </Select>
        </Col>
        {/*<Col span={16}>{option}</Col>*/}
        <Col span={16}>{returnValues(value?.taskType)}</Col>
        <Checkbox
          onChange={updatePatientCheckbox}
          value={index}
          name={`createPatientChecklist${[index]}`}
        >
          Create as Task for Patient
        </Checkbox>
      </Row>
    </>
  );
};

export default TaskCreation;
