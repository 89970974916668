import { Tooltip, Button, Space, Modal, Switch, Row, Col, Tree, Divider } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import {
  deleteLocation,
  getLocationTree
} from "api/locations";
import DeleteModal from "components/Modals/DeleteModal";
import React, { useState } from "react";
import CRUDDataTable from "components/DataTable/CRUDDataTable";
import CreateCollateralForm from "../Collateral/components/CreateCollateralForm";
import { Link, useParams } from "react-router-dom";
import LocationForm from "./components/LocationForm";
import ActionButtonsDropdown from "components/ActionButtonsDropdown";
import { isCurrentPermissionKeyPermitted } from "utils";
import { useSelector } from "react-redux";
import { Badge } from 'react-bootstrap';

const Locations = (props) => {
  const { id } = useParams();
  const [isDeleteModalVisible, setDeleteModalVisibility] = useState("");
  const [locationId, setLocationId] = useState();
  const [draw, setDraw] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(() => {});
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const permissionState = useSelector((state) => state.permissions);
  const cols = [
    {
      title: <strong>Name</strong>,
      dataIndex: "name",
      key: "name",
      sorter: isCurrentPermissionKeyPermitted('locations:sorting', permissionState),
      className: 'Location-Name-Column',
      render: (value, record, index) => {
        let statusBadge = null;

        if(record?.status){
          if (record?.status.toLowerCase() === 'active') {
            statusBadge = <Badge variant='success'>Active</Badge>
          }

          if (record?.status.toLowerCase() === 'inactive') {
              statusBadge = <Badge variant='danger'>Inactive</Badge>
          }
        }

        return <>
          <div>
            <span className="mr-3">
              {record?.name} {statusBadge}
            </span>
          </div>
        </>;
      }
        /*    render: (text, record, value) => {
                return record?.childrenAllowed
                    ? <Link to={`/locations/${record?.id}`}>{text}</Link>
                    : text
            }*/
    },
    {
      title: <strong>Email | ContactNumber</strong>,
      dataIndex: 'contact',
      key: 'contact',
      sorter: false,
      className: 'Location-Email-Contact-Column',
      render: (text, record, value) => {
        let email = record?.email ? <a href={`mailto:${record?.email}`}>{record?.email}</a>:null;
        let contactNumber = record?.contactNumber? `${record?.contactNumber}`:null;
        return <>
          {email} {email && contactNumber?' | ':null} {contactNumber}
        </>;
      }
    },
    {
      title: <strong>Unique Id</strong>,
      dataIndex: 'uniqueId',
      key: 'uniqueId',
      sorter: false,
      render: (text, record, value) => {
        return <>
          {record?.uniqueId}
        </>;
      }
    },
    {
      title: <strong>Address</strong>,
      dataIndex: 'address',
      key: 'address',
      sorter: false,
      render: (text, record, value) => {
        return <>
          {record?.address?.line ? record?.address?.line + ',' : ''} {record?.address?.city} {record?.address?.postalCode}
        </>;
      }
    },
    {
      title: isCurrentPermissionKeyPermitted(
        "locations:options",
        permissionState
      ) ? (
        <strong>Options</strong>
      ) : null,
      dataIndex: "id",
      key: "id",
      sorter: false,
      render: (text, record, value) => {
        const locationActionOptions = [
          {
            permissionKey: "locations:options:update",
            label: "Update Location",
            icon: <EditOutlined />,
            onClick: () => {
              setLocationId(text);
              setSelectedLocation(record);
              setEditModalVisible(true);
            },
          },
          {
            permissionKey: "locations:options:delete",
            label: "Delete Location",
            icon: <DeleteOutlined />,
            onClick: () => {
              setDeleteModalVisibility(text);
              setLocationId(text);
            },
          },
        ];

        const permittedLocationActionOptions = locationActionOptions.filter(
          (locationActionOption) =>
            isCurrentPermissionKeyPermitted(
              locationActionOption.permissionKey,
              permissionState
            )
        );

        return <Row>
         {isCurrentPermissionKeyPermitted(
            "locations:options",
            permissionState
          ) && <ActionButtonsDropdown menus={permittedLocationActionOptions} />}
        </Row>
      },
    },
  ];

  return (
    <>
      <DeleteModal
        entity="location"
        visible={isDeleteModalVisible}
        setVisibility={setDeleteModalVisibility}
        entityId={locationId}
        deleteFunction={deleteLocation}
        onSuccess={() => setDraw(draw + 1)}
      />
      <LocationForm
        onSuccess={() => setDraw(draw + 1)}
        visible={isEditModalVisible}
        setVisibility={setEditModalVisible}
        locationId={locationId}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
      />
      <CRUDDataTable
        draw={draw}
        entity="location"
        fetchDataFunction={async (params) => {
          // if (id!==undefined)
          //     return await getLocationChildren(id);
          let res = await getLocationTree();
          addKeys(res.data.data);
          return res;
        }}
        createModal={<LocationForm />}
        columns={cols}
        isButtonShown={isCurrentPermissionKeyPermitted(
          "locations:new",
          permissionState
        )}
        rowKey="id" // this SUPPOSEDLY should be the solution, but it doesn't work.
      />
    </>
  );
};

/**
 * Reason for this is because upon further investigation,
 * the expandable tree in ant table is actually reliant on
 * them having "keys" though i'm not sure as to how to make
 * it reliant on the provided id instead, for now this is a temporary hack
 * @param entry
 * @param i
 */
const addKeys = (entry, i = 0) => {
  entry.forEach((item, index) => {
    item.key = i++;
    if (item.children.length > 0) {
      addKeys(item.children, i);
    } else {
      delete item.children;
    }
  });
};

export default Locations;
