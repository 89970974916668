import React, { useEffect, useState } from 'react';

import { message } from 'antd';

import { useDispatch } from 'react-redux';

import { setTopbarVisibility, toggleSideBarVisibility } from 'redux/actions/ui';

import FormEditor from 'pages/FormBuilder/Editor';

import lz from 'lzutf8';
import { useParams } from 'react-router';
import { getFormById } from '../../api/forms';
import { getAppointmentFormSubmission } from '../../api/formsSubmissions';

const FormBuilder = ({ 
    isEditor = true, 
    formId = null, 
    activeSubmissionFormId = null,
    willFieldsDisable = false
}) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [jsonContent, setJsonContent] = useState(null);
    const [formData, setFormData] = useState({});
    const [formSubmissionContent, setFormSubmissionContent] = useState([]);

    const requestFormContent = async activeSubmissionFormId => {
        try {
            const response = await getAppointmentFormSubmission(activeSubmissionFormId);
            const formSubmissionContentResponse = response.data.content;
            if (formSubmissionContentResponse) {
                setFormSubmissionContent(JSON.parse(formSubmissionContentResponse));
            }
        } catch (error) {
            console.log(error);
            message.error('Error occured while building the form');
        }
    };

    useEffect(() => {
        if (isEditor) {
            dispatch(setTopbarVisibility(false));
            dispatch(toggleSideBarVisibility(false));
        }
        (async () => {
            const requestFormId = formId ? formId : id;
            let data = await getFormById(requestFormId);
            let responseFormData = data.data;
            let content = responseFormData.content;

            setFormData(responseFormData);

            let form = lz.decompress(lz.decodeBase64(content));
            setJsonContent(form);

            if (activeSubmissionFormId) requestFormContent(activeSubmissionFormId);
        })();
        if (isEditor) {
            return () => {
                dispatch(setTopbarVisibility(true));
                dispatch(toggleSideBarVisibility(true));
            };
        }
    }, []);

    return <FormEditor 
                isEditor={isEditor} 
                jsonContent={jsonContent} 
                formData={formData} 
                formSubmissionContent={formSubmissionContent}
                willFieldsDisable={willFieldsDisable}
            />;
};

export default FormBuilder;
