import {BASE_API_URL} from "./config";
import {createAxios} from "./axios";

const messagesApi = createAxios({
  baseURL: BASE_API_URL
})

export const getMessagesDataTable = (id, params) => {
  return messagesApi.get(`/messages`, {
    params: {
      page: params.page,
      draw: 1,
      start: params.start,
      length: params.length,
      search: params.search,
      sortColumn: params.sortColumn,
      sortDirection: params.sortDirection,
      appointmentId: id
    }
  })
}

export const createMessage = (id, payload) => {
  return messagesApi.post(`/messages`, {
    appointmentId: id,
    ...payload
  })
}

export const deleteMessage = id => {
  return messagesApi.delete(`/messages/${id}`);
}

export const getMessage = (id) => {
  return messagesApi.get(`/messages/${id}`);
}

export const getNotesDatatable = (id, params) => {
  return messagesApi.get(`/notes`, {
    params: {
      appointmentId: id,
      ...params
    }
  });
}


export const createNote = (id, payload) => {
  return messagesApi.post(`/notes`, {
    appointmentId: id,
    ...payload
  })
}