import {
    SET_EDITOR_UTILITIES,
    SET_EDITOR_FOCUSED,
    SET_CONTENT_LOADED
} from 'redux/actions/types/formBuilder';

export const setEditorUtilities = utilities => ({
    type: SET_EDITOR_UTILITIES,
    utilities
});

export const setEditorFocused = isEditorFocused => ({
    type: SET_EDITOR_FOCUSED,
    isEditorFocused
});

export const setContentLoaded = isContentLoaded => ({
    type: SET_CONTENT_LOADED,
    isContentLoaded
});