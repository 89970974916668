import * as React from "react"

function PatientIcon(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M408.687 353.18l-33.008-29.861a34.55 34.55 0 00-23.177-8.928H175.865a34.55 34.55 0 00-23.177 8.928L119.68 353.18a34.887 34.887 0 00-11.483 25.873v87.185c0 19.752 16.012 35.763 35.763 35.763l124.796 10h102.097l12.026-46.881a2.44 2.44 0 002.44-2.44l34.851-23.073v-60.554c0-9.86-4.172-19.258-11.483-25.873z"
        fill="#5d91b9"
      />
      <path
        d="M383.471 156.012v47.439l-238.576-6.96v-86.479h136.249z"
        fill="#be694e"
      />
      <path d="M359.311 202.74l24.16.711v-93.439h-24.16z" fill="#ac583e" />
      <path
        d="M408.687 353.18l-33.008-29.861a34.55 34.55 0 00-23.177-8.928H320.09a34.55 34.55 0 0123.177 8.928l33.008 29.861a34.887 34.887 0 0111.483 25.873V499.56a2.44 2.44 0 01-2.439 2.44h22.412a2.44 2.44 0 002.44-2.44l10-59.953v-60.554a34.896 34.896 0 00-11.484-25.873z"
        fill="#4980ac"
      />
      <path
        d="M363.126 439.607V512h54.604a2.44 2.44 0 002.44-2.44v-69.953z"
        fill="#ffcdbe"
      />
      <path
        d="M387.758 439.607v69.954a2.441 2.441 0 01-2.439 2.44h32.412a2.44 2.44 0 002.44-2.439v-69.953h-32.413z"
        fill="#ebae9d"
      />
      <g fill="#4980ac">
        <path d="M172.967 507v-98.956a7.726 7.726 0 10-15.452 0V507zM370.853 512V408.044a7.726 7.726 0 10-15.452 0V512z" />
      </g>
      <path
        d="M231.771 239.739v76.083c0 17.901 14.511 32.412 32.412 32.412s32.412-14.511 32.412-32.412v-76.083z"
        fill="#ebae9d"
      />
      <path
        d="M370.576 234.026H157.791c-12.606 0-22.826-10.22-22.826-22.826s10.22-22.826 22.826-22.826h212.785c12.606 0 22.826 10.22 22.826 22.826s-10.22 22.826-22.826 22.826z"
        fill="#ffcdbe"
      />
      <path
        d="M345.604 140.891v98.451c0 22.953-18.607 41.561-41.561 41.561h-79.72c-22.953 0-41.561-18.607-41.561-41.561v-86.18c0-4.188 2.538-7.992 6.444-9.502 16.728-6.467 32.579-15.791 45.699-27.491 3.209-2.862 7.802-3.487 11.687-1.642 28.149 13.371 59.61 19.702 90.744 18.383 4.518-.192 8.268 3.459 8.268 7.981z"
        fill="#ffcdbe"
      />
      <path
        d="M337.336 132.91c-31.134 1.319-62.594-5.012-90.744-18.383-3.885-1.845-8.477-1.221-11.687 1.642-.27.241-.548.474-.82.713 26.806 11.754 56.337 17.268 85.582 16.029 4.517-.191 8.267 3.459 8.267 7.981v98.451c0 22.953-18.607 41.561-41.561 41.561h17.669c22.953 0 41.561-18.608 41.561-41.561v-98.451c.001-4.523-3.749-8.174-8.267-7.982z"
        fill="#ebae9d"
      />
      <g fill="#766d78">
        <path d="M221.336 198.368c-4.258 0-8.514-.82-12.741-2.461a7.727 7.727 0 015.592-14.406c4.812 1.868 9.489 1.868 14.301 0a7.73 7.73 0 019.999 4.407 7.729 7.729 0 01-4.407 9.999c-4.229 1.64-8.487 2.461-12.744 2.461zM307.03 198.368c-4.258 0-8.514-.82-12.741-2.461a7.727 7.727 0 015.592-14.406c4.811 1.867 9.489 1.869 14.3 0a7.73 7.73 0 019.999 4.407 7.729 7.729 0 01-4.407 9.999c-4.229 1.64-8.486 2.461-12.743 2.461z" />
      </g>
      <path
        d="M290.117 474.295v18.366c0 10.681-8.658 19.339-19.339 19.339h-36.756L152.8 502v-47.044h117.979c10.68 0 19.338 8.659 19.338 19.339z"
        fill="#ffcdbe"
      />
      <path
        d="M225.344 436.65a7.73 7.73 0 01-7.586-6.304l-21.465-114.532a7.728 7.728 0 0115.189-2.847l21.465 114.532a7.728 7.728 0 01-7.603 9.151z"
        fill="#62a4fb"
      />
      <path
        d="M97.414 428.923h141.36c3.084 0 5.584 2.5 5.584 5.584v67.158c0 5.708-4.627 10.336-10.336 10.336h-103.99c-21.098 0-38.202-17.103-38.202-38.202v-39.292c0-3.084 2.5-5.584 5.584-5.584z"
        fill="#80b6fc"
      />
      <path
        d="M234.022 489.061h-103.99c-21.098 0-38.201-17.104-38.201-38.202v22.939c0 21.098 17.103 38.202 38.202 38.202h103.99c5.708 0 10.336-4.627 10.336-10.336v-22.939c-.002 5.709-4.629 10.336-10.337 10.336z"
        fill="#62a4fb"
      />
      <path
        d="M245.552 0h37.262c55.592 0 100.659 45.066 100.659 100.659v58.451l-238.579-43.187V100.66C144.894 45.066 189.96 0 245.552 0z"
        fill="#ddebfd"
      />
      <g fill="#bed9fd">
        <path d="M282.814 0h-24.161c55.592 0 100.659 45.066 100.659 100.658v54.077l24.161 4.374v-58.451C383.473 45.066 338.406 0 282.814 0z" />
        <path d="M368.118 47.211L228.267 72.529a7.725 7.725 0 00-6.226 8.979 7.729 7.729 0 008.979 6.226L375.596 61.56a100.374 100.374 0 00-7.478-14.349zM170.491 33.6a100.905 100.905 0 00-12.864 18.033l183.28-33.184a100.385 100.385 0 00-21.9-11.738z" />
        <path d="M148.653 73.321l234.82 42.503-.007-15.705-229.385-41.518a99.965 99.965 0 00-5.428 14.72z" />
      </g>
    </svg>
  )
}

export default PatientIcon
