import React, { useState, Suspense, useEffect } from 'react';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import { Button as AntdButton, Modal, Tooltip, Spin, message } from 'antd';

import Editor from 'pages/FormBuilder';

import {
  FileSearchOutlined,
  FileTextOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  getAppointmentFormSubmissions,
  updateFormSubmissions,
  deleteFormSubmission,
  getMostRecentAnswerPrefill
} from 'api/formsSubmissions';
import { getAllSettings } from 'api/systemSettings';
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import { Badge, Col, Container, Row } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/AppointmentForms.scss';

import CreateFormModal from './CreateFormModal';
import {
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grow,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DeleteModal from 'components/Modals/DeleteModal';
import { setWillTaskListRefresh } from 'redux/actions/appointment';
import { isCurrentPermissionKeyPermitted } from 'utils';
import PermissifiedComponent from 'components/PermissifiedComponent';
import { setContentLoaded } from 'redux/actions/formBuilder';
import {
    FcDocument
} from 'react-icons/all'
import { uuidv4 } from 'utils';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow in ref={ref} {...props} />;
});

const AppointmentForm = ({ appointmentId }) => {
  const [isFormModalVisible, setFormModalVisibility] = useState(false);

  const [title, setTitle] = useState();
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [isPatientFormSavingEnabled, setPatientFormEnability] = useState(true);
  const [activeSubmissionFormId, setActiveSubmissionFormId] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [deleteFormId, setDeleteFormId] = useState('');
  const [isFormPrefilled, setFormPrefilledStatus] = useState(true);
  const [currentFormData, setCurrentFormData] = useState(null);
  const [draw, setDraw] = useState(0);
  const formBuilderState = useSelector((state) => state.formBuilder);
  const permissionState = useSelector((state) => state.permissions);
  const dispatch = useDispatch();

  const nonValuedControls = [
    'Container',
    'Card',
    'Panel',
    'Column',
    'Button',
    'Text',
    'Grid',
  ];

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const generatePermittedMenuItems = (menuList) => {
    let newMenuList = [];

    menuList &&
      menuList.map((menuItem) => {
        if (
          isCurrentPermissionKeyPermitted(
            menuItem.permissionKey,
            permissionState
          )
        ) {
          delete menuItem['permissionKey'];
          newMenuList.push(menuItem);
        }
      });

    return newMenuList;
  };

  const generateOptionsColumnHeader = () => {
    let optionsColumnHeader = null;

    if (
      isCurrentPermissionKeyPermitted(
        'appointments:forms:list:columns:options',
        permissionState
      )
    ) {
      optionsColumnHeader = <strong>Options</strong>;
    }

    return optionsColumnHeader;
  };

    const generateStatusBadge = (status) => {
        let statusBadge = null;

        if (status === 'NEW') {
            statusBadge = <Badge variant='info'>NEW</Badge>;
        }

        if (status === 'DRAFT') {
            statusBadge = <Badge variant='secondary'>DRAFT</Badge>;
        }

        if (status === 'COMPLETE') {
            statusBadge = <Badge variant='success'>COMPLETE</Badge>;
        }

        return statusBadge
    }

  const columns = [
    {
      title: <strong>Name</strong>,
      dataIndex: 'formDesign.name',
      key: 'formDesign.name',
      sorter: true,
      render: (text, record, value) => {

        return (
          <Link onClick={() => previewForm(record)}>
            <FileTextOutlined />
            <div className="message-content">
              {' '}
              <span style={{ position: 'relative', top: '3px' }}>
                {record?.formDesign.name}
              </span>{' '}
              {generateStatusBadge(record?.status)}
            </div>
          </Link>
        );
      },
    },
    {
      title: () => generateOptionsColumnHeader(),
      dataIndex: 'id',
      key: 'id',
      sorter: false,
      render: (text, record, value) => {
        const menuList = [
          {
            label: 'Preview Form',
            permissionKey: 'appointments:forms:options:preview_form',
            icon: <FileSearchOutlined />,
            onClick: () => previewForm(record),
          },
          {
            label: 'Delete Form',
            permissionKey: 'appointments:forms:options:delete_form',
            icon: <DeleteOutlined />,
            onClick: () => setDeleteFormId(record?.id),
          },
        ];

        return (
          isCurrentPermissionKeyPermitted(
            'appointments:forms:list:columns:options',
            permissionState
          ) && (
            <ActionButtonsDropdown
              menus={generatePermittedMenuItems(menuList)}
            />
          )
        );
      },
    },
  ];

    const previewForm = (record) => {
        setActiveSubmissionFormId(record?.id);
        setSelectedFormId(record?.formDesign.id);
        setTitle(record?.formDesign.name);
        setCurrentFormData(null);
        setSelectedForm({
            ...record,
            indexerId: uuidv4()
        });
        setFormModalVisibility(true);
        dispatch(setContentLoaded(false));
    };

    const enablePatientFormSavingForAdmin = async () => {
        const response = await getAllSettings('TENANT');

        if (response?.data?.TENANT?.length > 0) {
            const enablePatientFormSaving = response.data.TENANT.find(tenant => tenant.name === 'ENABLE_PATIENT_FORM_SAVING');
            
            if (enablePatientFormSaving?.value === 'false' || enablePatientFormSaving?.value === '' || enablePatientFormSaving?.value === false) {
                setPatientFormEnability(false);
            }
        }
    }

    useEffect(() => {
        enablePatientFormSavingForAdmin();
    }, []);

    useEffect(() => {
        if (selectedForm) {
            (async () => {
                const response = await getMostRecentAnswerPrefill({
                    formSubmissionId: activeSubmissionFormId
                });

                if (response?.data?.data?.content) {
                    setCurrentFormData(response.data.data.content);
                }
            })();
        }
    }, [selectedForm]);
    
    useEffect(() => {
        if (formBuilderState.utilities && currentFormData) {
            const {
                actions: { setProp },
                query,
                nodes,
            } = formBuilderState.utilities;

            const formDesignData = selectedForm.formDesign;

            if (selectedForm.status !== 'COMPLETE') {
                if (currentFormData && formDesignData && formDesignData.prefillEnabled) {
                    (async () => {
                        if (currentFormData) {
                            const formSubmissionContent = JSON.parse(
                                currentFormData
                            );

                            Object.keys(nodes).map(async (serializeNodeKey) => {
                                const nodeControl =
                                    query.getSerializedNodes()[serializeNodeKey];

                                if (!nonValuedControls.includes(nodeControl.displayName)) {
                                    const formSubmissionItem = formSubmissionContent.find(
                                        (jsonFormItem) => jsonFormItem.id === serializeNodeKey
                                    );

                                    if (nodeControl?.props?.isPrefillable) {
                                        await setProp(serializeNodeKey, (props) => {
                                            if (!props.value) {
                                                props.value = formSubmissionItem.value;
                                            }
                                        });
                                    }
                                }
                            });

                            setCurrentFormData(null);
                        }
                    })();
                }
            }
        }
    }, [currentFormData, formBuilderState.utilities]);

    useEffect(
        (loadAppointmentForm) => {
            setDraw(draw + 1);
        },
        [appointmentId]
    );

    const handleModalCancel = () => {
        // setFormPrefilledStatus(true);
        setFormModalVisibility(false);
        dispatch(setContentLoaded(false));
    };

  const validateAndGetFormValues = () => {
    const findGridName = (obj) => {
      const nodeControl = query.getSerializedNodes()[obj.parent];
      if (nodeControl.displayName === 'Grid') {
        const gridObj = query.getSerializedNodes()[obj.parent];
        return gridObj.props ? gridObj.props.name : '';
      } else if (nodeControl.displayName === 'Column') {
        const parentNode = query.getSerializedNodes()[nodeControl.parent];
        if (parentNode.displayName === 'Container') {
          const parentNode2 = query.getSerializedNodes()[parentNode.parent];
          if (parentNode2.displayName === 'Grid') {
            const gridObj = query.getSerializedNodes()[parentNode.parent];
            return gridObj.props ? gridObj.props.name : '';
          }
        }
      }
      return '';
    };

    const {
      actions: { setProp },
      query,
      nodes,
    } = formBuilderState.utilities;

    let data = [];
    let errors = [];

    const gridValueObject = {};

    function isNodeOrParentNodeHidden(node) {
      if (node.hidden) return true;
      else if (!node.parent) return false;
      else
        return isNodeOrParentNodeHidden(
          query.getSerializedNodes()[node.parent]
        );
    }
    //rowIndex
    Object.keys(nodes).map((serializeNodeKey) => {
      const nodeControl = query.getSerializedNodes()[serializeNodeKey];

      if (nodeControl.displayName === 'Grid') {
        const gridName = nodeControl.props.name;
        if (gridName) {
          gridValueObject[gridName] = {};
          gridValueObject[gridName].gridId = nodeControl.props.name;
          gridValueObject[gridName].gridTitle = nodeControl.props.cardTitle;
          gridValueObject[gridName].gridSerializedId = serializeNodeKey;
          if (!gridValueObject[gridName].values) {
            gridValueObject[gridName].values = [];
          }
        }
      }

      if (!nonValuedControls.includes(nodeControl.displayName)) {
        if (nodeControl.props.type === 'datetime' && !nodeControl.props.value) {
          nodeControl.props.value = moment();
        }

        if (
          nodeControl.props.isRequired &&
          !nodeControl.props.value &&
          !isNodeOrParentNodeHidden(nodeControl)
        ) {
          errors.push(serializeNodeKey);

          setProp(serializeNodeKey, (props) => {
            if (props.error) {
              if (
                nodeControl.props.name === 'input' &&
                nodeControl.props.type === 'datetime'
              )
                props.value = moment();
              if (nodeControl.props.name === 'select')
                props.value =
                  props.options &&
                  props.options.length > 0 &&
                  props.options[0].value;
              if (
                nodeControl.props.name === 'input' &&
                nodeControl.props.type === 'text'
              )
                props.value = '';

              props.error.required.isVisible = true;
            }
          });
        } else {
          setProp(serializeNodeKey, (props) => {
            if (props.error) props.error.required.isVisible = false;
          });

          delete errors[serializeNodeKey];

          let nodeValue = '';

          if (
            nodeControl.props.value &&
            !isNodeOrParentNodeHidden(nodeControl)
          ) {
            if (nodeControl.props.type === 'datetime') {
              const newDate = new Date(nodeControl.props.value).toISOString();
              nodeValue = newDate;
            } else {
              nodeValue = nodeControl.props.value;
            }
          }

          if (nodeControl.props.isGridComponent === 2) {
            const gridName = findGridName(nodeControl);
            if (gridName) {
              if (!gridValueObject[gridName]) {
                gridValueObject[gridName] = {};
              }
              if (!gridValueObject[gridName].values) {
                gridValueObject[gridName].values = [];
              }

              gridValueObject[gridName].values.push({
                id: serializeNodeKey,
                name: nodeControl.props.name,
                rowId: nodeControl.props.rowId,
                label:
                  nodeControl.props.labelText &&
                  nodeControl.props.labelText.replace(':', ''),
                value: nodeValue,
                subTitle: nodeControl.props.subTitle
              });
            }
          } else {
            if (
              !nodeControl.props.isGridComponent ||
              nodeControl.props.isGridComponent !== 1
            ) {
              data.push({
                id: serializeNodeKey,
                name: nodeControl.props.name,
                label:
                  nodeControl.props.labelText &&
                  nodeControl.props.labelText.replace(':', ''),
                value: nodeValue,
                subTitle: nodeControl.props.subTitle
              });
            }
          }
        }
      }
    });

    Object.values(gridValueObject).forEach((grid) => {
      data.push({
        id: grid.gridSerializedId,
        gridId: grid.gridId,
        name: 'Grid',
        label: grid.gridTitle,
        value: grid.values,
      });
    });

    return {
      data,
      errors,
    };
  };

  const hide = () => {
      setFormModalVisibility(false);
      dispatch(setContentLoaded(false));
      message.loading('Saving form as draft, please wait...', 0);
  }

  const saveAsDraftClick = async () => {
    const {
      actions: { setProp },
      query,
      nodes,
    } = formBuilderState.utilities;

    function isNodeOrParentNodeHidden(node) {
      if (node.hidden) return true;
      else if (!node.parent) return false;
      else
        return isNodeOrParentNodeHidden(
          query.getSerializedNodes()[node.parent]
        );
    }

    let data = [];

    Object.keys(nodes).map((serializeNodeKey) => {
      const nodeControl = query.getSerializedNodes()[serializeNodeKey];

      if (!nonValuedControls.includes(nodeControl.displayName)) {
        let nodeValue = '';

        if (nodeControl.props.value && !isNodeOrParentNodeHidden(nodeControl)) {
          nodeValue = nodeControl.props.value;
        }

        // if (nodeControl.props.value) {
        data.push({
          id: serializeNodeKey,
          name: nodeControl.props.name,
          label:
            nodeControl.props.labelText &&
            nodeControl.props.labelText.replace(':', ''),
          value: nodeValue,
          subTitle: nodeControl.props.subTitle
        });
        // }
      }
    });

    try {
      await updateFormSubmissions(
        activeSubmissionFormId,
        JSON.stringify({
          content: JSON.stringify(data),
          status: 'DRAFT',
        })
      );

      hide();

      message.success('Form successfully submitted as draft');
    } catch (error) {
      hide();
      console.log('error', error);
      message.error('Form submission failed');
    }
  };

  const saveFormAsComplete = async () => {
    const { data, errors } = validateAndGetFormValues();

    if (errors && errors.length < 1) {
      const hide = message.loading('Saving form, please wait...', 0);

      try {
        await updateFormSubmissions(
          activeSubmissionFormId,
          JSON.stringify({
            content: JSON.stringify(data),
            status: 'COMPLETE',
          })
        );

        hide();
        setDraw(draw + 1);
        dispatch(setWillTaskListRefresh(true));
        message.success('Form successfully submitted');
      } catch (error) {
        hide();
        console.log('error', error);
        message.error('Form submission failed');
      }
    } else {
      message.error('Please fill in the required field(s).');
    }
  };

  return (
    <>
      <DeleteModal
        entity='form'
        visible={deleteFormId}
        setVisibility={setDeleteFormId}
        entityId={deleteFormId}
        deleteFunction={deleteFormSubmission}
        onSuccess={() => setDraw(draw + 1)}
      />
      <Suspense fallback={<Spin />}>
        <Dialog
          fullScreen={true}
          fullWidth={true}
          maxWidth='lg'
          scroll='paper'
          open={isFormModalVisible}
          aria-labelledby='responsive-dialog-title'
          TransitionComponent={Transition}
        >
          <DialogTitle
            id='responsive-dialog-title'
            style={{
              borderBottom: '1px solid #ddd',
              paddingLeft: '8.2%',
              paddingRight: '8.2%'
            }}
          >
            <strong><FcDocument fontSize='3.5rem' /> {title}</strong>&nbsp;
            {selectedForm?.status ? <span style={{ fontSize: '1.3rem', position: 'relative', top: '-4px' }}>{generateStatusBadge(selectedForm?.status)}</span> : null}
          </DialogTitle>
          <DialogContent
            style={{ 
                minHeight: '60vh',
                transition: '.4s',
                background: '#ddd',
                padding: '2% 8%'
            }}
          >
            <Editor
                isEditor={false}
                formId={selectedFormId}
                activeSubmissionFormId={activeSubmissionFormId}
                willFieldsDisable={!isPatientFormSavingEnabled}
            />
          </DialogContent>
          <DialogActions
            style={{
              borderTop: '1px solid #ddd',
            }}
          >
            <Container className='View-Form-Action' fluid>
              <Row>
                <Col xs={12} sm={6} className='text-left p-0'>
                  <PermissifiedComponent permissionKey='appointments:forms:save_as_draft'>
                    {isPatientFormSavingEnabled && <AntdButton
                      size='large'
                      className='mt-3'
                      onClick={saveAsDraftClick}
                    >
                      Save as draft
                    </AntdButton>}
                  </PermissifiedComponent>
                </Col>
                <Col xs={12} sm={6} className='text-right p-0'>
                  <AntdButton
                    size='large'
                    className='mr-3 mt-3'
                    key='Close'
                    onClick={handleModalCancel}
                  >
                    Close
                  </AntdButton>
                  <PermissifiedComponent permissionKey='appointments:forms:submit'>
                    {isPatientFormSavingEnabled && <AntdButton
                      size='large'
                      className='mt-3'
                      onClick={saveFormAsComplete}
                      type='primary'
                    >
                      Submit
                    </AntdButton>}
                  </PermissifiedComponent>
                </Col>
              </Row>
            </Container>
          </DialogActions>
        </Dialog>
      </Suspense>
      <CRUDDataTable
        fetchDataFunction={async (params) => {
          params = {
            ...params,
            sortDirection: 'DESC',
            sortOrder: 'lastModifiedAt',
          };
          const response = await getAppointmentFormSubmissions(
            appointmentId,
            params
          );
          return response;
        }}
        title='Forms'
        entity='form'
        highlightRow={(row, i) => row.staffReadStatus === 'UNREAD'}
        createModal={<CreateFormModal appointmentId={appointmentId} />}
        isButtonShown={isCurrentPermissionKeyPermitted(
          'appointments:forms:new',
          permissionState
        )}
        columns={columns}
        draw={draw}
      />
    </>
  );
};

export default AppointmentForm;
