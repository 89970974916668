import React, {useRef} from "react";
import {Button, Col, Modal, Row} from "antd";
import {Formik} from "formik";
import {Form, Input, SubmitButton} from "formik-antd";
import {updateStatus} from "../../api/tasks";

const TaskRejectionForm =
  ({id, visible, setVisibility, onSuccess}) => {

    const create = values => {
      updateStatus(id, {
        rejectionReason: values.reason,
        status: 'REJECTED'
      })
        .then(res => {
          setVisibility(false)
          onSuccess();
        })
    }
    const formikRef = useRef(null);

    return (<>
      <Modal
        title='Please enter reason for rejecting this task'
        visible={visible}
        onCancel={() => {
          setVisibility(false)
        }}
        footer={null}
        destroyOnClose={true}
        keyboard={false}
        maskClosable={false}
      >
        <Formik
          innerRef={formikRef}
          onSubmit={values => create(values)}
          initialValues={{}}
          render={() => (
            <Form layout='vertical' colon={false}>
              <Form.Item label='Reason' name='reason' className='mb-4'>
                <Input.TextArea name='reason' autoFocus />
              </Form.Item>
              <Row gutter={4} className="d-flex justify-content-end">
                <Col>
                  <Button type="dashed" shape="round" onClick={() => setVisibility(false)}>Cancel</Button>
                </Col>
                <Col>
                  <SubmitButton>Reject</SubmitButton>
                </Col>
              </Row>
            </Form>
          )}
        />

      </Modal>

    </>)

  }

export default TaskRejectionForm;