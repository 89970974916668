import React from 'react';
import { Breadcrumb } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Breadcrumbs(props) {
    const { location } = props;
    const uiState = useSelector(state => state.ui);

    const pathSnippets = location.pathname.split('/').filter(i => i);

    const extraBreadcrumbItems = pathSnippets.map((path, index) => {
        const pathSnippetsLastIndex = pathSnippets.length - 1;
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        const displayUrl = path.charAt(0).toUpperCase() + path.slice(1).replace('-', ' ');
        const currentDisplayedUrl = (uiState && Number.isInteger(parseInt(path)) ? uiState.currentBreadcrumbDisplay : displayUrl);
        return (path !== 'dashboard' ? (
            <Breadcrumb.Item key={url}>
                <Link to={url}>{path === 'collateral' ? 'Resources' : (pathSnippetsLastIndex === index ? currentDisplayedUrl : displayUrl)}</Link>
            </Breadcrumb.Item>
        ) : null)
    });

    let breadcrumbItems = extraBreadcrumbItems;

    return (
        <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    );
}

export default withRouter(Breadcrumbs);