import {ERROR_ACTIONS, ErrorAction, ErrorState} from "../types/error-types";

const initialState: ErrorState = {
    message: "",
    details: ""
};

export default function permissionsReducer(state = initialState, action: ErrorAction): ErrorState {
    const { type, payload } = action;
    switch (type) {
        case ERROR_ACTIONS.SET_ERROR_MESSAGE: {
            return {
                ...state,
                message: payload.message,
                details: payload.details
            };
        }
        default:
            return { ...state };
    }
}
