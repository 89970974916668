import { useEffect, useState } from 'react';
import { Input as AntInput, Divider, Modal, Row, Col, message } from 'antd';
import { Formik } from 'formik';
import { Form, ResetButton, SubmitButton, Input, Select } from 'formik-antd';
import { PlusOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import { createRolePermission } from 'api/rolePermissions';

const RoleSchema = Yup.object().shape({
    module: Yup.string()
        .required('Module is required'),
    role: Yup.string()
        .required('Role name is required')
});

const ManageRoleModal = (props) => {
    const [modulesList, setModulesList] = useState([]);
    const [newModule, setNewModule] = useState('');

    useEffect(() => {
        if (props.rolesData) {
            const modules = [...new Set(props.rolesData.map(role => role.module))];
            setModulesList(modules);
        }
    }, [props.rolesData]);

    const saveRole = async (role) => {
        const payload = {
            ...role,
            permissions: []
        };

        try {
            await createRolePermission(payload);

            message.success('Role successfully added');

            if (props.setManageRoleModalVisibility) {
                props.setDrawRolePermission(parseInt(props.drawRolePermission) + 1);
                props.setManageRoleModalVisibility(false);
            }
        } catch (err) {
            message.error('Error occurred while trying to save the new role.');
        }
    };

    return <Modal
                title='New Role'
                {...props}
                footer={null}
                keyboard={false}
                maskClosable={false}
            >
                <Formik
                    initialValues={props.initialValues || {
                        module: '',
                        name: ''
                    }}
                    validationSchema={RoleSchema}
                    onSubmit={(role) => {
                        saveRole(role);
                    }}
                >
                    <Form
                        layout='vertical'
                        colon={true}
                    >
                        <Form.Item
                            className='mb-3'
                            label='Module'
                            name='module'
                        >
                            <Select
                                style={{ width: '100%' }}
                                placeholder='Please select or add a new module'
                                name='module'
                                autoFocus
                                dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <AntInput 
                                        style={{ flex: 'auto' }} 
                                        value={newModule}
                                        onChange={(e) => setNewModule(e.target.value)} 
                                    />
                                    <a
                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                        onClick={() => {
                                            if (newModule) {
                                                setModulesList([
                                                    ...modulesList,
                                                    newModule
                                                ]);
                                                setNewModule('');
                                            }
                                        }}
                                    >
                                        <PlusOutlined /> Add item
                                    </a>
                                    </div>
                                </div>
                                )}
                            >
                                {modulesList.length > 0 && modulesList.map(module => (
                                    <Select.Option key={module} value={module}>{module}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className='mb-3'
                            label='Name'
                            name='role'
                        >
                            <Input name='role' placeholder='Enter role name' />
                        </Form.Item>
                        <Row 
                            gutter={4} 
                            className='d-flex justify-content-end mt-5'
                        >
                            <Col>
                                <ResetButton>Reset</ResetButton>
                            </Col>
                            <Col>
                                <SubmitButton>Save</SubmitButton>
                            </Col>
                        </Row>
                    </Form>
                </Formik>
            </Modal>
};

export default ManageRoleModal;
