export function visitorsHasErrored(state = false, action) {
    switch (action.type) {
        case 'VISITORS_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function visitorsIsLoading(state = false, action) {
    switch (action.type) {
        case 'VISITORS_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function visitors(state = [], action) {
    switch (action.type) {
        case 'VISITORS_FETCH_DATA_SUCCESS':
            return action.visitors;
        case 'VISITORS_REMOVE_VISITOR_SUCCESS':
            const data = state.data;
            // this bit is fancy javascript to replace the data with a new array
            return {
                ...state,
                data: remove(data, data.findIndex(data => data.id === action.visitors))
            }
        default:
            return state;
    }
}

const remove = (visitors,index) => {
    return [...visitors.slice(0,index),
        ...visitors.slice(index+1,visitors.length)];
};