import React, { useState, useEffect } from 'react';
import CreateNoteModal from './CreateNoteModal';
import NoteFormModal from './NoteFormModal';
import { FileTextOutlined } from '@ant-design/icons';
import { getNotesDatatable } from 'api/messages';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import {Link} from 'react-router-dom';

const Notes = ({appointmentId}) => {
  const [draw, setDraw] = useState(0);
  const [selectedNote, setSelectedNote] = useState(null);

  const columns = [
    {
      title: <strong>Notes</strong>,
      dataIndex: "note",
      key: "note",
      sorter: true,
      render: (text, record, value) => {
        return <Link onClick={() => setSelectedNote(record)}>{record?.note}</Link>
      }
    }
  ];

  useEffect(() => {
    if(appointmentId) {
      setDraw(draw + 1);
    }
  }, [appointmentId]);

  const refreshNote = () => {
    setSelectedNote(null);
    setDraw(draw + 1);
  }

  return (
    <>
    <NoteFormModal
      visible={selectedNote}
      setVisibility={refreshNote}
      onSuccess={refreshNote}
      icon={<FileTextOutlined className="mr-2" />}
      appointmentId={appointmentId}
      note={selectedNote}
    />
    <CRUDDataTable
      fetchDataFunction={async (params) => {
        return await getNotesDatatable(appointmentId, params);
      }}
      title="Notes"
      entity="note"
      createModal={
        <CreateNoteModal
          icon={<FileTextOutlined className="mr-2" />}
          appointmentId={appointmentId}
          onSuccess={() => setDraw(draw + 1)}
        />
      }
      draw={draw}
      columns={columns}
    />
    </>
  );
};

export default Notes;
