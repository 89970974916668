import React from "react";
import { Switch } from "react-router-dom";
import routes from '../../routes';
import BasePage from '../../pages/BasePage';
import RouteWithSubRoutes from 'routes/routeWithSubRoutes';
import { connect } from 'react-redux';

const PageWrapper = (props) => {
    const { isSidebarVisible } = props;

    return  <div id='page-wrapper' className={(isSidebarVisible ? 'sidebar-visible' : 'sidebar-hidden')}>
                <Switch>
                    {routes().map((route, i) =>
                        <RouteWithSubRoutes key={i} path={route.path} {...route}>
                            <BasePage 
                                pageName={route.title}>
                                    {route.component}
                            </BasePage>
                        </RouteWithSubRoutes>
                    )}
                </Switch>
            </div>
};

export default connect(
    state => {
        return {
            isSidebarVisible: state.ui.isSidebarVisible,
        }
    }
)(PageWrapper)
