export const hexToRgbA = (hex, opacity) => {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+', ' + opacity + ')';
    }
    throw new Error('Bad Hex');
}

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const capitaliseFirstLetter = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export const insertSpaceBeforeCapitalLetter = (string) => {
    string = string.replace(/([a-z])([A-Z])/g, `$1 $2`);
    string = string.replace(/([A-Z])([A-Z][a-z])/g, `$1 $2`)
    return string;
}

export const getParentPermissionKey = (permissionKey) => {
    if (permissionKey) {
        if (permissionKey.indexOf(':') !== -1) {
            const parentPermissionKey = permissionKey.split(':')[0];
            if (parentPermissionKey) {
                return capitaliseFirstLetter(parentPermissionKey);
            }
        } else {
            return capitaliseFirstLetter(permissionKey);
        }
    }

    return null;
}

export const isCurrentPermissionKeyPermitted = (permissionKey, permissionState) => {
    const parentPermissionKey = getParentPermissionKey(permissionKey);

    if (permissionState?.permissions) {
        return permissionState?.permissions[parentPermissionKey]?.includes(permissionKey);
    }
}

export const permissifyTableColumns = (columns, permissionState) => {
    let permissifiedColumns = [];

    if (columns && Array.isArray(columns) && columns.length > 0) {
        permissifiedColumns = columns.filter(column => isCurrentPermissionKeyPermitted(column.permissionKey, permissionState));
    }

    return permissifiedColumns;
}

export const createGoogleFontLinkTag = (
    source,
    id
) => {
    if (!document.getElementById(id)) {
        const link = document.createElement('link');
        link.id = id;
        link.rel = 'stylesheet';
        link.href = source;
        document.head.appendChild(link);
    } else {
        const link = document.getElementById(id);
        link.href = source;
    }
}

export const isPressEnterKey = (callback) => {
    const listener = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          event.preventDefault();
          callback();
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
    };
}