import React, {FunctionComponent, useEffect} from "react";
import AceEditor from 'react-ace';
import 'brace/mode/javascript';
import 'brace/theme/tomorrow';

interface JSONInputProps {
    defaultValue: string;
    onChange: (value: string, e: any) => void;
}

const JSONInput: FunctionComponent<JSONInputProps> = ({defaultValue, onChange}) => {
    return <AceEditor
        mode="javascript"
        theme="tomorrow"
        name="editor"
        fontSize={14}
        editorProps={{$blockScrolling: true}}
        setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true
        }}
        defaultValue={defaultValue}
        onChange={onChange}
        wrapEnabled={true}
    />
}

export default JSONInput;