import React, { useState } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

const StyledSelect = styled(Select)`
    width: 30%;
`;

const Selector = ({
    dataList,
    valueKey,
    titleKey,
    label,
    placeholder,
    onSelectorChange,
    onSelectClear = false,
    selectClearThreshold = 200
}) => {
    const [selectValue, setSelectValue] = useState(null);

    return (
        <>
            <label>{label}:</label>&nbsp;&nbsp;
            <StyledSelect
                placeholder={placeholder}
                value={selectValue}
                onSelect={(value) => {
                    if (onSelectorChange) {
                        onSelectorChange(value);
                    }

                    if (onSelectClear) {
                        setSelectValue(value);
                        const selectTimerThreshold = setTimeout(() => {
                            setSelectValue(null);
                            if (selectTimerThreshold) {
                                clearTimeout(selectTimerThreshold);
                            }
                        }, selectClearThreshold);
                    } else {
                        setSelectValue(value);
                    }
                }}
                showSearch
            >
                {dataList 
                    && dataList.length > 0 
                    && dataList.map((data, idx) => (
                        <Option
                            key={idx}
                            value={valueKey ? data[valueKey] : JSON.stringify(data)}
                        >
                            {data[titleKey]}
                        </Option>
                    ))}
            </StyledSelect>
        </>
    )
};

export default Selector;
