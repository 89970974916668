import React, {
    FunctionComponent,
    useEffect,
    useRef,
    useState,
} from 'react';
import { 
    Modal, 
    Typography, 
    Row, 
    Col, 
    message,
    Upload
} from 'antd';
import { GrDocumentCsv } from 'react-icons/all';
import { AudioOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Form, Select, ResetButton, SubmitButton } from 'formik-antd';
import { getMessageTemplates } from 'api/messageTemplates';
import * as Yup from 'yup';
import { createBroadcast } from 'api/broadcasts';

const { Title } = Typography;
const { Dragger } = Upload;

interface CreateBroadcastModal {
    visible?: boolean;
    setVisibility?: (visibility: boolean) => void;
    setDraw?: (draw: any) => void;
    draw?: any;
}
  
const CreateTemplateBroadcastModal: FunctionComponent<CreateBroadcastModal> = ({
    visible,
    setVisibility,
    setDraw,
    draw,
}) => {
    const [messageTemplates, setMessageTemplates] = useState([]);

    const BroadcastSchema = Yup.object().shape({
        messageTemplateId: Yup.string()
            .required('Please select message template'),
        file: Yup.mixed()
            .required('Please choose CSV file')
    });

    const fetchMessageTemplates = async () => {
        try {
            const response = await getMessageTemplates();
            setMessageTemplates(response.data);
        } catch (err) {
            message.error("Couldn't fetch message templates, please try again later", 3);
        }
    }

    useEffect(() => {
        fetchMessageTemplates();
    }, []);
  
    return (
      <Modal
        title={
            <Title
                level={4}
                className='mb-0'
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <AudioOutlined className='mr-2' /> New Message Template Broadcast
            </Title>
        }
        visible={visible}
        destroyOnClose
        onCancel={() => {
            if (setVisibility) {
                setVisibility(false);
            }
        }}
        footer={null}
        keyboard={false}
        maskClosable={false}
      >
        <Formik
            initialValues={{ messageTemplateId: '', file: null }}
            validationSchema={BroadcastSchema}
            onSubmit={async (broadcast) => {
                try {
                    await createBroadcast(broadcast, null);
                    if (setVisibility) {
                        setVisibility(false);
                    }
    
                    if (setDraw) {
                        setDraw(draw + 1);
                    }
                    message.success('Message Template is now sending...');
                } catch (err) {
                    if (setVisibility) {
                        setVisibility(false);
                    }
    
                    if (setDraw) {
                        setDraw(draw + 1);
                    }
                    message.success('Message Template sending failed.');
                }
            }}
        >
            {({
                setFieldValue
            }): any => (
                <Form layout='vertical' colon={false}>
                    <Form.Item label='Message Template' name='messageTemplateId'>
                        <Select name='messageTemplateId' autoFocus>
                            {messageTemplates 
                            && messageTemplates.length > 0
                            && messageTemplates.map((messageTemplate: any, idx: any) => {
                                return <Select.Option key={idx} value={messageTemplate.id}>{messageTemplate.templateName}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item name='file' label='Upload CSV'>
                        <Dragger
                            className='Message-Broadcast-Upload'
                            name='file'
                            maxCount={1}
                            listType='picture'
                            accept={'.xlsx, .xls, .csv'}
                            onChange={(info: any) => {
                                if (info.file.status === 'error' || info.file.status === 'success' || info.file.status === 'done') {
                                    setFieldValue('file', info.file.originFileObj, true);
                                }

                                if (info.fileList.length < 1) {
                                    setFieldValue('file', null, true);
                                }
                            }}
                        >
                            <p className="ant-upload-drag-icon">
                                <GrDocumentCsv fontSize='3.5rem' style={{ color: 'rgba(0, 0, 0, 0.45)!important' }} />
                            </p>
                            <p className="ant-upload-hint">Click or drag CSV to this area to upload</p>
                        </Dragger>
                    </Form.Item>
                    <Row gutter={4} className='d-flex justify-content-end'>
                        <Col>
                            <ResetButton>Reset</ResetButton>
                        </Col>
                        <Col>
                            <SubmitButton>Send</SubmitButton>
                        </Col>
                    </Row>
                </Form>
            )}    
        </Formik>
      </Modal>
    );
  };
  
  export default CreateTemplateBroadcastModal;
  