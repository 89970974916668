import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Button, Popover} from "antd";
import FilterComponent from "./FilterComponent";
import {FiFilter} from "react-icons/all";

const DataTableFilter = ({ filterState: {filters, setFilters}, filtersConfig }) => {

    const content =
         <div>
            <Row>
                <Col xs={6}>
                    <strong>Filters</strong>
                </Col>
                <Col xs={6}>
                    <Button
                        style={{ float: 'right' }}
                        type='link'
                        onClick={() => {
                            const newValue = {...filters};
                            Object.keys(newValue).forEach(prop => newValue[prop].value = null);
                            setFilters(newValue);
                        }}
                    >Clear</Button>
                </Col>
            </Row><hr/>
            {
                filtersConfig && filtersConfig.map(filterConfig =>
                    filterConfig && !filterConfig.hidden
                    ? <Row key={filterConfig.paramName} className='mb-2'>
                        <Col xs={12} sm={3}>
                            <span>{filterConfig.label}</span>
                        </Col>
                        <Col xs={12} sm={9}>
                            <FilterComponent
                                value={filters[filterConfig.paramName]}
                                component={filterConfig.component}
                                onChange={value => {
                                    const newValue = {...filters};
                                    newValue[filterConfig.paramName] = {
                                        value,
                                        label: filterConfig.label,
                                        beforeRequest: filterConfig.beforeRequest,
                                        displayFormat: filterConfig.displayFormat
                                    };

                                    setFilters(newValue);
                                }}
                            />
                        </Col>
                    </Row>
                    : null
                )
            }
        </div>
    

    return (
        filtersConfig && filtersConfig.length > 0
        ? <>
            <Popover
                trigger='click'
                content={content}
                placement='bottomRight'
            >
                <Button type='link'><FiFilter /> Filters</Button>
            </Popover>
        </>
        : null
    );
}

export default DataTableFilter;