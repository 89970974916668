import { Button, Divider, Empty, Modal, Space } from 'antd';
import { Formik } from 'formik';
import { Form, Input, ResetButton, SubmitButton } from 'formik-antd';
import React, { useEffect, useRef, useState } from 'react';
import RuleCreation from './RuleCreation';
import TaskCreation from './TaskCreation';
import { BsPlus } from 'react-icons/bs';
import { createTemplate, getTemplate } from '../../../api/actionTemplates';

const CreateActionTemplateForm = ({
  visible,
  setVisible,
  onSuccess,
  templateId,
  actionOptionTemplates,
  isDisabled = false,
  mode,
  onCancel,
}) => {
  const formikRef = useRef(null);
  const [rules, setRules] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [name, setName] = useState('');

  console.log(actionOptionTemplates);
  useEffect(() => {
    console.log(templateId);
    if (templateId !== undefined && mode === 'edit') {
      getTemplate(templateId).then((res) => {
        let template = res.data;
        console.log(template);
        setRules(template.rules);
        setTasks(template.tasks);
        setName(template.name);
      });
    } else {
      reset();
    }
  }, [templateId, mode]);

  const addRequiredValue = (value, index) => {
    let newArr = [...rules];
    newArr[index].requiredValue = value;
    setRules(newArr);
  };

  const addCheckedRule = (rule, index) => {
    let newArr = [...rules];
    newArr[index].checkedValue = rule;
    setRules(newArr);
  };

  const addTaskType = (type, index) => {
    let newArr = [...tasks];
    newArr[index].taskType = type;
    setTasks(newArr);
  };

  const addTaskValue = (value, index) => {
    let newArr = [...tasks];
    newArr[index].value = value;
    setTasks(newArr);
  };

  const changeCheckbox = (check, index) => {
    let newArr = [...tasks];
    newArr[index].createPatientTask = check;
    setTasks(newArr);
  };

  function reset() {
    setRules([]);
    setTasks([]);
    formikRef.current.resetForm();
  }

  return (
    <Modal
      title={
        <strong>{mode === 'add' ? 'Create' : 'Edit'} Action Template</strong>
      }
      visible={visible}
      destroyOnClose
      onCancel={() => {
        setVisible(false);
        reset();
        if (onCancel) {
          onCancel();
        }
      }}
      footer={null}
      // width={'100%'}
      width={'800px'}
      keyboard={false}
      maskClosable={false}
    >
      <Formik
        innerRef={formikRef}
        initialValues={{ name: '' }}
        onSubmit={() => {
          let request = {
            name,
            rules,
            tasks,
          };

          if (mode === 'edit') {
            request.id = templateId;
          }
          console.log(request);
          createTemplate(request).then((res) => {
            // console.log(res)
            onSuccess();
          });
          setVisible(false);
        }}
        render={() => (
          <Form layout='vertical'>
            <Form.Item name='name' label='Name'>
              <Input
                value={name}
                name='name'
                onChange={(el) => {
                  setName(el.target.value);
                }}
                disabled={isDisabled && mode !== 'add'}
                autoFocus
              />
            </Form.Item>

            <Divider orientation='left'>Rules</Divider>
            {!isDisabled && (
              <>
                <Button
                  onClick={() => {
                    setRules(
                      rules.concat({ checkedValue: '', requiredValue: '' })
                    );
                  }}
                  type='link'
                >
                  <BsPlus /> Add Rule
                </Button>
                <br />
              </>
            )}
            {rules.length > 0 ? (
              rules.map((val, index) => {
                console.log(val);
                return (
                  <RuleCreation
                    value={val}
                    addRule={addCheckedRule}
                    addValue={addRequiredValue}
                    index={index}
                    actionOptionTemplates={actionOptionTemplates}
                  />
                );
              })
            ) : (
              <Empty
                description={'No Rules'}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}

            <Divider orientation='left'>Task</Divider>
            {!isDisabled && (
              <>
                <Button
                  onClick={() => {
                    setTasks(
                      tasks.concat({
                        createPatientTask: false,
                        taskType: '',
                        value: '',
                      })
                    );
                  }}
                  type='link'
                  disabled={isDisabled}
                >
                  <BsPlus /> Add Task
                </Button>
                <br />
              </>
            )}
            {tasks.length > 0 ? (
              tasks.map((val, index) => {
                console.log(val);
                return (
                  <TaskCreation
                    value={val}
                    addType={addTaskType}
                    addValue={addTaskValue}
                    updateCheckBox={changeCheckbox}
                    index={index}
                    actionOptionTemplates={actionOptionTemplates}
                  />
                );
              })
            ) : (
              <Empty
                description={'No Tasks'}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}

            <footer style={{ textAlign: 'right' }}>
              {!isDisabled && (
                <Space>
                  <ResetButton>Reset</ResetButton>
                  <SubmitButton>
                    {mode === 'add' ? 'Create' : 'Update'}
                  </SubmitButton>
                </Space>
              )}
            </footer>
          </Form>
        )}
      />
    </Modal>
  );
};
export default CreateActionTemplateForm;
