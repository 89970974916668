import { Table, Tag, Spin } from 'antd';

const APITestingResult = ({ endpoints }) => {

    const getStatusCodeColorByCategory = statusCode => {
        const currentStatusCode = parseInt(statusCode);

        switch (true) {
            case currentStatusCode >= 100 && currentStatusCode <= 199:
                return '#2db7f5';
            case currentStatusCode >= 200 && currentStatusCode <= 299:
                return '#4caf50';
            case currentStatusCode >= 300 && currentStatusCode <= 399:
                return '#404040';
            case currentStatusCode >= 400 && currentStatusCode <= 499:
                return '#cd201f';
            case currentStatusCode >= 500 && currentStatusCode <= 599:
                return '#cd201f';
            default:
                return '';
        }
    };

    const columns = [
        {
            title: 'API',
            dataIndex: 'apiString',
            key: 'apiString',
            render: text => text,
        },
        {
            title: 'Description',
            dataIndex: 'endpoint',
            key: 'endpoint',
        },
        {
            title: 'Result Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => status === 0 ? <Spin spinning /> : <Tag color={getStatusCodeColorByCategory(status)}>{status}</Tag>,
        },
    ];

    return endpoints && Array.isArray(endpoints) && endpoints.length > 0 ? 
            <>
                <h4 className='font-weight-bolder'>API Test Result(s):</h4>
                <Table
                    columns={columns}
                    dataSource={endpoints}
                    pagination={{
                        pageSize: '10',
                        responsive: true,
                    }}
                />
            </> : null;
};

export default APITestingResult;
