import { SET_TENANT_BRANDING, SET_TENANT_BRANDING_LOGO } from 'redux/actions/types/tenant';

export const setTenantBranding = (branding) => {
    let returnAction = {
        type: SET_TENANT_BRANDING
    }

    if (branding) {
        returnAction = {
            type: SET_TENANT_BRANDING,
            branding
        }
    }
    return returnAction
}

export const setTenantBrandingLogo = logo => {
    return {
        type: SET_TENANT_BRANDING_LOGO,
        logo
    }
}