import React from 'react';
import {message, Modal, Row, Col, Button} from 'antd';
import {updateAppointmentStatus} from "../../../api/appointments";
import { Formik } from 'formik';
import { Form, SubmitButton, Select } from 'formik-antd';
import titleize from "titleize";
import * as yup from 'yup';

const {Option} = Select;

const initialValues = {
    statusValue: ''
};
  
const schema = yup.object().shape({
    statusValue: yup.string().required('Please choose a status.'),
});

function StatusModal(props) {
    const {
        setStatusModalVisibility,
        isStatusModalVisible,
        id,
        onSuccess
    } = props;

    const statusList = [
        {
            value: 'UNCONFIRMED',
            text: 'UNCONFIRMED',
        },
        {
            value: 'DECLINED',
            text: 'DECLINED',
        },
        {
            value: 'CONFIRMED',
            text: 'CONFIRMED',
        },
        {
            value: 'CHECKED_IN_WAITING',
            text: 'CHECKED IN WAITING',
        },
        {
            value: 'CALLED_BACK_TO_QUEUE',
            text: 'CALLED BACK TO QUEUE',
        },
        {
            value: 'CHECKED_IN',
            text: 'CHECKED IN',
        },
        {
            value: 'IN',
            text: 'IN',
        },
        {
            value: 'CHECKED_OUT',
            text: 'CHECKED OUT',
        },
        {
            value: 'NO_SHOW',
            text: 'NO SHOW',
        }
    ]

    const saveStatus = async (values) => {
        try {
            await updateAppointmentStatus({
                id: id,
                status: values.statusValue
            });
            message.success(`${titleize('Appointment status')} changed.`);
        } catch {
            message.error(`Something went wrong.`);
        } finally {
            onSuccess();
            setStatusModalVisibility(false);
        }
    }

    return (
        <Modal
            title='Change Appointment Status'
            visible={isStatusModalVisible}
            onCancel={() => setStatusModalVisibility(false)}
            destroyOnClose={true}
            footer={null}
            centered
            keyboard={false}
            maskClosable={false}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(values) => saveStatus(values)}
                render={() => (
                    <Form layout='vertical' colon={false}>
                        <Form.Item label='Status' name='statusValue' className='mb-4'>
                            <Select
                                showSearch
                                autoFocus
                                name='statusValue'
                                defaultValue=''
                                style={{ width: '100%' }}
                                placeholder="Select Status"
                                optionFilterProp="children"
                                size='large'
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {statusList && statusList.length > 0 && statusList.map((status, i) => (
                                    <Option key={status.value} value={status.value}>{status.text}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Row gutter={4} className='d-flex justify-content-end'>
                            <Col>
                                <Button onClick={() => setStatusModalVisibility(false)}>Close</Button>
                            </Col>
                            <Col>
                                <SubmitButton>Save Changes</SubmitButton>
                            </Col>
                        </Row>
                    </Form>
                )}
            />
        </Modal>
    );
}

export default StatusModal;