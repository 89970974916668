// components/user/Button.js
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button as AntButton, Input as AntInput, Select, message } from 'antd';

import { useNode, useEditor } from '@craftjs/core';

import moment from 'moment';

const { Option } = Select;

const nonValuedControls = ['Container', 'Card', 'Panel', 'Column', 'Button', 'Text'];

const Button = ({ 
    title = 'Button',
    type = 'primary'
}) => {
    const { 
        connectors: { connect, drag }, 
        selected,
    } = useNode((state) => ({
        selected: state.events.selected
    }));

    const {
        actions: { setProp },
        query,
        nodes,
    } = useEditor((state, query) => ({
        nodes: state.nodes
    }))

    return (
        <AntButton 
            ref={ref => connect(drag(ref))} 
            size='small' 
            type={type}
            onClick={() => {
                let data = [];
                let errors = [];
                
                Object.keys(nodes).map(serializeNodeKey => {
                    const nodeControl = query.getSerializedNodes()[serializeNodeKey];
                    
                    if (!nonValuedControls.includes(nodeControl.displayName)) {
                        if (nodeControl.props.isRequired && !nodeControl.props.value) {
                            errors.push(serializeNodeKey);

                            setProp(serializeNodeKey, props => {

                                if (props.error) {
                                    if (nodeControl.props.name === 'input' && nodeControl.props.type === 'datetime') props.value = moment();
                                    if (nodeControl.props.name === 'select') props.value = props.options && props.options.length > 0 && props.options[0].value;
                                    if (nodeControl.props.name === 'input' && nodeControl.props.type === 'text') props.value = '';
                                       
                                    props.error.required.isVisible = true;
                                }
                            });
                        } else {
                            if (nodeControl.props.value) {
                                setProp(serializeNodeKey, props => {
                                    if (props.error) props.error.required.isVisible = false;
                                });

                                delete errors[serializeNodeKey];
                                
                                data.push({
                                    id: serializeNodeKey,
                                    name: nodeControl.props.name, 
                                    value: nodeControl.props.value
                                });
                            }
                        }
                    }
                });
                
                if (errors && errors.length < 1) {
                    message.success('Form successfully submitted');
                } else {
                    message.error('Please fill in the required field(s).');
                }
            }}
        >
            {title}
        </AntButton>
    );
};

const ButtonSettings = () => {
    const {
        actions: { setProp },
        title,
        type,
    } = useNode((node) => ({
        title: node.data.props.title,
        type: node.data.props.type,
    }));

    return (
        <>
            <Row>
                <Col xs={12}>
                    <label className='Form-Builder-Label'>Button Text:</label>
                    <AntInput 
                        placeholder='Button Text'
                        defaultValue={title}
                        onChange={e => setProp(props => props.title = e.target.value)}
                    />
                </Col>
                <Col xs={12}>
                    <label className='Form-Builder-Label'>Button Type:</label><br />
                    <Select 
                        defaultValue={type} 
                        onChange={value => setProp(props => props.type = value)} 
                        style={{ width: '100%' }}>
                        <Option value='default'>Default</Option>
                        <Option value='primary'>Primary</Option>
                    </Select>
                </Col>
            </Row>
        </>
    );
};

Button.craft = {
    displayName: 'Button',
    props: {
        name: 'Button',
        title: 'Button',
        type: 'primary'
    },
    related: {
        settings: ButtonSettings
    }
};

export default Button;
