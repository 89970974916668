import React from "react";

import SideMenu from './SideMenu';
import SlickTopMenu from './SlickNav/SlickTopMenu';

import { useDispatch, useSelector } from 'react-redux';

const Navigation = (props) => {
    const uiState = useSelector(state => state.ui);

    return <div role="navigation">
        {uiState.isTopbarVisible && <SlickTopMenu fullName={props.fullName} />}
        <SideMenu fullName={props.fullName} />
    </div>
};

export default Navigation;
