import {
    SET_APPOINTMENT_FORMS_DATA
} from 'redux/actions/types/appointmentForms';

const INITIAL_STATE = {
    data: []
};

const appointmenFormsReducer = (state = INITIAL_STATE, action) => {
    const { data } = action;

    switch (action.type) {
        case SET_APPOINTMENT_FORMS_DATA:
            return {
                ...state,
                data
            }

        default:
            return state;
    }
}

export default appointmenFormsReducer;
