import axios from 'axios';
import React from "react";
import {AuthUtils} from 'utils/AuthUtil';
import {getAuthenticationToken} from 'utils/auth';
import {store} from "../index";
import {ERROR_ACTIONS} from "../redux/types/error-types";


export const createAxios = (config) => {
    const instance = axios.create(config);
    const authType = true;

    instance.interceptors.request.use(config => {
        // todo determine what kind of auth
        const token = authType
            ? getAuthenticationToken()
            : AuthUtils.getMSAuthToken();

        // maybe I should extract this entire function due to possibility of
        // future auths having much more different implementations than this
        if (token) {
            config.headers.common['Authorization'] = `Bearer ${token}`;
        }
        config.headers.common['Accept'] = '*/*';
        config.headers.common['Access-Control-Allow-Origin'] = "*";
        return config
    }, error => {
        return error;
    })

    const onSuccess = () => {
        return response => response;
    }

    function onError(error) {

        const errorResponseObject = error.response;
        const errorJSONObject = error.toJSON();
        let message = errorResponseObject?.data?.message ? errorResponseObject?.data?.message : errorJSONObject.message;
        let details = errorResponseObject?.data?.details ? errorResponseObject?.data?.details : errorJSONObject.details;

        if (errorResponseObject?.data?.data?.message){
            message = errorResponseObject?.data?.data?.message
        }
        if (errorResponseObject?.data?.data?.details){
            details = errorResponseObject?.data?.data?.details
        }
        store.dispatch({
            type: ERROR_ACTIONS.SET_ERROR_MESSAGE,
            payload: {message, details}
        })

        if (authType) {
            AuthUtils.handleMsAuthError(error)
        }

        return Promise.reject(error);
    }

    instance.interceptors.response.use(onSuccess(), onError)

    return instance;
}