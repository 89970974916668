import React, {useEffect, useState} from 'react';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import {Redirect, Switch} from 'react-router';
import KeycloakLandingPage from 'pages/Landing/KeycloakLandingPage';
import MainLayout from 'components/Layout/MainLayout';
import {AuthContext} from 'hooks';
import {BrowserRouter, Route} from 'react-router-dom';
import {keycloak} from 'api/keycloak';
import routes from 'routes/index';
import {AuthUtils} from 'utils/AuthUtil';
import LoginPage from 'pages/Login';
import { getUserDetails } from 'api/auth';
import { message } from 'antd';
import { useUserAuthentication } from 'hooks/useUserAuthentication';

const KeycloakAuthPage = () => {
    const landingUri = '/';
    const baseURI = '';
    const { authenticated } = useUserAuthentication();

    const [defaultRedirectUri, setDefaultRedirectUri] = useState(null);

    const [sessionState, setSessionState] = useState({
        sessionDetails: [
            {
                firstName: '',
                lastName: ''
            }
        ],
        otherState: 'not sure what this is'
    });

    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const defaultUri = routes(false)[0]?.path;

    useEffect(() => {
        if (authenticated) {
            fetchUserDetails();
        }
    }, [authenticated]);

    const fetchUserDetails = async () => {
        try {
            const response = await getUserDetails();
            if (response?.data) {
                setSessionState({
                    sessionDetails: [response.data],
                    otherState: null
                });
            }
        } catch (error) {
            message.error('Fetching user details failed', 3);
        }
    };

    // useEffect(() => {
    //     console.log('trying to initialize keycloak');
    //     AuthUtils.keycloak()
    //         .then((auth) => {
    //             console.log('in keycloak init ', auth);
    //             setIsAuthenticated(auth);
    //         }).catch((e) => {
    //             console.error('Authentication Failed');
    //             console.error(e);
    //         });
    // }, []);
    return <AuthContext.Provider value={isAuthenticated}>
        <BrowserRouter basename=''>
            <Switch>
                <Route path='/console/meeting/:meetingNumber/:meetingPassword/:appointmentId'
                       component={(props) => {

                           console.log('starting a meeting with', props.match.params.meetingNumber);
                           window.location.href = 'http://localhost:3001/meeting/'
                               + props.match.params.meetingNumber + '/'
                               + props.match.params.meetingPassword + '/'
                               + props.match.params.appointmentId;
                           return null;
                       }}/>

                <AuthenticatedRoute
                    exact path={landingUri}
                    onAuthenticated={
                        () => {
                            console.log(`on Authenticated route landing: redirect to ${defaultUri}`);
                            return defaultUri && <Redirect to={defaultUri}/>;
                        }
                    }
                    onUnauthenticated={
                        () => {
                            console.log('on Unauthenticated route Landing: render Landing');
                            return <KeycloakLandingPage keycloak={keycloak}/>;
                        }
                    }
                />
                <AuthenticatedRoute
                    exact path={'/login'}
                    onAuthenticated={
                        () => {
                            console.log(`on Authenticated route landing: redirect to ${defaultUri}`);
                            return defaultUri && <Redirect to={defaultUri}/>;
                        }
                    }
                    onUnauthenticated={
                        () => {
                            console.log('on Unauthenticated route Landing: render Landing');
                            return <LoginPage />;
                        }
                    }
                />
                <AuthenticatedRoute
                    onAuthenticated={
                        () => {
                            console.log('on Authenticated route main: render Main');
                            return <MainLayout fullName={sessionState.sessionDetails.length > 0 && sessionState.sessionDetails[0].firstName.concat(' ', sessionState.sessionDetails[0].lastName)}/>;
                        }
                    }
                    onUnauthenticated={
                        () => {
                            console.log('on Unauthenticated route main: redirect to Landing.')
                            return <Redirect to={landingUri}/>;
                        }
                    }
                />
            </Switch>
        </BrowserRouter>
    </AuthContext.Provider>
}

export default KeycloakAuthPage;