import React from 'react';
import TitleInput from './Input';
import { Row } from 'react-bootstrap';

export default ({ branding, isReadOnly = false }) => {
    return  <>
                <h3 className='font-weight-bolder Branding-Logo-Settings-Header'>Titles</h3>
                <Row>
                    {branding 
                        && branding.page
                        && Object.keys(branding.page).length > 0
                        && Object.keys(branding.page).map((brandingPage, index) => {
                            return  <TitleInput
                                        key={index}
                                        currentState={branding} 
                                        brandingPage={brandingPage}
                                        isReadOnly={isReadOnly}
                                    />
                        })}
                </Row>
            </>;
}