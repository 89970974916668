import {BASE_API_URL} from "./config";
import {createAxios} from "./axios";

const formsApi = createAxios({
    baseURL: BASE_API_URL + '/form-designs'
})
export const getForms = params => {
    return formsApi.get('', {
        params: params
    })
}

export const getFormById = id => {
    return formsApi.get(`/${id}`);
}

export const deleteForm = id => {
    return formsApi.delete(`/${id}`);
}

export const createForm = form => {
    return formsApi.post(``, form)
}

export const updateContent = form => {
    return formsApi.put(`/${form.designUniqueId}`, form)
}