import {BASE_API_URL} from "./config";
import {createAxios} from "./axios";

const formSelectionApi = createAxios({
    baseURL: BASE_API_URL + '/form-selection/config'
});

export const fetchAllFormSelection = () => {
    return formSelectionApi.get();
}

export const createOrUpdateConfig = (payload) => {
    return formSelectionApi.patch('', JSON.stringify(payload), {
        headers: {
            'Content-Type': "application/json"
        }
    });
}