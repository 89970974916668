import * as React from "react"

function AttachmentIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5 13c-1.93 0-3.5-1.57-3.5-3.5V4.25C14 3.009 15.01 2 16.25 2s2.25 1.009 2.25 2.25v5a.75.75 0 01-1.5 0v-5a.75.75 0 00-1.5 0V9.5c0 1.103.897 2 2 2s2-.897 2-2V2.75a.75.75 0 011.5 0V9.5c0 1.93-1.57 3.5-3.5 3.5z"
        fill="#2196f3"
      />
      <path
        d="M16.25 2C15.01 2 14 3.01 14 4.25V9.5a3.504 3.504 0 003.75 3.491v-1.507A2.001 2.001 0 0115.5 9.5V4.25a.75.75 0 011.5 0v5c0 .414.336.75.75.75V2.575A2.24 2.24 0 0016.25 2z"
        fill="#1d83d4"
      />
      <path
        d="M23 7.62V9.5c0 3.03-2.47 5.5-5.5 5.5S12 12.53 12 9.5V7h-1.59l-.17-.07-1.42-1.42C8.49 5.18 8.05 5 7.59 5H2.75C1.23 5 0 6.23 0 7.75v12.5C0 21.77 1.23 23 2.75 23h18.5c1.52 0 2.75-1.23 2.75-2.75V9.75c0-.85-.39-1.62-1-2.13z"
        fill="#607d8b"
      />
      <path
        d="M7.59 5H2.75C1.23 5 0 6.23 0 7.75v12.5C0 21.77 1.23 23 2.75 23H12V7h-1.59l-.17-.07-1.42-1.42C8.49 5.18 8.05 5 7.59 5z"
        fill="#546d79"
      />
    </svg>
  )
}

export default AttachmentIcon
