export function collateralsHasErrored(state = false, action) {
    switch (action.type) {
        case 'COLLATERALS_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function collateralsIsLoading(state = false, action) {
    switch (action.type) {
        case 'COLLATERALS_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function collateralDetailsHasErrored(state = false, action) {
    switch (action.type) {
        case 'COLLATERAL_DETAILS_HAS_ERRORED':
            return action.detailsHasErrored;

        default:
            return state;
    }
}

export function collateralDetailsIsLoading(state = false, action) {
    switch (action.type) {
        case 'COLLATERALS_DETAILS_IS_LOADING':
            return action.detailsIsLoading;

        default:
            return state;
    }
}

export function collaterals(state = [], action) {
    switch (action.type) {
        case 'COLLATERALS_FETCH_DATA_SUCCESS':
            return action.collaterals;
        case 'COLLATERALS_REMOVE_LOCATION_SUCCESS':
            const data = state.data;
            // this bit is fancy javascript to replace the data with a new array
            return {
                ...state,
                data: remove(data, data.findIndex(data => data.id === action.collaterals))
            }
        default:
            return state;
    }
}

export function collateralDetails(state = [], action) {
    switch (action.type) {
        case 'COLLATERALS_DETAILS_FETCH_DATA_SUCCESS':
            return action.collateralsDetails;
        case 'COLLATERALS_REMOVE_LOCATION_SUCCESS':
            const data = state.data;
            // this bit is fancy javascript to replace the data with a new array
            return {
                ...state,
                data: remove(data, data.findIndex(data => data.id === action.collateralsDetails))
            }
        default:
            return state;
    }
}

export function collateralListSuccess(state = [], action) {
    switch (action.type) {
        case 'SET_COLLATERAL_LIST':
            return {
                ...state,
                collateralList: action.collateralList
            }
        default:
            return state;
    }

}

const remove = (collaterals, index) => {
    return [...collaterals.slice(0, index),
        ...collaterals.slice(index + 1, collaterals.length)];
};