import { Divider } from 'antd';
import React from 'react';
import { isCurrentPermissionKeyPermitted } from 'utils';
import Colors from './Colors';
import Titles from './Titles';
import Typography from './Typography';
import { useSelector } from 'react-redux';

const BrandingSection = ({ branding }) => {
    const permissionState = useSelector(state => state.permissions);
    const isReadOnly = !isCurrentPermissionKeyPermitted('settings:branding_logo:edit', permissionState);

    return  <>
                <Titles branding={branding} isReadOnly={isReadOnly} />
                <Divider />
                <Typography branding={branding} isReadOnly={isReadOnly} />
                <Divider />
                <Colors branding={branding} isReadOnly={isReadOnly} />
            </>;
}

export default BrandingSection;
