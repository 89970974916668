
export enum ERROR_ACTIONS {
    SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE',
}

export interface ErrorAction {
    type: ERROR_ACTIONS;
    payload: ErrorState;
}

export interface ErrorState {
    message: string;
    details:string;
}