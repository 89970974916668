import React from 'react';
import {DatePicker, Input, Select} from "antd";

const FilterComponent = ({ value, component, onChange }) => {
    const val = value? value.value : null;
    switch(component.name) {
        case 'select': return <Select style={{ width: '100%' }} {...component} value={val} onChange={onChange}>
            {
                component && component.fetchData().map(row =>
                    <Select.Option key={row.value} value={row.value}>{row.displayText}</Select.Option>)
            }
        </Select>

        case 'daterange': return <DatePicker.RangePicker value={val} {...component} onChange={onChange} className="w-100" />

        default: return <Input {...component} value={val} onChange={ e => onChange(e.target.value) }/>
    }
}

export default FilterComponent;