export function appointmentsHasErrored(state = false, action) {
    switch (action.type) {
        case 'APPOINTMENTS_HAS_ERRORED':
            return action.hasErrored;
        case 'DETAILS_HAS_ERRORED':
            return action.detailsHasErrored;

        default:
            return state;
    }
}

export function appointmentsIsLoading(state = false, action) {
    switch (action.type) {
        case 'APPOINTMENTS_IS_LOADING':
            return action.isLoading;
        case 'DETAILS_IS_LOADING':
            return action.detailsIsLoading;

        default:
            return state;
    }
}

export function appointments(state = [], action) {
    switch (action.type) {
        case 'APPOINTMENTS_FETCH_DATA_SUCCESS':
            return action.appointments;
        case 'APPOINTMENTS_REMOVE_LOCATION_SUCCESS':
            const data = state.data;
            // this bit is fancy javascript to replace the data with a new array
            return {
                ...state,
                data: remove(data, data.findIndex(data => data.id === action.appointments))
            }
        default:
            return state;
    }
}

export function appointmentDetails(state = [], action) {
    switch (action.type) {
        case 'APPOINTMENT_DETAILS_FETCH_DATA_SUCCESS':
            return action.appointmentDetails;
        case 'APPOINTMENT_DETAILS_REMOVE_LOCATION_SUCCESS':
            const data = state.data;
            // this bit is fancy javascript to replace the data with a new array
            return {
                ...state,
                data: remove(data, data.findIndex(data => data.id === action.appointmentDetails))
            }
        case 'REQUEST_APPOINTMENT_BY_ID_SUCCESS':
            return {
                ...state,
                data: action.payload
            }
        default:
            return state;
    }
}
const INITIAL_STATE = {
    data: {},
    messages: []
}
export function appointmentReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'REQUEST_APPOINTMENT_BY_ID_SUCCESS':
            return {
                ...state,
                data: action.payload
            }
        case 'REQUEST_MESSAGES_BY_APPOINTMENT_SUCCESS':
            return {
                ...state,
                messages: action.payload
            }

        default:
            return state;
    }
}


const remove = (appointments,index) => {
    return [...appointments.slice(0,index),
        ...appointments.slice(index+1,appointments.length)];
};