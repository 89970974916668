import React, { useEffect, useState } from 'react';
import { setEditorUtilities, setContentLoaded } from 'redux/actions/formBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { setFormBuilderActiveTab } from 'redux/actions/ui';

import { useEditor } from '@craftjs/core';

const CraftEditorInitializer = ({ 
    formSubmissionContent,
    willFieldsDisable = false
}) => {
    const { actions, query, nodes, selected } = useEditor((state) => ({
        selected: state.events.selected,
        nodes: state.nodes,
    }));

    const [currentSelected, setCurrentSelected] = useState(null);
    const [areFieldsDisabled, setFieldsDisability] = useState(null);

    const uiState = useSelector((state) => state.ui);
    const formBuilderState = useSelector((state) => state.formBuilder);

    const dispatch = useDispatch();

    const nonValuedControls = [
        'Container',
        'Card',
        'Panel',
        'Column',
        'Button',
        'Text',
        'Grid',
    ];

    useEffect(() => {
        if (
            actions &&
            Object.keys(actions).length > 2 &&
            query &&
            Object.keys(query).length > 2 &&
            nodes &&
            Object.keys(nodes).length > 0
        ) {
            dispatch(
                setEditorUtilities({
                actions,
                query,
                nodes,
                })
            );
            
            if (willFieldsDisable && !areFieldsDisabled) {
                Object.keys(nodes).map((serializeNodeKey) => {
                    const nodeControl = query.getSerializedNodes()[serializeNodeKey];

                    if (
                        (!nonValuedControls.includes(nodeControl.displayName) ||
                        nodeControl.displayName === 'Grid') && 
                        nodeControl.isCanvas === false
                    ) {
                        actions.setProp(serializeNodeKey, (props) => {
                            if (!props.value) {
                                props.isDisabled = willFieldsDisable;
                            }
                        });
                    }
                });

                setFieldsDisability(true);
            }
        }
    }, [nodes]);

  useEffect(() => {
    const selectedNodeControl = query.getSerializedNodes()[currentSelected];

    if (uiState.formBuilderActiveTab !== '2') {
      if (selectedNodeControl?.props?.eventProperties?.selectedControls) {
        selectedNodeControl?.props.eventProperties.selectedControls.map(
          (selectedControl) => {
            if (nodes[selectedControl.id]){            
              actions.setProp(selectedControl.id, (props) => {
                props.isEventChild = false;
              });
            }
          }
        );
      }
    } else {
      if (
        selectedNodeControl?.props?.eventProperties?.selectedControls &&
        selectedNodeControl?.props?.eventProperties?.conditionalEventEnabled
      ) {
        selectedNodeControl?.props.eventProperties.selectedControls.map(
          (selectedControl) => {
            if (nodes[selectedControl.id]){
              actions.setProp(selectedControl.id, (props) => {
                props.isEventChild = true;
              });
            }
          }
        );
      }
    }
  }, [uiState.formBuilderActiveTab]);

  useEffect(() => {
    if (selected) {
      if (!uiState.isFormBuilderEventsSelecting) {
        Object.keys(nodes).map((serializeNodeKey) => {
          if (serializeNodeKey !== selected) {
            actions.setProp(serializeNodeKey, (props) => {
              if (props.isSelected) {
                props.isSelected = false;
              }
            });
          }
        });
      }

      if (
        currentSelected !== selected &&
        !uiState.isFormBuilderEventsSelecting
      ) {
        const previousNodeControl = query.getSerializedNodes()[currentSelected];

        if (previousNodeControl?.props?.eventProperties?.selectedControls) {
          previousNodeControl?.props.eventProperties.selectedControls.map(
            (selectedControl) => {
              if (nodes[selectedControl.id]) {
                actions.setProp(selectedControl.id, (props) => {
                  props.isEventChild = false;
                });
              }
            }
          );
        }
      }

      if (!uiState.isFormBuilderEventsSelecting) {
        actions.setProp(selected, (props) => {
          props.isSelected = true;
        });
      } else {
        actions.setProp(selected, (props) => {
          props.isEventChild = true;
        });
      }

      if (!uiState.isFormBuilderEventsSelecting) {
        setCurrentSelected(selected);
        dispatch(setFormBuilderActiveTab('1'));
      }
    }
  }, [selected, uiState.isFormBuilderEventsSelecting]);

  useEffect(() => {
    if (!formBuilderState.isEditorFocused) {
      actions.clearEvents();
      Object.keys(nodes).map((serializeNodeKey) => {
        actions.setProp(serializeNodeKey, (props) => {
          props.isSelected = false;
          props.isEventChild = false;
        });
      });
    } else {
      if (currentSelected) {
        const selectedNodeControl = query.getSerializedNodes()[currentSelected];

        if (selectedNodeControl) {
          actions.setProp(currentSelected, (props) => {
            props.isSelected = true;
          });

          if (uiState.formBuilderActiveTab === '2') {
            if (
              selectedNodeControl?.props?.eventProperties?.selectedControls &&
              selectedNodeControl?.props?.eventProperties
                ?.conditionalEventEnabled
            ) {
              selectedNodeControl?.props.eventProperties.selectedControls.map(
                (selectedControl) => {
                  if (nodes[selectedControl.id]) {
                    actions.setProp(selectedControl.id, (props) => {
                      props.isEventChild = true;
                    });
                  }
                }
              );
            }
          }
        }
      }
    }
  }, [formBuilderState.isEditorFocused, uiState.formBuilderActiveTab]);

    useEffect(() => {
        if (!formBuilderState.isContentLoaded && formSubmissionContent && formSubmissionContent.length > 0) {
            dispatch(setContentLoaded(true));
            
            Object.keys(nodes).map((serializeNodeKey) => {
                const nodeControl = query.getSerializedNodes()[serializeNodeKey];

                if (
                    (!nonValuedControls.includes(nodeControl.displayName) ||
                    nodeControl.displayName === 'Grid') && 
                    nodeControl.isCanvas === false
                ) {
                    const formControlItem = formSubmissionContent.find(
                        (jsonFormItem) => jsonFormItem.id === serializeNodeKey
                    );

                    if (formControlItem) {
                        actions.setProp(serializeNodeKey, (props) => {
                            if (!props.value) {
                                props.value = formControlItem.value;
                            }
                        });
                    }
                }
            });
        }
    }, [formBuilderState.isContentLoaded, formSubmissionContent]);

    return <></>;
};

export default CraftEditorInitializer;
