export function documentsHasErrored(state = false, action) {
    switch (action.type) {
        case 'DOCUMENTS_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function documentsIsLoading(state = false, action) {
    switch (action.type) {
        case 'DOCUMENTS_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function documents(state = [], action) {
    switch (action.type) {
        case 'DOCUMENTS_FETCH_DATA_SUCCESS':
            return action.documents;
        case 'DOCUMENTS_REMOVE_LOCATION_SUCCESS':
            const data = state.data;
            // this bit is fancy javascript to replace the data with a new array
            return {
                ...state,
                data: remove(data, data.findIndex(data => data.id === action.documents))
            }
        default:
            return state;
    }
}

const remove = (documents,index) => {
    return [...documents.slice(0,index),
        ...documents.slice(index+1,documents.length)];
};