import { BASE_AUTH_URL } from 'api/config';
import axios from 'axios';
import { removeAuth } from 'utils/helpers';
import { BASE_API_URL } from './config';
import { createAxios } from './axios';

const authApi = createAxios({
    baseURL: BASE_API_URL + '/auth',
});

export const authenticateUser = (payload) => {
  return axios.post(BASE_AUTH_URL + '/token', JSON.stringify(payload), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getUserDetails = () => {
    return authApi.get('/me');
};

export const refreshToken = (payload) => {
  const token = sessionStorage.getItem('token');
  return axios.post(BASE_AUTH_URL + '/refresh-token', JSON.stringify(payload), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const logout = () => {
  const refreshtoken = sessionStorage.getItem('refresh_token');
  const token = sessionStorage.getItem('token');
  const payload = {
    refreshToken: refreshtoken,
  };

  removeAuth();
  return axios.post(BASE_AUTH_URL + '/logout', JSON.stringify(payload), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};
