import { BASE_API_URL } from 'api/config';
import { createAxios } from './axios';

const heartratesApi = createAxios({
  baseURL: BASE_API_URL + '/heartrates',
});

export const getHeartrates = () => {
  return heartratesApi.get('/');
};
