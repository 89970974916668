import * as React from "react"

function DocumentIcon(props) {
  return (
    <svg
      height={props.height}
      viewBox="0 0 512 512"
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M451 135l-105-30L316 0H106C81.147 0 61 20.147 61 45v422c0 24.853 20.147 45 45 45h300c24.853 0 45-20.147 45-45z"
        fill="#734bbd"
      />
      <circle cx={136} cy={256} fill="#fff5f5" r={15} />
      <circle cx={136} cy={316} fill="#fff5f5" r={15} />
      <circle cx={136} cy={376} fill="#fff5f5" r={15} />
      <path
        d="M451 135v332c0 24.853-20.147 45-45 45H256V0h60l30 105z"
        fill="#5f45a9"
      />
      <path
        d="M346 271H196c-8.291 0-15-6.709-15-15s6.709-15 15-15h150c8.291 0 15 6.709 15 15s-6.709 15-15 15zM346 331H196c-8.291 0-15-6.709-15-15s6.709-15 15-15h150c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
        fill="#fff5f5"
      />
      <g>
        <path
          d="M346 391H196c-8.291 0-15-6.709-15-15s6.709-15 15-15h150c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
          fill="#fff5f5"
        />
      </g>
      <g fill="#e3e7ea">
        <path d="M256 391h90c8.291 0 15-6.709 15-15s-6.709-15-15-15h-90zM256 331h90c8.291 0 15-6.709 15-15s-6.709-15-15-15h-90zM256 271h90c8.291 0 15-6.709 15-15s-6.709-15-15-15h-90z" />
      </g>
      <path
        d="M451 135H346c-16.5 0-30-13.5-30-30V0c3.9 0 7.8 1.5 10.499 4.501l120 120C449.5 127.2 451 131.1 451 135z"
        fill="#9b7ed0"
      />
    </svg>
  )
}

export default DocumentIcon
