import React, {useRef, useEffect} from "react";
import {Typography, Modal, Row, Col, message} from "antd";
import {Formik} from "formik";
import {Form, Input, ResetButton, SubmitButton} from "formik-antd";
import * as yup from "yup";
import titleize from 'titleize';
import {createStaffTask} from 'api/staffTasks'


const {Title} = Typography;

const entity = `staff task`;
const initialValues = {
  description: ''
};

const schema = yup.object().shape({
  description: yup
    .string()
    .min(2, "Too short.")
    .max(255, "Too long.")
    .required("Please enter a description."),
});

const CreateStaffTaskModal = ({visible, setVisibility, icon, appointmentId, onSuccess, onCancel, fetchData}) => {
  const formikRef = useRef(null);
  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible])

  async function create(values) {
    try {
      let payload = {
        appointmentId: appointmentId,
        assigneeType: 'CLINICIAN',
        taskType: 'CUSTOM',
        description: values.description
      }
      message.loading(`Creating ${entity}...`, 0);
      const res = await createStaffTask(payload);
      message.destroy();
      message.success(`${titleize(entity)} successfully created.`);
      onSuccess();
      fetchData();
      setVisibility(false)
    } catch (error) {
      message.destroy();
      // message.error(error);
    }
  }

  function reset() {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  }

  return (
    <Modal
      title={<Title level={4} className="mb-0"
                    style={{display: "flex", alignItems: "center"}}>{icon}{`Add ${titleize(entity)}`}</Title>}
      visible={visible}
      onCancel={() => { setVisibility(false); onCancel(); }}
      footer={null}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={values => create(values)}
        render={() => (
          <Form layout="vertical" colon={false}>
            <Form.Item label="Description" name="description" className="mb-4">
              <Input
                name="description"
              />
            </Form.Item>
            <Row gutter={4} className="d-flex justify-content-end">
              <Col>
                <ResetButton>Reset</ResetButton>
              </Col>
              <Col>
                <SubmitButton>Add</SubmitButton>
              </Col>
            </Row>
          </Form>
        )}
      />
    </Modal>
  );
};

export default CreateStaffTaskModal;
