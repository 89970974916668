import React from 'react';

export function evaluate(
  eventProperties,
  componentVal,
  availableActions,
  query
) {
  if (!eventProperties.conditionalEventEnabled) return;

  const conditionResult = evaluateConditions(eventProperties, componentVal);
  const doAction = evaluateAction(
    eventProperties.action,
    conditionResult,
    availableActions
  );

  eventProperties.selectedControls.map((control) => {
    doAction(control.id, query, conditionResult);
  });
}

function evaluateConditions({ conditions }, componentVal) {
  if (!conditions || conditions === undefined)
    throw Error('No conditions provided');

  let result = false;
  let conResult = false;
  conditions.map((con) => {
    const { condition, conditionalValue, logicalOperator } = con;
    let conditionalVal = conditionalValue;

    if (!componentVal) {
      componentVal = '';
    }

    if (!conditionalVal) {
      conditionalVal = '';
    }

    // console.log('mekeni tameni: \''+ componentVal.toString() + '\', \'' + conditionalVal.toString() + '\'');

    switch (condition.value) {
      case 'equal_to':
        if (typeof componentVal === 'object') {
          conResult = componentVal.includes(conditionalVal.toString());
          break;
        } else {
          conResult = conditionalVal.toString() === componentVal?.toString();
          break;
        }

      case 'not_equal_to':
        conResult = conditionalVal.toString() !== componentVal?.toString();
        break;
      case 'includes':
        conResult = componentVal
          .toString()
          .includes(conditionalVal?.toString());
        break;
      case 'not_includes':
        conResult = !componentVal
          .toString()
          .includes(conditionalVal?.toString());
        break;
      default:
        throw Error(`Unknown event condition ${condition.value}`);
    }

    if (logicalOperator)
      switch (logicalOperator.value) {
        case 'or':
          result |= conResult;
          break;
        case 'and':
          result &= conResult;
          break;
        default:
          throw Error(`Unknown event condition ${logicalOperator.value}`);
      }
    else result = conResult;
  });

  return result;
}

function evaluateAction(action, other, availableActions) {
  const { setHidden } = availableActions;

  switch (action.value) {
    case 'hide':
      return (controlId, query, conditionRes) =>
        _action(controlId, query, () => setHidden(controlId, other));
    case 'show':
      return (controlId, query, conditionRes) =>
        _action(controlId, query, () => setHidden(controlId, !other));
      break;
    default:
      throw Error(`Unknown event action ${action.value}`);
  }
}

function _action(controlId, query, action) {
  if (query.node(controlId).get()) {
    action();
  }
}
