import React, {useState, useEffect} from 'react';
import CreateMessageModal from './CreateMessageModal';
import {MessageOutlined} from '@ant-design/icons';
import {Reply} from '@material-ui/icons';
import {getMessagesDataTable, deleteMessage} from 'api/messages';
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import ViewMessageModal from './ViewMessageModal';
import {Anchor, Tag, message} from 'antd';
import { isCurrentPermissionKeyPermitted } from 'utils';
import { useSelector } from 'react-redux';

const {Link} = Anchor;

const Messages = ({appointmentId}) => {
  const [isCreateModalVisible, setCreateModalVisibility] = useState(false);
  const [isReplyModalVisible, setReplyModalVisibility] = useState(false);
  const [viewMessageId, setViewMessageId] = useState(undefined);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [selectedMessageSubject, setSelectedMessageSubject] = useState('');
  const [draw, setDraw] = useState(0);

  const permissionState = useSelector(state => state.permissions);

  const columns = [
    {
      title: <strong>Message</strong>,
      dataIndex: 'message',
      key: 'message',
      sorter: true,
      render: (text, record, value) => {
        return (
          <>
            <span className="text-primary cursor-pointer" 
              onClick={() => { 
                setViewMessageId(record.id); 
                setSelectedMessageSubject(record.subject); 
              }}>
              {record && record.replyingToMessage? 'Re: ' : null}
              {record.subject}
            </span>
            <div className="message-content">
              <span className="mr-3">{record.message}</span>
            </div>
            { record.sender === 'STAFF' ?
              <Tag color='green'>Outgoing</Tag>
              :
              <Tag color='blue'>Incoming</Tag>
            }
          </>
        )
      }
    }
  ];

  useEffect(loadAppointmentMessage => {
    setDraw(draw + 1);
  }, [appointmentId]);

  const fetchMessages = async (params) => {
    params = {
      ...params,
      sortColumn: 'createdAt',
      sortDirection: 'DESC',
    };
    return await getMessagesDataTable(appointmentId, params);
  };

  const refreshMessage = () => {
    setDraw(draw + 1);
    setSelectedMessageId(null);
    setViewMessageId(null);
    setSelectedMessageSubject('');
  };

  const deleteAppointmentMessage = async (id) => {
    message.loading('Deleting message...', 0);
    try {
      const res = await deleteMessage(id);
      message.destroy();
      message.success('Message successfully deleted.');
      refreshMessage();
    } catch(err) {
      message.destroy();
      message.error('Error deleting message.');
    }
  }

  return (
    <>
      <ViewMessageModal
        messageId={viewMessageId}
        onReply={() => {
          setReplyModalVisibility(true); 
          setSelectedMessageId(viewMessageId);
        }}
        onDelete={() => deleteAppointmentMessage(viewMessageId) }
        setVisibility={setViewMessageId}
        icon={<MessageOutlined className='mr-2'/>}
        visible={viewMessageId}
        setDraw={setDraw}
        draw={draw}
      />
      <CreateMessageModal
        subject={selectedMessageSubject}
        visible={isReplyModalVisible}
        setVisibility={setReplyModalVisibility}
        icon={<MessageOutlined className='mr-2'/>}
        appointmentId={appointmentId}
        messageId={selectedMessageId}
        onSuccess={refreshMessage}
        onCancel={refreshMessage}
      />
      <CRUDDataTable
        draw={draw}
        fetchDataFunction={fetchMessages}
        title="Messages"
        entity='message'
        highlightRow={(row, i) => row.highlight}
        createModal={
            <CreateMessageModal
                visible={isCreateModalVisible}
                setVisibility={setCreateModalVisibility}
                icon={<MessageOutlined className='mr-2'/>}
                appointmentId={appointmentId}
                onSuccess={refreshMessage}
                onCancel={refreshMessage}
            />
        }
        isButtonShown={isCurrentPermissionKeyPermitted('appointments:messages:new_message', permissionState)}
        columns={columns}
      />
    </>
  );
};

export default Messages;
