export const FETCH_APPOINTMENTS_LIST_REQUEST = 'FETCH_APPOINTMENTS_LIST_REQUEST';
export const FETCH_APPOINTMENTS_LIST_SUCCESS = 'FETCH_APPOINTMENTS_LIST_SUCCESS';
export const FETCH_APPOINTMENTS_LIST_ERROR = 'FETCH_APPOINTMENTS_LIST_ERROR';

export const FETCH_APPOINTMENT_REQUEST = "FETCH_APPOINTMENT_REQUEST";
export const FETCH_APPOINTMENT_SUCCESS = "FETCH_APPOINTMENT_SUCCESS";
export const FETCH_APPOINTMENT_ERROR = "FETCH_APPOINTMENT_ERROR";

export const CONFIRM_APPOINTMENT_REQUEST = 'CONFIRM_APPOINTMENT_REQUEST';
export const CONFIRM_APPOINTMENT_SUCCESS = 'CONFIRM_APPOINTMENT_SUCCESS';
export const CONFIRM_APPOINTMENT_ERROR = 'CONFIRM_APPOINTMENT_ERROR';

export const SET_WILL_TASK_LIST_REFRESH = 'SET_WILL_TASK_LIST_REFRESH';