import { FETCH_APPOINTMENT_ERROR, FETCH_APPOINTMENT_REQUEST, FETCH_APPOINTMENT_SUCCESS } from "redux/action-types/appointment-action-types";
import { SET_WILL_TASK_LIST_REFRESH } from "redux/actions/types/appointment";


const INITIAL_STATE = {
    data: undefined,
    error: null,
    isLoading: true,
    willTaskListRefresh: false,
};

const appointmentReducer = (state = INITIAL_STATE, action) => {
    const {type, payload, error } = action;
    switch(type) {
        case SET_WILL_TASK_LIST_REFRESH: {
            return {
                ...state,
                willTaskListRefresh: payload
            }
        }
       
        case FETCH_APPOINTMENT_REQUEST: {
            return {
                ...state,
                isLoading: payload
            }
        }

        case FETCH_APPOINTMENT_SUCCESS: {
            return {
                ...state,
                data: payload,
                isLoading: false
            }
        }

        case FETCH_APPOINTMENT_ERROR: {
            return {
                ...state,
                error
            }
        }

        default:
            return state;
    }
};

export default appointmentReducer;
