import React, {useEffect, useRef, useState} from "react";
import {Button, Col, message, Modal, Row, Space, Typography} from "antd";
import {Formik} from "formik";
import {Checkbox, Form, Input, ResetButton, SubmitButton} from "formik-antd";
import { updateStaffTaskStatus } from 'api/staffTasks';


const RejectStaffTaskModal =
  ({visible, setVisibility, onSuccess, id}) => {

    const create = async values => {
      try {
        let payload = {
          rejectionReason: values.reason,
          status: 'REJECTED'
        }
        message.loading(`Updating staff task...`, 0);
        const res = await updateStaffTaskStatus(id, payload);
        message.destroy();
        message.success(`Staff task successfully rejected.`);
        onSuccess();
        setVisibility(false)
      } catch (error) {
        message.destroy();
        message.error(`Reject staff task failed.`);
      }
    }
    const formikRef = useRef(null);

    return (<>
      <Modal
        title='Please enter reason for rejecting this task'
        visible={visible}
        onCancel={() => {
          setVisibility(false)
        }}
        footer={null}
        destroyOnClose={true}
        keyboard={false}
        maskClosable={false}
      >
        <Formik
          innerRef={formikRef}
          onSubmit={values => create(values)}
          initialValues={{}}
          render={()=>(
            <Form layout='vertical' colon={false}>
              <Form.Item label='Reason' name='reason' className='mb-4'>
                <Input.TextArea name='reason' autoFocus/>
              </Form.Item>
              <Row gutter={4} className="d-flex justify-content-end">
                <Col>
                  <Button type="dashed" shape="round" onClick={() => setVisibility(false)}>Cancel</Button>
                </Col>
                <Col>
                  <SubmitButton>Reject</SubmitButton>
                </Col>
              </Row>
            </Form>
          )}
        />

      </Modal>

    </>)

  }

export default RejectStaffTaskModal;