import {alertService} from "../../services/AlertService";
import SystemPropertyService from '../../services/SystemPropertyService'
import { SET_STAGING } from 'redux/actions/types/systemProperties';

export function systemPropertiesHasErrored(bool) {
    return {
        type: 'SYSTEM_PROPERTIES_HAS_ERRORED',
        hasErrored: bool
    };
}

export function systemPropertiesIsLoading(bool) {
    return {
        type: 'SYSTEM_PROPERTIES_IS_LOADING',
        isLoading: bool
    };
}

export function systemPropertiesFetchDataSuccess(systemProperties) {
    return {
        type: 'SYSTEM_PROPERTIES_FETCH_DATA_SUCCESS',
        systemProperties
    };
}

export function systemPropertiesDeleteDataSuccess(systemProperties) {
    return {
        type: 'SYSTEM_PROPERTIES_REMOVE_SYSTEM_PROPERTY_SUCCESS',
        systemProperties
    };
}

export function systemPropertiesRemoveSystemProperty(id) {
    return (dispatch) => {
        SystemPropertyService.deleteItem(id)
        .then(function(res) {
            alertService.success('Success!!', {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(systemPropertiesDeleteDataSuccess(id))
            return res;
        }).then(function(res) {
            // TODO may need to reload the stuff here as the delete does not take effect straight away - not sure what to do about that yet
            dispatch(systemPropertiesFetchData(0, 0, 5, "", []));
        }).catch(function(res) {
            console.log('OH NO THERE IS AN ERROR', res);
            alertService.error(res, {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(systemPropertiesHasErrored(true))
        });
    };
}

export function systemPropertiesAddSystemProperty(systemProperty) {
    return (dispatch) => {
        SystemPropertyService.addItem(systemProperty)
        .then(function(res) {
            dispatch(systemPropertiesFetchData(0, 0, 5, "", []));
        }).then(function(res) {
            alertService.success('Success!!', {
                autoClose: true,
                keepAfterRouteChange: false
            });
        }).catch(function(res) {
            console.log('OH NO THERE IS AN ERROR', res)
            alertService.error(res, {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(systemPropertiesHasErrored(true))
        });
    };
}

export function systemPropertiesFetchData(page, start, length, search, sortOrder, sortDirection) {
    return (dispatch) => {
        dispatch(systemPropertiesIsLoading(true));
            SystemPropertyService.getSystemProperties(page, start, length, search, sortOrder, sortDirection)
               .then(function(res) {
                dispatch(systemPropertiesIsLoading(false))
                return res;
            }).then(function(res) {
                dispatch(systemPropertiesFetchDataSuccess(res.data))
            }).catch(function(res) {
                console.log('OH NO THERE IS AN ERROR', res)
                alertService.error(res, {
                    autoClose: true,
                    keepAfterRouteChange: false
                });
                dispatch(systemPropertiesHasErrored(true))
            });
        };
}

export const setStaging = (data) => {
    return {
        type: SET_STAGING,
        data
    }
}
