import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {Container, Row, Col} from "react-bootstrap";
import {Button} from "antd";
import { useSelector } from 'react-redux';

import 'styles/KeycloakLanding.scss';
import logo from 'assets/images/5f-dark-logo.png';
import { getBrandingLogoByTenant } from 'api/branding';

const KeycloakLandingPage = ({ keycloak }) => {
    let history = useHistory();

    const login = () => {
        history.push('/login');
    };
    const tenant = useSelector(state => state.tenant);
    const [currentLogo, setCurrentLogo] = useState(getBrandingLogoByTenant(tenant.uniqueId));

    return (
        <Container className='Landing-Container'>
            <div className='Main-Header'>
                <Row>
                    <Col>
                        <img className='Logo' src={currentLogo} onError={() => setCurrentLogo(logo)} />
                    </Col>
                </Row>
            </div>
            <div className='Content'>
                <div className='Content-Header'>
                    <Row>
                        <Col>
                            <h2 style={{ fontSize: '3rem' }}>{tenant.branding.page.appTitle || 'Digital Patient Journey'}</h2>
                        </Col>
                    </Row>
                </div>
                <div className='Main-Content'>
                    <Row>
                        <Col>
                            <Button type="primary" style={{ fontSize: '2rem', height: 'auto' }} onClick={login}>Sign In with Five Faces SSO</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span style={{ fontSize: '2rem' }}>or</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Link style={{ fontSize: '2rem' }} to={'/signup'}>Sign up here</Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    );
};

export default KeycloakLandingPage;