import React, { useRef, useEffect, useState } from 'react';
import { Typography, Modal, Row, Col, message } from 'antd';
import { Formik } from 'formik';
import { Form, Input, ResetButton, SubmitButton, Select } from 'formik-antd';
import * as yup from 'yup';
import titleize from 'titleize';
import { getCollateralsDatatable } from 'api/collaterals';
import { addCollateralToAppointment } from '../../../../api/appointmentCollaterals';

const { Title } = Typography;
const { Option } = Select;

const entity = `resource`;
const initialValues = {
  collateralId: '',
  note: '',
};

const schema = yup.object().shape({
  collateralId: yup.string().required('Please choose a resource.'),
});

const CreateCollateralModal = ({
  visible,
  setVisibility,
  icon,
  appointmentId,
  onSuccess,
}) => {
  const [collaterals, setCollaterals] = useState([]);
  const [noteValue, setNoteValue] = useState('');
  const formikRef = useRef(null);

  async function readyCollaterals() {
    try {
      let params = {
        page: 0,
        start: 0,
        length: 100,
        sortOrder: 'name',
        sortDirection: 'ASC',
      };
      const res = await getCollateralsDatatable(params);
      setCollaterals(res.data.data);
    } catch (error) {
      console.log('here', error.toString());
      message.error(error.toString());
    }
  }

  useEffect(() => {
    readyCollaterals();
  }, []);

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible]);

  async function create(values) {
    try {
      let payload = {
        appointmentId: appointmentId, // TODO input appId
        collateralId: values.collateralId, // TODO input collaId
        note: noteValue,
      };
      message.loading(`Creating ${entity}...`, 0);
      const res = await addCollateralToAppointment(payload);
      message.destroy();
      onSuccess();
      message.success(`${titleize(entity)} successfully linked.`);
      setVisibility(false);
    } catch (error) {
      message.destroy();
      message.error(error);
    }
  }

  function reset() {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setNoteValue('');
  }

  return (
    <Modal
      title={
        <Title
          level={4}
          className='mb-0'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {icon}
          {`New ${titleize(entity)}`}
        </Title>
      }
      visible={visible}
      onCancel={() => setVisibility(false)}
      footer={null}
      destroyOnClose={true}
      keyboard={false}
      maskClosable={false}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values) => create(values)}
        render={() => (
          <Form layout='vertical' colon={false}>
            <Form.Item label='Resource' name='collateralId' className='mb-4'>
              <Select
                name='collateralId'
                defaultValue=''
                style={{ width: '100%' }}
                autoFocus
              >
                {collaterals.map((collateral) => (
                  <Option value={collateral.id}>{collateral.name}</Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item label="Note" name="note" className="mb-4">
              <Input.TextArea
                name="note"
                autoSize={{minRows: 3, maxRows: 10}}
              />
            </Form.Item> */}
            <textarea
              // autoSize={{
              //   minRows: 5,
              //   maxRows: 7,
              // }}
              value={noteValue}
              style={{ width: '100%' }}
              onChange={(e) => setNoteValue(e.target.value)}
            />
            <Row gutter={4} className='d-flex justify-content-end'>
              <Col>
                <ResetButton>Reset</ResetButton>
              </Col>
              <Col>
                <SubmitButton>Create</SubmitButton>
              </Col>
            </Row>
          </Form>
        )}
      />
    </Modal>
  );
};

export default CreateCollateralModal;
