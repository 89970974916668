import {alertService} from "../../services/AlertService";
import NotificationService from "../../services/MessageService";
import NoteService from "../../services/NoteService";
import {
    addAppointment,
    getAppointmentById,
    getAppointmentsDatatable,
    updateAppointmentStatus
} from 'api/appointments';
import {addCollateralToAppointment} from 'api/appointmentCollaterals'
import { setBreadcrumbsText } from 'redux/actions/ui';

export function appointmentsHasErrored(bool) {
    return {
        type: 'APPOINTMENTS_HAS_ERRORED',
        hasErrored: bool
    };
}

export function detailsHasErrored(bool) {
    return {
        type: 'DETAILS_HAS_ERRORED',
        detailsHasErrored: bool
    };
}

export function appointmentsIsLoading(bool) {
    return {
        type: 'APPOINTMENTS_IS_LOADING',
        isLoading: bool
    };
}

export function detailsIsLoading(bool) {
    return {
        type: 'DETAILS_IS_LOADING',
        detailsIsLoading: bool
    };
}

export function appointmentsFetchDataSuccess(appointments) {
    return {
        type: 'APPOINTMENTS_FETCH_DATA_SUCCESS',
        appointments
    };
}

export function appointmentDetailsFetchDataSuccess(payload) {
    return {
        type: 'FETCH_APPOINTMENT_SUCCESS',
        payload
    };
}

export function appointmentDetailsFetchDataRequest(payload) {
    return {
        type: 'FETCH_APPOINTMENT_REQUEST',
        payload
    }
}

export function appointmentsAddAppointment(location) {
    return (dispatch) => {
        addAppointment(location)
            .then(function (res) {
                dispatch(appointmentsFetchData(0, 0, 5, "", []));
            }).then(function (res) {
            alertService.success('Success!!', {
                autoClose: true,
                keepAfterRouteChange: false
            });
        }).catch(function (res) {
            console.log('OH NO THERE IS AN ERROR', res)
            alertService.error(res, {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(appointmentsHasErrored(true))
        });
    };
}

export function appointmentsFetchData(page, start, length, search, sortOrder, sortDirection, filters) {
    return (dispatch) => {
        dispatch(appointmentsIsLoading(true));
        let params = {
            page: page,
            start: start,
            length: length,
            search: search,
            sortOrder: sortOrder,
            sortDirection: sortDirection,
            filters: filters
        }
        getAppointmentsDatatable(params)
            .then(function (res) {
                dispatch(appointmentsIsLoading(false))
                return res;
            }).then(function (res) {
            dispatch(appointmentsFetchDataSuccess(res.data))
        }).catch(function (res) {
            console.log('OH NO THERE IS AN ERROR', res)
            alertService.error(res, {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(appointmentsHasErrored(true))
        });
    };
}

export function fetchAppointment(id) {
    return (dispatch) => {
        dispatch(detailsIsLoading(true));
        getAppointmentById(id)
            .then(function (res) {
                dispatch(detailsIsLoading(false))
                return res;
            }).then(function (res) {
            dispatch(setBreadcrumbsText(res.data.appointmentType))
            dispatch(appointmentDetailsFetchDataSuccess(res.data))
        }).catch(function (res) {
            console.log('OH NO THERE IS AN ERROR', res)
            alertService.error(res, {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(detailsHasErrored(true))
        });
    };
}

export function appointmentsAddMessage(message) {
    NotificationService.addItem(message)
        .then(function (res) {
            alertService.success('Success!!', {
                autoClose: true,
                keepAfterRouteChange: false
            });
        }).catch(function (res) {
        console.log('OH NO THERE IS AN ERROR', res)
        alertService.error(res, {
            autoClose: true,
            keepAfterRouteChange: false
        });
    });
}

export function appointmentsChangeStatus(update) {

    return (dispatch) => {
        updateAppointmentStatus(update)
            .then(function (res) {
                dispatch(appointmentsFetchData(0, 0, 5, "", []));
            }).then(function (res) {
            alertService.success('Success!!', {
                autoClose: true,
                keepAfterRouteChange: false
            });
        }).catch(function (res) {
            console.log('OH NO THERE IS AN ERROR', res)
            alertService.error(res, {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(appointmentsHasErrored(true))
        });
    };
}

export function appointmentsAttachNote(note) {

    NoteService.addItem(note)
        .then(function (res) {
            alertService.success('Success!!', {
                autoClose: true,
                keepAfterRouteChange: false
            });
        }).catch(function (res) {
        console.log('OH NO THERE IS AN ERROR', res)
        alertService.error(res, {
            autoClose: true,
            keepAfterRouteChange: false
        });
    });
}

export function appointmentsSendCollateral(collateral) {
    return (dispatch) => {
        addCollateralToAppointment(collateral)
            .then(function (res) {
                let appointmentId = res.data.appointment.id;
                dispatch(fetchAppointment(appointmentId))
            }).catch(function (res) {
            console.log('OH NO THERE IS AN ERROR', res)
            alertService.error(res, {
                autoClose: true,
                keepAfterRouteChange: false
            });
        });
    }
}