import { PermissionsAction, PermissionsState, PERMISSIONS_ACTIONS } from './../types/permissions-types/index';

const initialState: PermissionsState = {
    permissions: {},
};

export default function permissionsReducer(state = initialState, action: PermissionsAction): PermissionsState {
    const { type, payload } = action;
    switch (type) {
        case PERMISSIONS_ACTIONS.SET_PERMISSIONS: {
            return {
                ...state,
                permissions: payload.permissions,
            };
        }
        default:
            return { ...state };
    }
}
