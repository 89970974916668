import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    Row,
    Col
} from 'react-bootstrap';
import {
  Formik
} from 'formik';
import * as Yup from 'yup';
import {
    Input as FormikInput,
    Form as FormikForm,
    SubmitButton,
    ResetButton
} from 'formik-antd';
import { useDispatch, useSelector } from 'react-redux';
import { FiArrowLeftCircle } from 'react-icons/all';
import { setActiveMessageTemplate, setMessageTemplateMode } from 'redux/actions/messageTemplates';
import { MessageTemplateMode } from 'utils/enums';
import { saveMessageTemplate, updateTemplateMessage } from 'api/messageTemplates';
import styled from 'styled-components';
import { message } from 'antd';

const BackButton = styled(FiArrowLeftCircle)`
    font-size: 4rem;
    color: rgb(169 169 169);
    margin-top: -5px;
    margin-right: 10px;

    &:hover {
        cursor: pointer;
        transition: .2s;
        color: rgb(210 210 210);
    }
`;

const CreateMessageTemplate = ({
    draw,
    setDraw
}) => {
    const dispatch = useDispatch();
    const messageTemplateState = useSelector(state => state.messageTemplates);
    const [editorState, setEditorState] = useState(null);
    const editorMaxLength = 10000;
    const limitReached = editorState?.getCurrentContent()?.getPlainText().length >= editorMaxLength;

    useEffect(() => {
        if (messageTemplateState.mode === MessageTemplateMode.EDIT && Object.keys(messageTemplateState.data).length > 0) {
            const { templateName, subject, message } = messageTemplateState.data;

            const contentBlock = htmlToDraft(message);
            
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const convertedState = EditorState.createWithContent(contentState);
                
                setEditorState(convertedState);
            }
        }
    }, [messageTemplateState.data]);

    const messageTemplateValidation = Yup.object().shape({
        templateName: Yup.string()
            .required('Please provide message template name'),
        subject: Yup.string()
            .required('Please provide message subject'),
            message: Yup.string()
            .required('Please provide message template content'),
    });

    const submitMessageTemplate = async (payload, { resetForm }) => {
        if (limitReached) {
            message.error('Character limit reached, Please try not to reach character limit to submit.', 3);
        } else {
            let messageText = '';

            try {
                if (messageTemplateState.mode === MessageTemplateMode.CREATE) {
                    await saveMessageTemplate(payload);
                    messageText = 'Message template successfully saved';
                } else {
                    await updateTemplateMessage(messageTemplateState.data.id, payload);
                    messageText = 'Message template successfully updated';
                }
                dispatch(setActiveMessageTemplate({}));
                dispatch(setMessageTemplateMode(MessageTemplateMode.LIST));
                resetForm({});
                setDraw(draw + 1);
                message.success(messageText, 2);
            } catch (err) {
                dispatch(setActiveMessageTemplate({}));
                dispatch(setMessageTemplateMode(MessageTemplateMode.LIST));
                resetForm({});
                message.error('Message template saving failed', 2);
            }
        }
    };

    return  <>
                <h3 className='text-primary font-weight-bolder'>
                    <BackButton 
                        onClick={() => {
                            dispatch(setActiveMessageTemplate({}));
                            dispatch(setMessageTemplateMode(MessageTemplateMode.LIST));
                        }}
                    />
                    {messageTemplateState.mode === MessageTemplateMode.CREATE ? 'New' : 'Update'} Template
                </h3>
                <Formik
                    enableReinitialize
                    initialValues={{
                        templateName: messageTemplateState.data.templateName ? messageTemplateState.data.templateName : '',
                        subject: messageTemplateState.data.subject ? messageTemplateState.data.subject : '',
                        message: messageTemplateState.data.message ? messageTemplateState.data.message : ''
                    }}
                    validationSchema={messageTemplateValidation}
                    onSubmit={submitMessageTemplate}
                >
                        {({
                            setFieldValue
                        }) => (
                            <FormikForm layout="vertical">
                                <FormikForm.Item label='Name' name='templateName'>
                                    <FormikInput name='templateName' placeholder='e.g. Appointment Template' />
                                </FormikForm.Item>
                                <FormikForm.Item label='Subject' name='subject'>
                                    <FormikInput name='subject' placeholder='e.g. Hello' />
                                </FormikForm.Item>
                                <FormikForm.Item label='Message Template' name='message'>
                                    <Editor
                                        editorState={editorState}
                                        editorStyle={{
                                            border: '1px solid #F1F1F1',
                                            minHeight: '30vh',
                                            height: '100%',
                                            padding: '0 10px'
                                        }}
                                        toolbar={{
                                            fontFamily: {
                                                inDropdown: false
                                            }
                                        }}
                                        onEditorStateChange={(editorState) => {
                                            setEditorState(editorState);
                                            setFieldValue('message', draftToHtml(convertToRaw(editorState.getCurrentContent())), true);
                                        }}
                                        placeholder={'Enclose text with {{}} to make it as variable.'}
                                        handleBeforeInput={() => {
                                            if (editorState.getCurrentContent().getPlainText().length >= editorMaxLength) {
                                                return 'handled';
                                            }
                                        }}
                                    />
                                </FormikForm.Item>
                                <div className='text-right'>
                                    {editorState?.getCurrentContent() ? <><small>{editorState?.getCurrentContent()?.getPlainText().length} / {editorMaxLength}</small><br/></> : <><small>0 / {editorMaxLength}</small><br/></>}
                                </div>
                                {limitReached && <span className='text-danger'>Character limit reached.</span>}
                                <Row gutter={4} className='d-flex justify-content-end'>
                                    <Col className='text-right mt-3'>
                                        <ResetButton>Reset</ResetButton>&nbsp;&nbsp;
                                        <SubmitButton>Save</SubmitButton>
                                    </Col>
                                </Row>
                            </FormikForm>
                        )}
                </Formik>
            </>
};

export default CreateMessageTemplate;
