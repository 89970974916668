export enum MODULES {
    Dashboard = "Dashboard",
    Appointments = "Appointments",
    Tasks = "Tasks",
    Resources = "Resources",
    Locations = "Locations",
    Visitors = "Visitors",
    Check_In_Out = "Check_In_Out",
    Users = "Users",
    Forms = "Forms",
    Broadcasts = "Broadcasts",
    Settings = "Settings"
}
