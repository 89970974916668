import axios from 'axios';
import BaseService from "./BaseService";

const NOTE_API_BASE_URL = "/api/notes";

class NoteService extends BaseService {

    constructor() {
        super(NOTE_API_BASE_URL);
    }

    getNotes(page, start, length, search, sortOrder, sortDirection, appointmentId){
        return axios.get(NOTE_API_BASE_URL + '/note', {
            timeout: 5000,
            params: {
                page: page,
                draw: 1,
                start: start,
                sortOrder: sortOrder,
                sortDirection: sortDirection,
                length: length,
                search: search,
                appointmentId: appointmentId
            }
        });
    }
}

export default new NoteService()