import React, { useState } from 'react';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import { DeleteOutlined, EditOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Badge } from 'react-bootstrap';
import DeleteModal from 'components/Modals/DeleteModal';
import { Link } from 'react-router-dom';
import { deleteForm, getForms } from 'api/forms';
import CreateEditPublicForm from './CreateEditPublicForm';
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import DateRangeModal from 'components/Modals/DateRangeModal';
import ExcelIcon from 'assets/icons/excel-icon';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { downloadFormResponses } from 'api/formsSubmissions';
import { message } from 'antd'
import { isCurrentPermissionKeyPermitted } from 'utils';
import { useSelector } from 'react-redux';
import { FormModes } from 'utils/enums';

const Forms = () => {
    const [draw, setDraw] = useState(0);

    const [collateralId, setCollateralId] = useState("");
    const [filterShown, setFilterShown] = useState(false);
    const [activeFormDesignId, setActiveFormDesignId] = useState(null);
    const permissionState = useSelector(state => state.permissions);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const columns = [
        {
            title: <strong>Name</strong>,
            dataIndex: "name",
            key: "name",
            sorter: true,
            className: "Form-Name-Column",
            render: (value, record, index) => {
                let statusBadge = null;

                if (record?.status === 'LIVE') {
                    statusBadge = <Badge variant='danger'>LIVE</Badge>
                }

                if (record?.status === 'DRAFT') {
                    statusBadge = <Badge variant='dark'>DRAFT</Badge>
                }

                if (record?.status === 'INACTIVE') {
                    statusBadge = <Badge variant='secondary'>INACTIVE</Badge>
                }

                return <>
                    {isCurrentPermissionKeyPermitted('forms:options:update_form', permissionState) ? <Link to={`/form-builder/${record?.id}`} className="Form-Name-Link"><div className="message-content">
                        <span>{value}</span>&nbsp;&nbsp;
                                        </div></Link> : value} {statusBadge}
                </>;
            },
        },
        {
            title: <strong>External code</strong>,
            dataIndex: "externalFormCode",
            key: "externalFormCode",
            sorter: true,
            className: "Form-External-Code-Column",
            render: (value, record, index) => {
                return <>
                    <span>
                        {record.externalAsmCode ? record.externalAsmCode : ''}
                    </span>
                </>
            },
        },
        {
            title: isCurrentPermissionKeyPermitted('forms:options', permissionState) && <strong>Options</strong>,
            dataIndex: "id",
            key: "id",
            sorter: false,
            render: (text, record, value) => {
                const formOptions = [
                    {
                        permissionKey: 'forms:options:update_form',
                        label: 'Update Form',
                        icon: <EditOutlined />,
                        link: `/form-builder/${record && record.id}`
                    },
                    {
                        permissionKey: 'forms:options:delete_form',
                        label: 'Delete Form',
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            setCollateralId(text)
                        }
                    },
                    {
                        permissionKey: 'forms:options:download',
                        label: 'Download',
                        icon: <FileExcelOutlined />,
                        dividerBefore: true,
                        hide: record.status !== 'LIVE',
                        onClick: () => {
                            setActiveFormDesignId(record.id);
                            setFilterShown(true);
                        }
                    }
                ];

                const permittedFormOptions = formOptions.filter(formOption => isCurrentPermissionKeyPermitted(formOption.permissionKey, permissionState))

                return isCurrentPermissionKeyPermitted('forms:options', permissionState) && <ActionButtonsDropdown
                    menus={permittedFormOptions}
                />
            },
        },
    ];

    return (
        <>
            <DeleteModal
                entity="form"
                visible={collateralId}
                setVisibility={setCollateralId}
                entityId={collateralId}
                deleteFunction={deleteForm}
                onSuccess={() => setDraw(draw + 1)}
            />
            <DateRangeModal
                title={<><ExcelIcon width='50' height='50' />Download Data</>}
                subtitle={
                    <>
                        <p>Form responses will be downloaded as an excel file.</p>
                        <p>To continue, filter out the data by selecting date below</p>
                    </>
                }
                visible={filterShown}
                setVisible={setFilterShown}
                pickerSize='large'
                fullScreen={fullScreen}
                okText='Download'
                onOk={(e, dateArr) => {
                    const startDate = dateArr[0].format('yyyy-MM-DD');
                    const endDate = dateArr[1].format('yyyy-MM-DD');
                    downloadFormResponses(activeFormDesignId, startDate, endDate)
                        .then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'test.xls'); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                        }).catch(err => {
                            if (err.response.status === 409) {
                                message.error('No data to download');
                            } else {
                                message.error(err.toString());
                            }

                        })
                }}
                onCancel={() => {
                    setActiveFormDesignId(null);
                }}
            />
            <CRUDDataTable
                draw={draw}
                entity="forms"
                fetchDataFunction={async (params) => {
                    return await getForms(params);
                }}
                createModal={<CreateEditPublicForm
                    mode={FormModes.ADD}
                />}
                isButtonShown={isCurrentPermissionKeyPermitted('forms:new', permissionState)}
                // button={<Link to='/form-builder'><Button type="primary">Create New Form</Button></Link>}
                columns={columns}
            />
        </>
    );
};

export default Forms;
