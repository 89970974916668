import React from 'react';
import { Button } from 'react-bootstrap';

import { useEditor } from '@craftjs/core';

const DeleteButton = ({ component, setSelected }) => {
    const { query, actions } = useEditor();

    return (
        <Button
            className='btn-danger Btn-Delete-Component'
            onClick={() => {
                if (query.node(component.id).get()) {
                    actions.delete(component.id);
                    setSelected(null);
                }
            }} 
            block
        >
            Delete
        </Button>
    )
};

export default DeleteButton;
