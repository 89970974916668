export function questionsHasErrored(state = false, action) {
    switch (action.type) {
        case 'QUESTIONS_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function questionsIsLoading(state = false, action) {
    switch (action.type) {
        case 'QUESTIONS_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function questions(state = [], action) {
    switch (action.type) {
        case 'QUESTIONS_FETCH_DATA_SUCCESS':
            return action.questions;
        case 'QUESTIONS_REMOVE_LOCATION_SUCCESS':
            const data = state.data;
            // this bit is fancy javascript to replace the data with a new array
            return {
                ...state,
                data: remove(data, data.findIndex(data => data.id === action.questions))
            }
        default:
            return state;
    }
}

const remove = (questions,index) => {
    return [...questions.slice(0,index),
        ...questions.slice(index+1,questions.length)];
};