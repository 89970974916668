import * as React from "react"

function NurseIcon(props) {
  return (
    <svg viewBox="0 0 512 512"{...props}>
      <path
        clipRule="evenodd"
        d="M98.647 323.695c38.249-6.861 42.741 14.522 66.251 21.024 14.094 3.898 26.677 2.179 35.245 0 2.591-.677 15.792-12.22 15.792-16.696v-27.061c7.523-75.142-53.94-101.505-92.878-96.169v41.489c-2.356 30.182-5.241 54.533-25.529 73.879-2.797 2.68-1.973 4.094 1.119 3.534z"
        fill="#ce8b6b"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M200.143 379.936c38.043 20.965 73.583 21.112 111.596.501l.059-.177 8.038-42.932c-2.532-.648-5.035-1.296-7.567-1.943-4.063-1.031-6.507-2.886-6.507-7.361v-37.455c-7.656 5.3-15.841 9.688-24.321 12.838-9.216 3.416-17.314 5.124-25.44 5.124-8.127 0-16.224-1.708-25.44-5.124-8.48-3.151-16.666-7.538-24.321-12.838v37.455c0 4.476-2.444 6.331-6.507 7.361-2.532.648-5.035 1.296-7.567 1.943z"
        fill="#ffdfcf"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M323.122 347.042c8.568 2.179 14.528-5.12 23.98-2.323 18.697-15.665 28.002-27.885 66.251-21.024 3.092.559 3.946-.854 1.119-3.533-20.287-19.346-23.173-43.697-25.529-73.879v-41.489c-16.622-8.111-24.631 12.555-42.239 12.702-5.86 25.264-33.753 66.183-58.634 83.467l6.355 36.365c0 4.476 13.25 6.36 17.314 7.391z"
        fill="#ce8b6b"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M388.943 194.4c0-42.578-10.482-89.662-38.985-122.553H162.042c-25.97 29.976-38.779 73.82-38.985 122.523.147-8.716 265.739-8.716 265.886.03z"
        fill="#e29d7b"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M256 90.545c22.378.088 44.344 4.711 74.76 14.163 2.679.824 3.062.236 3.828-1.325l32.007-65.664c1.649-3.327 1.737-3.74-1.502-5.624C328.464 10.895 292.188.118 256 0c-36.188.118-72.464 10.895-109.093 32.096-3.239 1.885-3.151 2.297-1.502 5.624l32.007 65.664c.766 1.561 1.148 2.15 3.828 1.325 30.416-9.452 52.382-14.075 74.76-14.164z"
        fill="#c3ddff"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M256 318.925c8.127 0 16.224-1.708 25.44-5.124 8.48-3.151 16.666-7.538 24.321-12.838 24.881-17.285 44.079-44.227 49.938-69.492 1.207-5.212 1.855-10.365 1.855-15.341v-45.258c0-2.68-2.208-4.888-4.888-4.888h-7.067c-21.73 0-35.893-13.486-43.402-23.468l-8.745-13.545c-1.502-1.531-2.061-1.531-3.563 0l-9.835 15.223c-18.315 28.356-75.938 21.79-112.567 21.79h-8.156c-2.679 0-4.888 2.208-4.888 4.888v45.258c0 4.976.648 10.129 1.855 15.341 5.86 25.264 25.058 52.207 49.938 69.492 7.656 5.3 15.841 9.688 24.321 12.838 9.219 3.416 17.316 5.124 25.443 5.124z"
        fill="#ffeee6"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M123.057 204.794c0 17.196 15.635 26.53 33.243 26.678-1.207-5.212-1.855-10.365-1.855-15.341v-37.779h-15.311c-8.745 0-15.93 7.303-16.077 16.048v1.089zM355.7 231.472c17.608-.147 33.243-9.481 33.243-26.678v-9.305-.824-.265c-.147-8.745-7.332-16.048-16.077-16.048h-15.311v37.779c0 4.976-.648 10.129-1.855 15.341z"
        fill="#ffcebf"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M327.227 162.481c5.388 2.15 11.513 3.504 18.374 3.504h7.067c2.679 0 4.888 2.208 4.888 4.888v7.479h15.311c8.745 0 15.93 7.303 16.077 16.048 0-42.578-10.482-89.662-38.985-122.553l-15.37 31.536c-.766 1.561-1.148 2.15-3.828 1.325-5.742-1.767-11.16-3.386-16.342-4.829a394.647 394.647 0 00-9.952-2.621c11.306 18.699 18.873 40.901 22.76 65.223z"
        fill="#ce8b6b"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M314.418 99.879c5.182 1.443 10.6 3.062 16.342 4.829 2.679.824 3.062.236 3.828-1.325l15.37-31.536 16.636-34.127c1.649-3.327 1.737-3.74-1.502-5.624-12.455-7.214-24.881-13.192-37.248-17.991l-33.42 80.858c3.268.677 6.596 1.443 10.041 2.297 2.473.618 4.976 1.266 7.538 1.973.796.205 1.591.44 2.415.646z"
        fill="#a4ccff"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M313.123 364.536l6.713-27.208c-2.532-.648-5.035-1.296-7.567-1.943-4.063-1.031-6.507-2.886-6.507-7.361v-27.061c-7.656 5.3-15.841 9.688-24.321 12.838-5.3 1.973-10.247 3.386-15.046 4.211a59.802 59.802 0 01-10.394.913c-8.127 0-16.224-1.708-25.44-5.124-8.48-3.151-16.666-7.538-24.321-12.838v24.764c11.395 15.164 40.487 21.937 64.19 17.432 9.069-1.708 3.504-3.475 13.368 7.126 7.595 8.186 20.285 11.454 29.325 14.251z"
        fill="#ffcebf"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M200.143 369.572l3.533 71.435L235.359 512h41.282l35.098-141.957c-38.013 20.641-73.553 20.464-111.596-.471z"
        fill="#c3ddff"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M281.44 313.802c8.48-3.151 16.666-7.538 24.321-12.838 24.881-17.285 44.079-44.227 49.938-69.492 1.207-5.212 1.855-10.365 1.855-15.341v-45.258c0-2.68-2.208-4.888-4.888-4.888h-7.067c-6.861 0-12.985-1.355-18.374-3.504v36.159c0 36.454-34.244 82.183-76.115 97.7-9.216 3.416-17.314 5.124-25.44 5.124-8.127 0-16.224-1.708-25.44-5.124-.147-.059-.265-.118-.412-.147a144.856 144.856 0 006.419 4.77c7.656 5.3 15.841 9.688 24.321 12.838 9.216 3.416 17.314 5.124 25.44 5.124 3.445 0 6.861-.294 10.394-.913 4.802-.824 9.748-2.237 15.048-4.21z"
        fill="#ffdfcf"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M245.989 512h30.652l34.98-63.838.118-78.119c-15.194 8.245-30.034 13.192-44.756 14.782-1.001 47.112-8.627 90.721-20.994 127.175z"
        fill="#a4ccff"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M57.483 512h177.876l-35.216-142.428-7.98-32.243c-8.568 2.179-17.814 4.594-27.266 7.391-44.55 13.103-94.047 34.392-102.939 79.915l-14.987 76.676C45.352 509.703 48.62 512 57.483 512zM276.641 512h177.876c8.863 0 12.131-2.297 10.512-10.689l-14.987-76.676c-8.892-45.523-58.389-66.812-102.939-79.915-9.452-2.797-18.697-5.212-27.266-7.391l-8.038 32.537-.059.177z"
        fill="#edf4ff"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M313.123 364.536c43.078 13.221 89.012 34.599 97.551 78.296L424.159 512h30.358c8.863 0 12.131-2.297 10.512-10.689l-14.987-76.676c-8.892-45.523-58.389-66.812-102.939-79.915-9.452-2.797-18.697-5.212-27.266-7.391z"
        fill="#dbeaff"
        fillRule="evenodd"
      />
      <path
        d="M208.064 216.545a7.805 7.805 0 01-7.805-7.805v-5.86a7.805 7.805 0 0115.61 0v5.86a7.806 7.806 0 01-7.805 7.805zM303.936 216.545a7.804 7.804 0 01-7.805-7.805v-5.86a7.805 7.805 0 0115.61 0v5.86a7.805 7.805 0 01-7.805 7.805z"
        fill="#6a5d6a"
      />
      <path
        d="M255.994 280.898c-.232 0-.463-.003-.696-.007-9.691-.197-18.679-4.428-25.31-11.913a7.806 7.806 0 0111.685-10.351c3.707 4.185 8.659 6.55 13.942 6.657 5.565.109 11.02-2.334 15.399-6.89a7.805 7.805 0 1111.254 10.817c-7.26 7.554-16.567 11.687-26.274 11.687z"
        fill="#ffcebf"
      />
      <path
        d="M396.01 476.049h-64.367a7.805 7.805 0 010-15.61h64.367a7.805 7.805 0 010 15.61z"
        fill="#c3ddff"
      />
      <path
        d="M256 70.318a7.805 7.805 0 01-7.805-7.805V29.416a7.805 7.805 0 0115.61 0v33.097A7.804 7.804 0 01256 70.318z"
        fill="#ff8086"
      />
      <path
        d="M272.548 53.769h-33.096a7.805 7.805 0 010-15.61h33.096c4.311 0 7.805 3.494 7.805 7.805s-3.494 7.805-7.805 7.805z"
        fill="#ff8086"
      />
    </svg>
  )
}

export default NurseIcon
