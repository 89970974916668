import {BASE_API_URL} from "./config";
import {createAxios} from './axios';

const collateralsApi = createAxios({
    baseURL: BASE_API_URL + '/collaterals'
})

export const getCollateralsDatatable = params => {
    return collateralsApi.get('', {
        params: {
            page: params.page,
            draw: 1,
            start: params.start,
            length: params.length,
            search: params.search,
            sortOrder: params.sortOrder,
            sortDirection: params.sortDirection,
            appointmentId: params.appointmentId
        }
    });
}

export const getCollateralById = id => {
    return collateralsApi.get(`/${id}`);
}

export const getCollateralList = location => {
    return collateralsApi.get('', {
        params: {
            locationId: location
        }
    })
}

export const addCollateral = params => {
    // should turn this into formdata
    return collateralsApi.post('', {
        name: params.name,
        description: params.description,
        locationId: params.locationId,
        url: params.url,
        file: params.file
    })
}

export const deleteCollateral = id => {
    return collateralsApi.delete(`/${id}`);
}

export const createCollateral = form => {
    return collateralsApi.post(``, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
};

export const fetchCollaterals = () => {
    return collateralsApi.get();
}

export const getCollateralURL = id => {
    return collateralsApi.get(`${id}/url`);
}

export const getCollateralFile = id => {
    return `${BASE_API_URL}/collaterals/${id}/file`
}

export const updateCollateral = (id, form) => {
    return collateralsApi.put(`/${id}`,form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
};