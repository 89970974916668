import React, {useEffect} from 'react';
import uuid from 'react-uuid';
import * as d3 from 'd3';

import 'styles/StackedBarChart.scss';

export default (
    {
        width = 960,
        height = 500,
        data=[],
        columns = {
            0: []
        },
        domainData = '',
        colors = ['#ff8c00', '#d0743c', '#a05d56', '#98abc5', '#8a89a6', '#7b6888', '#6b486b'],
        spaceInBetween = 4,
        spaceBetweenDomain = 20
    }
) => {
    const mData = JSON.parse(JSON.stringify(data));
    const fontSize = '12px';
    const rootClassName = `a${uuid()}`;
    useEffect(() => {
        if(!mData || mData.length < 1) return;

        const margin = {top: 50, right: 20, bottom: 80, left: 40};

        width = width - margin.left - margin.right;
        height = height - margin.top - margin.bottom;

        const x0 = d3.scaleBand().range([0, width]);

        const x1 = d3.scaleBand();

        const y = d3.scaleLinear()
            .range([height, 0]);

        const xAxis = d3.axisBottom()
            .scale(x0);

        const yAxis = d3.axisLeft()
            .scale(y)
            .tickFormat(d3.format('.2'));

        const color = d3.scaleOrdinal().range(colors);

        d3.select(`.${rootClassName}`).html('');

        const svg = d3.select(`.${rootClassName}`).append('svg')
            .attr('viewBox', `0 0 ${ width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        let yBegin;

        const columnHeaders = Object.keys(mData[0]).filter(function (key) {
            return key !== domainData;
        });
        color.domain(Object.keys(mData[0]).filter(function (key) {
            return key !== domainData;
        }));

        const legendX = d3.scaleLinear().domain([0, columnHeaders.length]).range([0, width / 2]);

        let iData = mData.map(d => d);
        iData.forEach(function(d) {
            let yColumn = new Array();
            d.columnDetails = columnHeaders.map(function(name) {
                for (let ic in columns) {
                    if(columns[ic].indexOf(name) >= 0){
                        if (!yColumn[ic]){
                            yColumn[ic] = 0;
                        }
                        yBegin = yColumn[ic];
                        yColumn[ic] += +d[name];
                        return {name: name, column: ic, yBegin: yBegin, yEnd: +d[name] + yBegin,};
                    }
                }
            });
            d.total = d3.max(d.columnDetails, function(d) {
                return d.yEnd;
            });
        });

        x0.domain(iData.map(function(d) { return d[domainData]; }));
        x1.domain(Object.keys(columns)).range([spaceBetweenDomain, x0.bandwidth()]);

        y.domain([0, d3.max(iData, function(d) {
            return d.total;
        })]);

        svg.append('g')
            .attr('class', 'x axis')
            .style('font-size', fontSize)
            .attr('transform', () => {
                const stackBarInGroup = Object.keys(columns).length;
                const offset = (spaceBetweenDomain / 2) + (spaceInBetween * stackBarInGroup);
                return `translate(${offset}, ${height})`;
            })
            .call(xAxis);

        svg.append('g')
            .attr('class', 'y axis')
            .call(yAxis)
            .append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', 6)
            .attr('dy', '.7em')
            .style('text-anchor', 'end')
            .style('font-size', fontSize)
            .text('');

        const project_stackedbar = svg.selectAll('.project_stackedbar')
            .data(iData)
            .enter().append('g')
            .attr('class', 'g')
            .attr('transform', d => `translate(${x0(d[domainData])}, 0)`);

        const total = project_stackedbar.append('text');

        total
            .style('display', d => d?.total > 0? null: 'none')
            .attr('transform', (d, i) => {
                return `translate(${(x1.bandwidth() / 2) + spaceBetweenDomain}, ${y(d.total)})`
            })
            .attr('dy', '-0.5em')
            .attr('font-weight', 'bold')
            .style('font-size', fontSize)
            .text(d => d.total);

        const tooltip = svg.append('g')
            .attr('class', `${rootClassName}-tooltip`)
            .style('display', 'none');

        tooltip.append('rect')
            .attr('height', 20)
            .attr('fill', 'black');

        tooltip.append('text')
            .attr('x', 5)
            .attr('dy', '1.1em')
            .attr('fill', 'white')
            .attr('font-weight', 'bold');

        project_stackedbar.selectAll('rect')
            .data(d => d.columnDetails)
            .enter().append('rect')
            .attr('width', x1.bandwidth())
            .attr('x', d => {
                return x1(d.column) + (+d.column * spaceInBetween);
            })
            .attr('y', d => {
                return y(d.yEnd);
            })
            .attr('height', d => {
                return y(d.yBegin) - y(d.yEnd);
            })
            .style('fill', function(d) {
                return color(d.name);
            })
            .on('mouseover', () => tooltip.style('display', null))
            .on('mouseout', () => tooltip.style('display', 'none'))
            .on('mousemove', (e, d) => {
                const xPosition = d3.pointer(e, project_stackedbar.node())[0] - 15;
                const yPosition = d3.pointer(e, project_stackedbar.node())[1] - 25;
                tooltip.attr('transform', `translate(${xPosition}, ${yPosition})`);
                tooltip.select('text').text(() => `${d.name}: ${d.yEnd - d.yBegin}`);
                tooltip.select('rect').attr('width', () => {
                    return tooltip.select('text').node().getBoundingClientRect().width + 10;
                });
            })

        const legend = svg.selectAll('.legend')
            .data(columnHeaders.slice().reverse())
            .enter().append('g')
            .attr('class', 'legend')
            .attr('transform', (d, i) => {
                return `translate(${legendX(i)}, -50)`;
            });

        const rectWidth = 15;
        const align = width / columnHeaders.length;

        legend.append('rect')
            .attr('x', align)
            .attr('width', rectWidth)
            .attr('height', rectWidth)
            .style('fill', color);

        legend.append('text')
            .attr('x', align + rectWidth + 2)
            .attr('y', 9)
            .attr('dy', '.30em')
            .style('text-anchor', 'start')
            .style('font-size', fontSize)
            .text(d => d);

    }, [mData, width]);


    return (<div className={rootClassName}></div>)
}