import React, {useEffect, useState} from 'react';

import 'App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'sb-admin-2.scss'
import 'style.scss'
import 'antd/dist/antd.css';
import KeycloakAuthPage from 'components/landingRoute/KeycloakAuthPage';
import MSAuthPage from 'components/landingRoute/MsAuthPage';
import GlobalStyle from 'theme';
import { useSelector } from 'react-redux';
import { getBrandingByTenant, getBrandingLogoByTenant } from 'api/branding';
import { useDispatch } from 'react-redux';
import {useAuthContext} from "hooks";
import { setBrandingTenantUniqueId } from 'redux/actions/branding';
import { setTenantBranding, setTenantBrandingLogo } from 'redux/actions/tenant';
import { createGoogleFontLinkTag } from 'utils';
import { Skeleton } from 'antd';

function App() {
    const [landing, setLanding] = useState(<KeycloakAuthPage/>);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    const tenant = useSelector(state => state.tenant);
    const { branding } = tenant;

    const buildGoogleFontApiSource = (typography) => {
        if (typography?.fontUrl) {
            createGoogleFontLinkTag(typography.fontUrl);
        }
    }

    const setPageTitle = () => {
        if (branding.page.appTitle) {
            document.title = 'Admin Portal - ' + branding.page.appTitle
        }
    }

    // determine auth type
    useEffect(() => {
        getBrandingByTenant(tenant.uniqueId)
            .then(response => {
                const responseData = response.data.data;
                const branding = JSON.parse(responseData.theme);
                buildGoogleFontApiSource(branding.theme.typography);

                if (branding) {
                    // dispatch(setBrandingTenantUniqueId(tenant.uniqueId));
                    dispatch(setTenantBranding(branding));
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);

                if (branding) {
                    // dispatch(setBrandingTenantUniqueId(tenant.uniqueId));
                    dispatch(setTenantBranding(branding));
                }
                setIsLoading(false);
            });
        // todo for multi auth, create auth page that allows them
        //  to select which auth and redirects to respective auth
        let landingPage = true ? <KeycloakAuthPage/> : <MSAuthPage/>;
        setLanding(landingPage)
    }, []);

    useEffect(() => {
        setPageTitle();
    }, [branding.page]);

    return <>
            <GlobalStyle branding={branding} />
            <Skeleton loading={isLoading}>{landing}</Skeleton>
        </>;
}

export default App;