import {BASE_API_URL} from 'api/config';
import {createAxios} from './axios';

const staffTasksApi = createAxios({
  baseURL: BASE_API_URL + '/staff-tasks'
});

export const getStaffTasks = params => {
  return staffTasksApi.get(``,{
    params: params
  });
}

export const createStaffTask = payload => {
  return staffTasksApi.post(``, payload);
}

export const updateStaffTaskStatus = (id, payload) => {
  return staffTasksApi.patch(`/${id}/status`,payload);
}

export const getIncompleteStaffTasks = () => {
  return staffTasksApi.get('/incomplete/count');
}