import { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import logo from 'assets/images/5f-dark-logo.png';
import { getBrandingLogoByTenant } from 'api/branding';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import InputField from 'components/Controls/InputField';
import PasswordField from 'components/Controls/PasswordField';
import { useDispatch } from 'react-redux';
import { setTokens } from 'redux/actions/auth';
import { authenticateUser } from 'api/auth';
import { saveAuth } from '../../utils/helpers';

const LoginSchema = Yup.object().shape({
  username: Yup.string().required('Please enter your username'),
  password: Yup.string().required('Please enter your password'),
});

const LoginPage = () => {
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.tenant);
  const [currentLogo, setCurrentLogo] = useState(
    getBrandingLogoByTenant(tenant.uniqueId)
  );

  //  useEffect(() => {
  //    const token = sessionStorage.getItem('token');
  //    const expiryDate = sessionStorage.getItem('expiryDate');
  //    const refresh_token = sessionStorage.getItem('refresh_token');
  //    if (token && expiryDate) {
  //      if (new Date(expiryDate) <= new Date()) {
  //        removeAuth();
  //        setTokens({
  //          access_token: '',
  //          refresh_token: '',
  //          isUserAuthenticated: true,
  //        });
  //      } else {
  //        dispatch(
  //          setTokens({
  //            access_token: token,
  //            refresh_token: refresh_token,
  //            isUserAuthenticated: true,
  //          })
  //        );
  //      }
  //    }
  //  }, []);

  const login = async (payload) => {
    try {
      const response = await authenticateUser(payload);

      if (response?.data) {
        if (response.data.status === 401) {
          message.error('Invalid username or password');
          return false;
        }

        const { access_token, refresh_token, expires_in } = response.data;

        saveAuth(access_token, refresh_token, expires_in);

        dispatch(
          setTokens({
            access_token: access_token,
            refresh_token: refresh_token,
            isUserAuthenticated: true,
          })
        );
      }
    } catch (err) {
      if (err.response?.data) {
        if (err.response.status === 401) {
          message.error('Invalid username or password');
        }
      }
    }
  };

  return (
    <div className='LoginPageWrapper'>
      <div className='LoginCard'>
        <Row>
          <Col>
            <img
              className='Logo img-responsive'
              src={currentLogo}
              onError={() => setCurrentLogo(logo)}
            />
          </Col>
        </Row>
        <Row
          style={{
            marginTop: '10px',
          }}
        >
          <Col>
            <div className='Login-Details-Container'>
              <div className='Title-Container'>
                <span>Sign In</span>
                <small>
                  to continue using {tenant.branding.page.appSubTitle || 'Digital Patient Journey'} Admin Portal
                </small>
              </div>
              <div className='Login-Details'>
                <Formik
                  initialValues={{
                    username: '',
                    password: '',
                  }}
                  validationSchema={LoginSchema}
                  onSubmit={login}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Field
                        name='username'
                        placeholder='Enter your username'
                        size='large'
                        component={InputField}
                        autoFocus
                      />
                      <Field
                        name='password'
                        size='large'
                        placeholder={'Enter your password'}
                        component={PasswordField}
                      />
                      <Button
                        loading={isSubmitting}
                        htmlType='submit'
                        type='primary'
                        className='mt-4'
                        size={'large'}
                        block
                      >
                        Login
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LoginPage;
