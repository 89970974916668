import { BASE_API_URL } from 'api/config';
import { createAxios } from './axios';

const attachmentsApi = createAxios({
    baseURL: BASE_API_URL + '/attachments',
});

export const getAttachmentsDatatableById = (id, params) => {
    return attachmentsApi.get(``, {
        params: {
            appointmentId: id,
            ...params,
        },
    });
};

export const downloadAttachmentFileById = id => {
    return attachmentsApi.get(`/${id}/file`, {
        responseType: 'blob',
    });
};

export const getAttachmentUrl = attachmentId => {
    return attachmentsApi.get(`/${attachmentId}/url`);
};

export const createAppointmentAttachment = data => {
    console.log('data', data);
    return attachmentsApi.post(``, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const deleteAttachment = id => {
    return attachmentsApi.delete(`/${id}`);
};
