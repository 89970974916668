import { BASE_API_URL } from "api/config";
import { createAxios } from "./axios";

const actionApi = createAxios({
  baseURL: BASE_API_URL + "/action-templates/options",
});

export const getAllOptions = (params) => {
  return actionApi.get(``, {
    params,
  });
};

export const createTemplate = (template) => {
  return actionApi.post(``, template);
};

export const deleteTemplates = (id) => {
  return actionApi.delete(`/${id}`);
};
