import React, {useEffect, useState} from 'react';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import {getPatientTasks, updatePatientTaskStatus} from 'api/patientTasks';
import {getStaffTasks} from 'api/staffTasks';
import CreatePatientTaskModal from 'pages/Appointment/components/Tasks/CreatePatientTaskModal';
import CreateStaffTaskModal from 'pages/Appointment/components/Tasks/CreateStaffTaskModal';
import RejectStaffTaskModal from 'pages/Appointment/components/Tasks/RejectStaffTaskModal';
import {Button, message, Modal, Space, Tabs, Tag} from 'antd';
import {CheckCircleFilled, CloseCircleOutlined, UnorderedListOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {updateStatus} from 'api/tasks';
import { useSelector, useDispatch } from 'react-redux';
import { setWillTaskListRefresh } from 'redux/actions/appointment';
import { isCurrentPermissionKeyPermitted } from 'utils';

const StyledTabs = styled(Tabs)`
    & .ant-tabs-nav-wrap {
        bottom: -15px;
        z-index: 10;
        left: 25px;
    }

    & .ant-tabs-nav {
        margin: 0 !important;
        position: absolute;
    }

    & .ant-tabs-tab {
        padding: 15px !important;
    }
`

const AppointmentTasks = (props) => {
    const {refreshTaskList, setRefreshTaskList, appointmentId} = props;
    const defaultActionColTitle = <strong>Actions</strong>;
    const [createPatientTaskVisible, setCreatePatientTaskVisibility] = useState(false);
    const [createStaffTaskVisible, setCreateStaffTaskVisibility] = useState(false);
    const [isRejectStaffTaskModalVisible, setRejectStaffModalVisibilty] = useState(false);
    const [selectedStaffTaskId, setSelectedStaffTaskId] = useState(null);
    const [selectedTab, setSelectedTab] = useState('PATIENT');
    const [drawPatientTasks, setDrawPatientTasks] = useState(0);
    const [drawStaffTasks, setDrawStaffTasks] = useState(0);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [entityId, setEntityId] = useState();
    const [newStatus, setNewStatus] = useState('NEW');
    const [modalMessage, setModalMessage] = useState('');
    const [taskType, setTaskType] = useState('PATIENT');
    const [permittedTabs, setPermittedTabs] = useState(null);
    const [patientTaskId, setPatientTaskId] = useState();
    const appointmentState = useSelector(state => state.appointment);
    const permissionState = useSelector(state => state.permissions);
    const dispatch = useDispatch();

    const taskTabs = {
        PATIENT: {
            permissionKey: 'appointments:tasks:patient',
            onTabChange: (tab) => {
                setTaskType('PATIENT')
                setDrawPatientTasks(drawPatientTasks + 1);
                setSelectedTab(tab);
            }
        },
        STAFF: {
            permissionKey: 'appointments:tasks:staff',
            onTabChange: (tab) => {
                setTaskType('STAFF')
                setDrawStaffTasks(drawStaffTasks + 1);
                setSelectedTab(tab);
            }
        }
    }

    
    useEffect(() => {
        if (confirmationVisible) {
            Modal.confirm({
                title: '',
                content: modalMessage,
                okText: 'Ok',
                cancelText: 'Cancel',
                maskClosable: true,
                icon: () => <></>,
                closable: true,
                onOk: taskType === 'STAFF' ? onOk : onPatientOk,
                onCancel: () => {
                    setConfirmationVisible(false)
                }
            });
        }
    }, [confirmationVisible]);

    useEffect(() => {
        const newPermittedTabs = {};
        Object.keys(taskTabs).map((taskTabKey) => {
            const { permissionKey } = taskTabs[taskTabKey];
            if (isCurrentPermissionKeyPermitted(permissionKey, permissionState)) {
                newPermittedTabs[taskTabKey] = taskTabs[taskTabKey];
            }
        });
        setPermittedTabs(newPermittedTabs);
        setSelectedTab(Object.keys(newPermittedTabs)[0]);
    }, []);

    useEffect(() => {
        if (refreshTaskList) {
            setDrawPatientTasks(drawPatientTasks + 1);
            setRefreshTaskList(false);
        }
    }, [refreshTaskList]);

    useEffect(() => {
        if (appointmentState.willTaskListRefresh) {
            setDrawPatientTasks(drawPatientTasks + 1);
            dispatch(setWillTaskListRefresh(false));
        }
    }, [appointmentState.willTaskListRefresh]);

    useEffect(() => {
        if (appointmentId) {
            setDrawPatientTasks(drawPatientTasks + 1);
        }
    }, [appointmentId]);

    const renderPatientTasksAction = (text, record, value) => {
        let action = null;
        if (isCurrentPermissionKeyPermitted('appointments:tasks:patient:list:columns:actions', permissionState)) {
            if (record.status === 'NEW') {
                action = <Button
                    type='primary'
                    onClick={() => {
                        setPatientTaskId(text)
                        setModalMessage('Mark task as Complete?')

                        setNewStatus('COMPLETED')
                        setConfirmationVisible(true)
                    }}
                >
                    Complete
                </Button>
            } else {
                action = <Button
                    onClick={() => {
                        setPatientTaskId(text)
                        setModalMessage('Undo Completed task?')

                        setNewStatus('NEW')
                        setConfirmationVisible(true)
                    }}
                >
                    Undo
                </Button>
            }
        }

        return action;
    }

    const renderStaffTasksAction = (text, record, value) => {
        let action = null;
        if (isCurrentPermissionKeyPermitted('appointments:tasks:staff:list:columns:actions', permissionState)) {
              if (record.status === 'NEW') {
                  action = (
                      <Space>
                          <Button
                              type='primary'
                              onClick={() => {
                                  setEntityId(text);
                                  setModalMessage('Mark task as Complete?');
      
                                  setNewStatus('COMPLETED');
                                  setConfirmationVisible(true);
                              }}
                          >
                              Complete
                          </Button>
                          <Button
                              type='danger'
                              ghost
                              onClick={() => {
                                  setEntityId(text)
                                  setModalMessage('Reject task?')
      
                                  setNewStatus('REJECTED')
                                  setConfirmationVisible(true)
                                  // showCompleteConfirmation(text)
                              }}
                          >
                              Reject
                          </Button>
                      </Space>
                  )
              } else if (record.status === 'COMPLETED') {
                  action = <>
                      <Button
                          onClick={() => {
                              setEntityId(text)
                              setModalMessage('Undo Completed task?')
                              setNewStatus('NEW')
                              setConfirmationVisible(true)
                          }}
                      >
                          Undo
                      </Button>
                  </>
              } else if (record.status === 'REJECTED') {
                  action = <>
                    <Button
                          onClick={() => {
                              setEntityId(text)
                              setModalMessage('Undo Rejected task?')
                              setNewStatus('NEW')
                              setConfirmationVisible(true)
                          }}
                      >
                          Undo
                    </Button>
                </>
            }
        }

        return action;
    }
      

  const onOk = () => {
        updateStatus(entityId, {
            rejectionReason: '',
            status: newStatus
        })
        .then(res => {
            setDrawStaffTasks(drawStaffTasks + 1);
            setConfirmationVisible(false);
        });
  }

  const onPatientOk = () => {
    setDrawPatientTasks(0);
    updatePatientTaskStatus(patientTaskId, newStatus)
        .then(res => {
            message.success('Successfully updated task.');
            setDrawPatientTasks(drawPatientTasks + 1);
            setConfirmationVisible(false)
        });
  }

const fetchPatientTasks = async (params) => {
  params = {
    ...params,
    sortColumn: 'id',
    appointmentId
  }
  return await getPatientTasks(params)
}

const fetchStaffTasks = async (params) => {
  params = {
    ...params,
    sortColumn: 'id',
    appointmentId
  }
  return await getStaffTasks(params);
}

const renderActionColumn = (activeTab) => {
    let actionColumn = null;

    if (activeTab === 'PATIENT' && isCurrentPermissionKeyPermitted('appointments:tasks:patient:list:columns:actions', permissionState) || activeTab === 'STAFF' && isCurrentPermissionKeyPermitted('appointments:tasks:staff:list:columns:actions', permissionState)) {
        actionColumn = <strong>Actions</strong>;
    }

    return actionColumn;
}

const columns = [
  {
        title: <strong>Task</strong>,
        dataIndex: 'description',
        key: 'description',
        sorter: true,
        render: (text, record, value) => {
        return <><div className="message-content">
        <span className="mr-3">{text}</span>
        <Tag visible={record.status === 'COMPLETED'}
                color='green'
                icon={<CheckCircleFilled style={{position: 'relative', top: '-2px', height: '10px'}}/>}
                style={{marginLeft: '5px'}}
            >
                DONE
            </Tag>
            <Tag
                visible={record.status === 'REJECTED'}
                color='red'
                icon={<CloseCircleOutlined style={{position: 'relative', top: '-2px', height: '10px'}}/>}
                style={{marginLeft: '5px'}}
            >
                REJECTED
            </Tag>
      </div>         
        </>

    }
  },
  {
        title: () => renderActionColumn(selectedTab),
        dataIndex: 'id',
        key: 'id',
        sorter: false,
        render: selectedTab === 'PATIENT' ? renderPatientTasksAction : renderStaffTasksAction
  }
];


return (
  <>
    <RejectStaffTaskModal   
        visible={isRejectStaffTaskModalVisible}
        setVisibility={setRejectStaffModalVisibilty}
        id={selectedStaffTaskId}
        onSuccess={() => setDrawStaffTasks(drawStaffTasks + 1)}
    />
        <StyledTabs
            activeKey={selectedTab}
            onChange={(tab) => {
                if (permittedTabs && permittedTabs[tab]) {
                    permittedTabs[tab]?.onTabChange(tab);
                }
            }}
        >
            {permittedTabs && permittedTabs['PATIENT'] && <StyledTabs.TabPane tab={<span className='text-primary font-weight-bold'>Patient Tasks</span>} key='PATIENT'>
                <CRUDDataTable
                    draw={drawPatientTasks}
                    tabs={true}
                    fetchDataFunction={fetchPatientTasks}
                    entity='patient task'
                    createModal={
                        <CreatePatientTaskModal
                            taskType='CUSTOM'
                            visible={createPatientTaskVisible}
                            setVisibility={setCreatePatientTaskVisibility}
                            appointmentId={props.appointmentId}
                            icon={<UnorderedListOutlined className='mr-2'/>}
                            onSuccess={() => setDrawPatientTasks(0)}
                            onCancel={() => {
                                setDrawPatientTasks(0);
                                setCreatePatientTaskVisibility(false)
                            }}
                        />
                    }
                    isButtonShown={isCurrentPermissionKeyPermitted('appointments:tasks:patient:new', permissionState)}
                    columns={columns}
                />
            </StyledTabs.TabPane>}
            {permittedTabs && permittedTabs['STAFF'] && <StyledTabs.TabPane tab={<span className='text-primary font-weight-bold'>Staff Tasks</span>} key='STAFF'>
                <CRUDDataTable
                    draw={drawStaffTasks}
                    tabs={true}
                    fetchDataFunction={fetchStaffTasks}
                    entity='staff task'
                    createModal={
                        <CreateStaffTaskModal
                            visible={createStaffTaskVisible}
                            setVisibility={setCreateStaffTaskVisibility}
                            appointmentId={props.appointmentId}
                            icon={<UnorderedListOutlined className='mr-2'/>}
                            fetchData={() => setDrawStaffTasks(0)}
                            onCancel={() => {
                                setDrawStaffTasks(0);
                                setCreateStaffTaskVisibility(false)
                            }}
                        />
                    }
                    isButtonShown={isCurrentPermissionKeyPermitted('appointments:tasks:staff:new', permissionState)}
                    columns={columns}
                />
            </StyledTabs.TabPane>}
        </StyledTabs>
  </>
)
}
;

export default AppointmentTasks
