import { Select, Row, Col, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import { groupBy } from '../../../utils/helpers';

const RuleCreation = ({
  index,
  addRule,
  addValue,
  value,
  actionOptionTemplates,
}) => {
  const { Option } = Select;
  const addNewValue = (val) => {
    addValue(`${val}`, index);
  };
  const returnValues = (val) => {
    let selectOptions;
    if (val === 'Reminder') {
      selectOptions = (
        <InputNumber
          onChange={addNewValue}
          size='large'
          style={{ width: '100%' }}
          placeholder='enter days before target due'
          value={value?.requiredValue}
        />
      );
    } else {
      selectOptions = (
        <Select
          size='large'
          style={{ width: '100%' }}
          onChange={addNewValue}
          defaultValue={value.requiredValue}
        >
          {actionOptionTemplates
            .filter((f) => f.actionType === 'RULE' && f.valueType === val)
            .map((item) => {
              return (
                <Option value={item.valueDisplay} key={item.valueDisplay}>
                  {item.valueDisplay}
                </Option>
              );
            })}
        </Select>
      );
    }
    return selectOptions;
  };
  const addNewRule = (val) => {
    console.log(val);
    //setValues(val);
    addRule(val, index);
  };

  const rules = actionOptionTemplates.filter((f) => f.actionType === 'RULE');
  const groupedRules = groupBy(rules, 'valueType');

  return (
    <>
      <label>Required Value:</label>
      <Row style={{ width: '100%' }}>
        <Col span={8}>
          <Select
            onChange={addNewRule}
            size='large'
            style={{ width: '100%' }}
            defaultValue={value.checkedValue ?? 'APPOINTMENT_TYPE'}
          >
            {groupedRules.map((item) => {
              return (
                <Option value={item.valueType} key={item.valueType}>
                  {item.valueType}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col span={16}>{returnValues(value?.checkedValue)}</Col>
      </Row>
    </>
  );
};

export default RuleCreation;
