import {BASE_API_URL} from 'api/config';
import {createAxios} from "./axios";

const patientTasksApi = createAxios({
  baseURL: BASE_API_URL + '/patient-tasks'
});

export const getPatientTasks = params => {
  return patientTasksApi.get(``,{
    params: params
  });
}

export const createPatientTask = payload => {
  return patientTasksApi.post(``, payload);
}

export const updatePatientTaskStatus = (id, status) => {
  return patientTasksApi.patch(`/${id}/status?status=${status}`)
}