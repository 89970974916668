import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Button, Input as AntInput, Modal, Slider, Alert } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import Input from 'pages/FormBuilder/components/Input';

import { PlusOutlined } from '@ant-design/icons';
import { useNode, useEditor } from '@craftjs/core';

import { FaPlusCircle } from 'react-icons/all';
import _ from 'lodash';
import uuid from 'react-uuid';

const Grid = ({ children, isSelected = false, isEventChild = false }) => {
  const {
    connectors: { connect, drag },
    isHovered,
    cardTitle,
    currentNodeValues,
  } = useNode((state) => ({
    currentNodeValues: state.data.props.value,
    isHovered: state.events.hovered,
    selected: state.events.selected,
    cardTitle: state.data.props.cardTitle,
  }));

  const { query, actions } = useEditor();
  const isEditor = query.getOptions().enabled;
  const [rowId, setRowId] = useState(0);

  useEffect(() => {
    console.log(currentNodeValues);
    const rows = _(currentNodeValues)
      .groupBy((x) => x.rowId)
      .map((value, key) => ({ rowId: key, detail: value }))
      .value();

    rows.forEach((row) => {
      children.props.children.forEach((child) => {
        const node = query.getSerializedNodes()[child.key];
        const cur = row.detail.find((d) => d.name === node.props.name);
        if (cur) {
          duplicate(child.key, cur, row.rowId);
        } else if (node.displayName === 'Container') {
          duplicate(child.key, row.detail, row.rowId);
        }
      });
    });
  }, [currentNodeValues]);

  const getParsedSerializedNodeById = (id) => {
    const currentNode = query.getSerializedNodes()[id];
    const parsedSerializedNode = query
      .parseSerializedNode(currentNode)
      .toNode();

    return parsedSerializedNode;
  };

  const duplicate = async (currentSelectedNode, cur, gridRowId) => {
    //const newGridRecords = [...gridRecords];

    if (currentSelectedNode) {
      const parsedSerializedNode =
        getParsedSerializedNodeById(currentSelectedNode);
      const copyParsedSerializedNode = { ...parsedSerializedNode };
      let newChildNodes = [];
      //let newChildChildNodes = [];

      if (copyParsedSerializedNode.data.parent) {
        const parsedParentNode = getParsedSerializedNodeById(
          parsedSerializedNode.data.parent
        );

        if (
          copyParsedSerializedNode.data.nodes &&
          copyParsedSerializedNode.data.nodes.length > 0
        ) {
          copyParsedSerializedNode.data.nodes.map((nodeId) => {
            const parsedSerializedChildNode =
              getParsedSerializedNodeById(nodeId);
            const newParsedSerialized = _.cloneDeep(parsedSerializedChildNode);

            newChildNodes.push(newParsedSerialized);
          });
        }

        let currentSelectedNodeIndex = parsedParentNode.data.nodes
          ? parseInt(
              parsedParentNode.data.nodes.findIndex(
                (node) => node === currentSelectedNode
              )
            ) + 1
          : 0;

        copyParsedSerializedNode.data.nodes = [];

        await actions.setProp(
          currentSelectedNode,
          (props) => (props.isSelected = false)
        );
        await actions.setProp(
          currentSelectedNode,
          (props) => (props.isGridComponent = 1)
        );

        await actions.add(
          copyParsedSerializedNode,
          parsedSerializedNode.data.parent,
          currentSelectedNodeIndex
        );

        const updatedParentNode = getParsedSerializedNodeById(
          parsedSerializedNode.data.parent
        );

        if (
          updatedParentNode &&
          updatedParentNode.data.nodes &&
          updatedParentNode.data.nodes.length > 0
        ) {
          const newlyAddedNodeId = parsedSerializedNode.id;
          //  updatedParentNode.data.nodes[currentSelectedNodeIndex];
          const newChildofChild = [];

          if (newChildNodes && newChildNodes.length > 0) {
            newChildNodes.map(async (newChildNode, idx) => {
              newChildNode.data.nodes.forEach((ncn) => {
                const newNode = getParsedSerializedNodeById(ncn);
                newChildofChild.push({
                  id: newChildNode.id,
                  node: newNode,
                  oldId: ncn,
                });
              });

              newChildNode.data.nodes = [];

              await actions.add(newChildNode, newlyAddedNodeId, idx);
            });
          }
          await actions.setProp(
            newlyAddedNodeId,
            (props) => (props.isGridComponent = 2)
          );

          await actions.setProp(
            newlyAddedNodeId,
            (props) => (props.rowId = gridRowId)
          );

          if (newChildofChild && newChildofChild.length > 0) {
            newChildofChild.map(async (newChildNode, idx) => {
              await actions.add(newChildNode.node, newChildNode.id, idx);
              actions.setProp(
                newChildNode.oldId,
                (props) => (props.isGridComponent = 1)
              );
              await actions.setProp(
                newChildNode.node.id,
                (props) => (props.isGridComponent = 2)
              );
              if (cur && cur.length) {
                const value = cur.find(
                  (c) => c.name === newChildNode?.node?.data?.props?.name
                ).value;
                const propsRowId = cur.find(
                  (c) => c.name === newChildNode?.node?.data?.props?.name
                ).rowId;

                if (value) {
                  await actions.setProp(
                    newChildNode.node.id,
                    (props) => (props.value = value)
                  );
                }
                if (propsRowId || propsRowId === 0) {
                  await actions.setProp(
                    newChildNode.node.id,
                    (props) => (props.rowId = propsRowId)
                  );
                  gridRowId = propsRowId;
                }
              } else {
                await actions.setProp(
                  newChildNode.node.id,
                  (props) => (props.rowId = gridRowId)
                );
              }
            });
          }

          if (cur && cur.value) {
            await actions.setProp(
              newlyAddedNodeId,
              (props) => (props.value = cur.value)
            );
          }

          actions.selectNode(newlyAddedNodeId);
          setRowId(gridRowId + 1);
          const node = query.getSerializedNodes()[newlyAddedNodeId];
          console.log(node);
        }
      }
    }
  };

  return (
    <div
      style={{
        height: '100%',
        minHeight: '50px',
        border: 'solid 1px rgb(217, 217, 217)',
      }}
      className={cx({
        'With-Mark': query.getOptions().enabled,
        'Component-Hovered': isHovered && query.getOptions().enabled,
        isSelected: isSelected && query.getOptions().enabled,
        isEventChild: isEventChild && query.getOptions().enabled,
      })}
      ref={(ref) => {
        connect(drag(ref));
      }}
    >
      {isEditor && (
        // <div
        //   style={{
        //     backgroundColor: 'rgba(64, 157, 134, 0.3)',
        //     textAlign: 'center',
        //   }}
        // >
        //   Add components below
        // </div>
        <>
          <div
            style={{
              background: 'rgb(249 249 249)',
              padding: '15px',
            }}
          >
            <h4
              style={{
                marginBottom: '0',
              }}
            >
              {cardTitle}
            </h4>
          </div>
          <div>
            {/* <h6 className='text-muted'><FaInfoCircle className='text-info' /> Drag components below to add.</h6> */}
            {!children && (
              <div
                style={{
                  border: '2px dashed rgb(216 216 216)',
                  textAlign: 'center',
                  padding: '10px',
                }}
              >
                <FaPlusCircle
                  style={{ color: 'rgb(216 216 216)' }}
                  fontSize={'2rem'}
                />
                <h4 style={{ color: 'rgb(216 216 216)' }}>
                  Drag components here to add
                </h4>
              </div>
            )}
          </div>
        </>
      )}
      {isEditor ? (
        children
      ) : (
        <div>
          <div
            style={{
              background: 'rgb(249 249 249)',
              padding: '15px',
            }}
          >
            <h4
              style={{
                marginBottom: '0',
              }}
            >
              {cardTitle}
            </h4>
          </div>
          {children.props.children
            .map((child) => {
              const node = query.getSerializedNodes()[child.key];
              if (node.props.isGridComponent === 2) {
                //child.node = node;
                return React.cloneElement(child, { rowId: node.props.rowId });
                //return child;
              } else {
                return null;
              }
            })
            .sort()
            .sort((a, b) => {
              if (a && b) {
                return a.props.rowId - b.props.rowId;
              } else {
                return 0;
              }
            })}
          <div style={{ textAlign: 'center', padding: '15px' }}>
            <Button
              type='dashed'
              onClick={() => {
                const gridRowId = rowId;
                children.props.children.forEach((child) => {
                  const node = query.getSerializedNodes()[child.key];
                  if (node && node.props && node.props.isGridComponent !== 2) {
                    duplicate(child.key, {}, gridRowId);
                  }
                });
              }}
              style={{ color: '#6858a2', cursor: 'pointer' }}
            >
              <PlusOutlined
                style={{
                  position: 'relative',
                  top: '-4px',
                }}
              />{' '}
              Add Record
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const GridSettings = () => {
  const {
    actions: { setProp },
    cardTitle,
    name,
  } = useNode((node) => ({
    name: node.data.props.name ? node.data.props.name : uuid(),
    cardTitle: node.data.props.cardTitle,
    cardTitleColor: node.data.props.cardTitleColor,
    headingBackground: node.data.props.headingBackground,
    bodyPadding: node.data.props.bodyPadding,
    margin: node.data.props.margin,
  }));

  return (
    <>
      <Row>
        <Col xs={12}>
          <label className='Form-Builder-Label'>Field name:</label>
          <AntInput
            placeholder='Field name'
            defaultValue={name}
            onChange={(e) => setProp((props) => (props.name = e.target.value))}
          />
        </Col>
        <Col xs={12}>
          <label>Grid Title:</label>
          <AntInput
            defaultValue={cardTitle}
            onChange={(e) => {
              setProp((props) => (props.cardTitle = e.target.value));
            }}
          />
        </Col>
      </Row>
    </>
  );
};

Grid.craft = {
  displayName: 'Grid',
  props: {
    cardTitle: 'Grid Title',
    cardTitleColor: '#fff',
    headingBackground: '#0073AC',
    margin: {
      top: 10,
      left: 10,
      right: 10,
      bottom: 10,
    },
    isSelected: false,
    isEventChild: false,
  },
  related: {
    settings: GridSettings,
  },
};

export default Grid;
