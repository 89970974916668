import React, { useState } from "react";
import { Tabs, message } from "antd";
import Aux from "components/hoc/AuxFunction";
import SystemProperties from "./SystemProperties";
import ActionTemplates from "./actionTemplates/ActionTemplates";
import BrandingAndLogo from "./BrandingAndLogo";
import { updateThemeSettings } from "api/branding";
import { createOrUpdateConfig } from "api/formSelection";
import { useDispatch, useSelector } from "react-redux";
import { setTenantBranding } from "redux/actions/tenant";
import SettingsHeader from "pages/Settings/SettingsHeader";
import { createSettings } from "api/systemSettings";
import FormSelection from "./FormSelection";
import EndpointTest from "./EndpointTest";
import { setAppointmentFormsData } from "redux/actions/appointmentForms";
import { isCurrentPermissionKeyPermitted } from "utils";
import RolePermissions from "./RolePermissions";
import MessageTemplates from "./MessageTemplates";
import { setActiveMessageTemplate, setMessageTemplateMode } from "redux/actions/messageTemplates";
import { MessageTemplateMode } from "utils/enums";

message.config({
  duration: 2,
});

const Settings = () => {
    const { TabPane } = Tabs;
    const [propertyFormVisible, setPropertyFormVisibility] = useState(false);
    const [actionFormVisible, setActionFormVisibility] = useState(false);
    const [isBrandingLogoButtonLoading, setBrandingLogoLoading] = useState(false);
    const [isFirstTimeLanded, setFirstTimeLanded] = useState(true);
    const tenantState = useSelector((state) => state.tenant);
    const brandingState = useSelector((state) => state.branding);
    const sysPropertiesState = useSelector((state) => state.sysProperties);
    const formSelectionState = useSelector((state) => state.appointmentForms);
    const permissionState = useSelector((state) => state.permissions);
    const messageTemplateState = useSelector((state) => state.messageTemplates);
    const dispatch = useDispatch();

    const onSaveFormSelection = async () => {
        if (formSelectionState?.data) {
        try {
            const response = await createOrUpdateConfig(formSelectionState.data);

            dispatch(setAppointmentFormsData(response.data));
            message.success("Appointment Forms configuration successfully saved.");
        } catch (error) {
            message.error(
            "Error occurred while trying to save the appointment forms configuration, please try again."
            );
        }
        }
    };

    const saveSystemProperties = () => {
        const systemPropertyKeys = Object.keys(sysPropertiesState.staging);

        const invalidCategory = systemPropertyKeys.find(systemPropertyKey => sysPropertiesState.staging[systemPropertyKey]?.length < 1);

        if (invalidCategory) {
            message.error(`Please add atleast one property to ${invalidCategory} category to save the settings.`);
        } else {
            try {
                setPropertyFormVisibility(true);
                (async () => {
                    const result = await createSettings(sysPropertiesState.staging);
                    if (result.status === 202) {
                        message.success("System Properties succesfully saved", 2);
                    } else {
                        message.error(
                            "Error occurred while trying to save the System Properties",
                            2
                        );
                    }
                })();
            } catch (error) {
                message.error(
                    "Error occurred while trying to save the System Properties",
                    2
                );
            }
        }
    };

    const onSaveBrandingButtonClick = async (brandingGlobalState) => {
        setBrandingLogoLoading(true);
        try {
        if (brandingState && brandingState.list.length > 0) {
            let promises = [];

            brandingState.list.map(async (brandingItem) => {
            const brandingPayload = brandingItem;
            brandingPayload.centraliseBranding = brandingState.centraliseBranding;
            brandingPayload.isPoweredByVisible = brandingState.isPoweredByVisible;

            promises.push(
                updateThemeSettings(brandingItem.tenantUniqueId, brandingPayload)
            );
            });

            await Promise.all(promises)
            .then((responses) => {
                setBrandingLogoLoading(false);

                const response = responses.find(
                (response) =>
                    JSON.parse(response.data.data).tenantUniqueId ===
                    tenantState.uniqueId
                );

                const responseJson = JSON.parse(response.data.data);

                dispatch(setTenantBranding(responseJson));
                message.success("Tenant Branding succesfully saved", 2);
            })
            .catch((error) => {
                console.log(error);
                setBrandingLogoLoading(false);
                message.error(
                "Error occurred while trying to save the branding",
                2
                );
            });
        }
        } catch (error) {
        setBrandingLogoLoading(false);
        message.error("Error occurred while trying to save the branding", 2);
        }
    };

    const saveActionTemplate = () => {
        setActionFormVisibility(true);
    };

    const createMessageTemplate = () => {
        dispatch(setActiveMessageTemplate({}));
        dispatch(setMessageTemplateMode(MessageTemplateMode.CREATE));
    };
    // TDOO currently unused
    const [activeTab, setActiveTab] = useState(1);

    const changeTab = (key) => {
        setActiveTab(Number(key));
        setFirstTimeLanded(false);
    };

    const settingsTabs = {
        SystemProperties: {
            tabTitle: 'System Properties',
            permissionKey: 'settings:system_properties',
            tabKey: '1',
            content: <>
                        <SettingsHeader
                            title="System Properties"
                            subTitle="Configure System related settings"
                            onClick={saveSystemProperties}
                            isButtonVisible={isCurrentPermissionKeyPermitted('settings:system_properties:edit', permissionState)}
                            buttonTitle="Save System Properties"
                        />
                        <SystemProperties isReadOnly={!isCurrentPermissionKeyPermitted('settings:system_properties:edit', permissionState)} />
                    </>
        },
        RolePermissions: {
            tabTitle: 'Role Permissions',
            permissionKey: 'settings:role_permissions',
            tabKey: '2',
            content: <>
                        <SettingsHeader
                            title="Role Permissions"
                            subTitle="This section is use to assign permissions to role."
                            isButtonVisible={false}
                        />
                        <RolePermissions />
                    </>
        },
        Branding: {
            tabTitle: 'Branding and Logo',
            permissionKey: 'settings:branding_logo',
            tabKey: '3',
            content: <>
                        <SettingsHeader
                            title="Branding and Logo"
                            subTitle="Configure System Branding and Logo"
                            onClick={onSaveBrandingButtonClick}
                            buttonTitle="Save Branding and Logo"
                            isButtonVisible={isCurrentPermissionKeyPermitted('settings:branding_logo:edit', permissionState)}
                        />
                        <BrandingAndLogo isReadOnly={!isCurrentPermissionKeyPermitted('settings:branding_logo:edit', permissionState)} />
                    </>
        },
        ActionTemplates: {
            tabTitle: 'Action Templates',
            permissionKey: 'settings:action_templates',
            tabKey: '4',
            content: <>
                        <SettingsHeader
                            title="Action Templates"
                            subTitle="Configure System Action Templates"
                            onClick={saveActionTemplate}
                            buttonTitle="Create Template"
                            isButtonVisible={isCurrentPermissionKeyPermitted('settings:action_templates:save', permissionState)}
                        />
                        <ActionTemplates
                            formVisible={actionFormVisible}
                            setFormVisibility={setActionFormVisibility}
                        />
                    </>
        },
        MessageTemplates: {
            tabTitle: 'Message Templates',
            permissionKey: 'settings:message_templates',
            tabKey: '5',
            content: <>
                        <SettingsHeader
                            title="Message Templates"
                            subTitle="Configure System Message Templates"
                            onClick={createMessageTemplate}
                            buttonTitle="Create Template"
                            isButtonVisible={isCurrentPermissionKeyPermitted('settings:message_templates:create', permissionState) && messageTemplateState.mode === MessageTemplateMode.LIST}
                        />
                        <MessageTemplates />
                    </>
        },
        AppointmentForms: {
            tabTitle: 'Appointment Form',
            permissionKey: 'settings:appointment_forms',
            tabKey: '6',
            content: <>
                        <SettingsHeader
                            title="Appointment Forms"
                            subTitle="This section is use to assign forms to a specific appointment type automatically."
                            buttonTitle="Save Appointment Forms"
                            onClick={onSaveFormSelection}
                            isButtonVisible={isCurrentPermissionKeyPermitted('settings:appointment_forms:save', permissionState)}
                        />
                        <FormSelection />
                    </>
        },
        Tests: {
            tabTitle: 'Tests',
            permissionKey: 'settings:tests',
            tabKey: '7',
            content: <>
                        <SettingsHeader
                            title="Tests"
                            subTitle="This section is used for testing purposes."
                            onClick={onSaveBrandingButtonClick}
                            buttonTitle="Save Branding and Logo"
                            isButtonVisible={false}
                        />
                        <EndpointTest />
                    </>
        }
    }

    const getPermittedTabs = (tabs) => {
        let permittedTabs = {};

        if (tabs && typeof tabs === 'object') {
            Object.keys(tabs).map(tabKey => {
                const currentTab = tabs[tabKey];

                if (isCurrentPermissionKeyPermitted(currentTab.permissionKey, permissionState)) {
                    permittedTabs[tabKey] = currentTab;
                }
            });
        }

        return permittedTabs;
    }

    return (
        <Aux>
            <Tabs
                onChange={changeTab}
                tabBarStyle={{ borderBottom: "none" }}
                tabPosition={"left"}
            >
                {
                    Object.keys(getPermittedTabs(settingsTabs)).map(settingsTabKey => {
                        const currentTab = getPermittedTabs(settingsTabs)[settingsTabKey];
                        const { tabKey, tabTitle, content } = currentTab;

                        return  <TabPane tab={tabTitle} key={tabKey}>
                                    {content}
                                </TabPane>
                    })
                }
            </Tabs>
        </Aux>
    );
};

export default Settings;
