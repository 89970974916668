import React from 'react';
import cx from 'classnames';

import {
    Col 
} from 'react-bootstrap';

import { useNode, useEditor } from '@craftjs/core';

const Column = ({
    children,
    isSelected = false,
    isEventChild = false
}) => {
    const {
        connectors: { connect, drag },
        isHovered,
        currentNode
    } = useNode((node) => ({
        currentNode: node,
        isHovered: node.events.hovered
    }));

    const { query } = useEditor();

    return (
        <>
            <Col 
                className={cx({
                    'With-Mark': query.getOptions().enabled,
                    'Component-Hovered': isHovered && query.getOptions().enabled,
                    'isSelected': isSelected && query.getOptions().enabled,
                    'isEventChild': isEventChild && query.getOptions().enabled
                })}
                style={{ padding: '5px' }}
                ref={ref => {
                    connect(drag(ref));
                }}
            >
                {isEventChild && query.getOptions().enabled && <div style={{
                    position: 'absolute',
                    top: '-30px',
                    left: '0',
                    padding: '5px',
                    background: '#ffc400',
                    fontSize: '12px'
                }}>
                    Column_{currentNode.id}
                </div>}
                {children}
            </Col>
        </>
    );
};

Column.craft = {
    displayName: 'Column',
    props: {
        isSelected: false,
        isEventChild: false
    }
}

export default Column;