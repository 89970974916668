import { refreshToken } from 'api/auth';

export const groupBy = (items, prop) => {
  var arr = [];
  for (var i = 0, _len = items.length; i < _len; i++) {
    var bfound = false;
    for (var j = 0; j < arr.length; j++) {
      if (arr[j][prop] === items[i][prop]) {
        bfound = true;
      }
    }
    if (bfound === false) {
      arr.push({ [prop]: items[i][prop] });
    }
  }
  return arr;
};

export const saveAuth = (token, refresh_token, expires_in) => {
  const expiryDate = new Date(new Date().getTime() + expires_in * 1000);

  sessionStorage.setItem('token', token);
  sessionStorage.setItem('refresh_token', refresh_token);
  sessionStorage.setItem('expiryDate', expiryDate);
};

export const removeAuth = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('refresh_token');
  sessionStorage.removeItem('expiryDate');
};

export const tokenExpired = () => {
  const token = sessionStorage.getItem('token');
  const expiryDate = sessionStorage.getItem('expiryDate');

  //;

  //return token && expiryDate && new Date(expiryDate) <= new Date();
  // change 1 minutes ago
  return (
    token &&
    expiryDate &&
    new Date(new Date(expiryDate).getTime() - 1 * 60000) <= new Date()
  );
};

export const refreshTheToken = async () => {
  try {
    const refreshtoken = sessionStorage.getItem('refresh_token');

    const payload = {
      refreshToken: refreshtoken,
    };
    const response = await refreshToken(payload);
    const { access_token, refresh_token, expires_in } = response.data;
    // save the new credentials
    saveAuth(access_token, refresh_token, expires_in);
    return { access_token, refresh_token, expires_in };
  } catch (err) {
    const access_token = null,
      refresh_token = null,
      expires_in = null;
    return { access_token, refresh_token, expires_in };
  }
};
