import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, message, Modal, Row, Space, Typography } from 'antd';
import { Formik } from 'formik';
import { Checkbox, Form, Input, SubmitButton } from 'formik-antd';
import * as yup from 'yup';
import { HomeOutlined } from '@ant-design/icons';
import { createLocation, updateLocation } from 'api/locations';

const { Title, Text } = Typography;

// TODO ready but not yet implemented. please see TODO note below
const CreateLocationSchema = yup.object().shape({
  name: yup.string().required('Please enter a name.'),
  openingHour: yup.string().required('Please select an opening hour.'),
  closingHour: yup.string().required('Please select a closing hour.'),
  uniqueId: yup.string().required('Please enter public form ID.'),
  childrenAllowed: yup.boolean(),
  childrenTypeName: yup.string().when('childrenAllowed', {
    is: true,
    then: yup.string().required('Please enter name for sub locations.'),
  }),
});

const LocationForm = ({
  visible,
  setVisibility,
  onSuccess,
  locationId,
  selectedLocation,
  setSelectedLocation,
}) => {
  const formikRef = useRef(null);
  const title = selectedLocation !== undefined ? 'Update' : 'Create';
  const submitText = selectedLocation !== undefined ? 'Save' : 'Create';
  const [location, setLocation] = useState({});
  useEffect(() => {
    if (selectedLocation !== undefined) {
      // this allows pass by value and not reference
      // sorry had to resort to temp solution, quite new to js
      setLocation(JSON.parse(JSON.stringify(selectedLocation ?? null)));
    }
  }, [selectedLocation]);

  async function create(values) {
    try {
      message.loading('Uploading location...', 0);
      location.name = values.name ?? location.name;
      location.contactNumber = values.contactNumber ?? location.contactNumber;
      location.cancelContactNumber = values.cancelContactNumber ?? location.cancelContactNumber;
      location.uniqueId = values.uniqueId ?? location.uniqueId;
      location.childrenAllowed =
        values.childrenAllowed ?? location.childrenAllowed;
      location.childrenTypeName =
        values.childrenTypeName ?? location.childrenTypeName;
      location.openingHour = values.openingHour ?? location.openingHour;
      location.closingHour = values.closingHour ?? location.closingHour;
      location.visitCapacity = values.visitCapacity ?? location.visitCapacity;

      /**
       * TODO take note that this post creation validation was used
       * instead of formik validation. This is because formik was
       * causing a bug where in preloading values will not run through
       * the validation schema and will be read as undefined
       */
      if (
        !location.name ||
        !location.uniqueId ||
        (location.childrenAllowed && !location.childrenTypeName) ||
        !location.openingHour ||
        !location.closingHour ||
        !location.visitCapacity
      ) {
        message.destroy();
        message.error('Please fill up all details');
        return;
      }

      const res =
        selectedLocation !== undefined
          ? await updateLocation(locationId, location)
          : await createLocation(values);
      message.destroy();
      onSuccess();
      message.success('Collateral successfully uploaded.');
      setVisibility(false);
    } catch (error) {
      message.destroy();
      message.error(error.response.data.message);
    }
  }

  const clearValues = () => {
    if (location !== undefined) {
      location.name = '';
      location.contactNumber = '';
      location.cancelContactNumber = '';
      location.uniqueId = '';
      location.childrenAllowed = false;
      location.childrenTypeName = '';
      location.openingHour = '';
      location.closingHour = '';
      location.visitCapacity = '';
    }
  };

  return (
    <Modal
      title={
        <Title
          level={4}
          className='mb-0'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeOutlined className='mr-2' /> {title} Location
        </Title>
      }
      visible={visible}
      onCancel={() => {
        setVisibility(false);
        clearValues();
      }}
      footer={null}
      destroyOnClose={true}
      keyboard={false}
      maskClosable={false}
    >
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: location?.name,
          uniqueId: location?.uniqueId,
          childrenAllowed: location?.childrenAllowed,
          childrenTypeName: location?.childrenTypeName,
          openingHour: location?.openingHour,
          closingHour: location?.closingHour,
          visitCapacity: location?.visitCapacity,
        }}
        // validationSchema={CreateLocationSchema}
        onSubmit={(values) => create(values)}
        render={() => (
          <Form layout='vertical' colon={false}>
            <Form.Item label='Name' name='name' className='mb-4'>
              <Input
                name='name'
                value={location?.name}
                onChange={(value) => {
                  location.name = value.target.value;
                }}
                autoFocus
              />
            </Form.Item>
            <Form.Item label='Contact Number' name='contactNumber' className='mb-4'>
              <Input
                name='contactNumber'
                value={location?.contactNumber}
                onChange={(value) => {
                  location.contactNumber = value.target.value;
                }}
              />
            </Form.Item>
            <Form.Item label='Cancellation Contact Number' name='cancelContactNumber' className='mb-4'>
              <Input
                name='cancelContactNumber'
                value={location?.cancelContactNumber}
                onChange={(value) => {
                  location.cancelContactNumber = value.target.value;
                }}
              />
            </Form.Item>            
            <Form.Item label='Public Form Id' name='uniqueId' className='mb-4'>
              <Input
                name='uniqueId'
                value={location?.uniqueId}
                onChange={(value) => {
                  location.uniqueId = value.target.value;
                }}
              />
            </Form.Item>
            <Form.Item
              label='Sublocation'
              name='childrenAllowed'
              className='mb-4'
            >
              <Checkbox
                name='childrenAllowed'
                checked={location?.childrenAllowed}
                onChange={(value) => {
                  location.childrenAllowed = value.target.checked;
                }}
              />{' '}
              This location can have sublocations
            </Form.Item>
            <Form.Item
              label='Sublocations Name'
              name='childrenTypeName'
              className='mb-4'
            >
              <Input
                name='childrenTypeName'
                value={location?.childrenTypeName}
                onChange={(value) => {
                  location.childrenTypeName = value.target.value;
                }}
              />
            </Form.Item>
            <Row>
              <Space>
                <Col>
                  <Form.Item
                    label='Opening Hour'
                    name='openingHour'
                    className='mb-4'
                  >
                    <Input
                      name='openingHour'
                      type='time'
                      value={location?.openingHour}
                      onChange={(value) => {
                        location.openingHour = value.target.value;
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label='Closing Hour'
                    name='closingHour'
                    className='mb-4'
                  >
                    <Input
                      name='closingHour'
                      type='time'
                      value={location?.closingHour}
                      onChange={(value) => {
                        location.closingHour = value.target.value;
                      }}
                    />
                  </Form.Item>
                </Col>
              </Space>
            </Row>
            <Form.Item
              label='Visit Capacity'
              name='visitCapacity'
              className='mb-4'
            >
              <Input
                name='visitCapacity'
                value={location?.visitCapacity}
                onChange={(value) => {
                  location.visitCapacity = value.target.value;
                }}
              />
            </Form.Item>

            <Row gutter={4} className='d-flex justify-content-end'>
              <Col>
                <Button
                  type='dashed'
                  shape='round'
                  onClick={() => setVisibility(false)}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <SubmitButton>{submitText}</SubmitButton>
              </Col>
            </Row>
          </Form>
        )}
      />
    </Modal>
  );
};

export default LocationForm;
