import {BASE_API_URL} from 'api/config';
import {createAxios} from './axios';

const dashboardApi = createAxios({
  baseURL: BASE_API_URL + '/dashboard'
});

export const getDashboardSummary = params => {
  return dashboardApi.get(`/summary`, {
    params
  })
}

export const getDailyAppointmentData = params => {
  return dashboardApi.get('/daily')
}