import axios from 'axios';
import BaseService from "./BaseService";

const SYSTEM_PROPERTY_API_BASE_URL = "/api/settings";

class SystemPropertyService extends BaseService {

    constructor() {
        super(SYSTEM_PROPERTY_API_BASE_URL);
    }

    getSystemProperties(page, start, length, search, sortOrder, sortDirection){
        return axios.get(SYSTEM_PROPERTY_API_BASE_URL + '/setting', {
            timeout: 5000,
            params: {
                page: page,
                draw: 1,
                start: start,
                sortOrder: sortOrder,
                sortDirection: sortDirection,
                length: length,
                search: search
            }
        });
    }

}

export default new SystemPropertyService()