import React, {useEffect} from 'react';
import { message, Modal } from "antd";
import titleize from 'titleize';
import parse from 'html-react-parser';

const DeleteModal = ({ entity, visible, setVisibility, entityId, deleteFunction, onSuccess }) => {
    const titleHTML = `<span style="color:#ff4d4f;font-weight: bold;">Delete ${titleize(entity)}</span>`.replaceAll(/<p>|<\/p>/g, '');
    const contentHTML = `Are you sure you want to delete this <strong>${entity}</strong>?`.replaceAll(/<p>|<\/p>/g, '');
    useEffect(() => {
        if (visible) {
            Modal.confirm({
                title: parse(titleHTML),
                content: parse(contentHTML),
                okText: 'Delete',
                cancelText: 'Cancel',
                maskClosable: true,
                icon: () => <></>,
                closable: true,
                okType: 'danger',
                onOk: async () => {
                    await deleteFunction(entityId);
                    onSuccess();
                    message.success(`${titleize(entity)} successfully deleted.`)
                    setVisibility(false)
                },
                onCancel: () => {
                    setVisibility(false)
                }
            });
        }
    }, [visible]);

    return (<></>)
}

export default DeleteModal;