import {Card, Col, message, Modal, Radio, Row, Select, Space, Typography} from "antd";
import {Formik} from "formik";
import {Form, Input, ResetButton, SubmitButton} from "formik-antd";
import React, {useEffect, useRef, useState} from "react";
import * as yup from "yup";
import {createVisitor} from "api/visits"
import QrReader from 'react-qr-reader'

const {Title, Text} = Typography;
const CreateCollateralSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Too short.")
    .max(30, "Too long.")
    .required("Please enter your first name."),
  lastName: yup
    .string()
    .min(2, "Too short.")
    .max(255, "Too long.")
    .required("Please enter your last name."),
  email: yup
    .string()
    .email(),
  contactNumber: yup
    .number()
});

const ScanQrCode = ({visible, setVisibility, onSuccess}) => {
  const [files, setFiles] = useState([]);
  const formikRef = useRef(null);
  const {Option} = Select;
  const [type, setType] = useState('VISITOR')
  const [contactMethod, setContactMethod] = useState('BOTH')
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState();
  useEffect(() => {
    (async () => {
      // let response = await getAllLocations();
      // setHospitalOptions(response.data.data)
    })();
  }, [])

  function onChange(value) {
    setSelectedHospital(value);
  }

  function onBlur() {
    console.log('blur');
  }

  function onFocus() {
    console.log('focus');
  }

  function onSearch(val) {
    console.log('search:', val);
  }

  function onRemoveFile(file) {
    const index = files.indexOf(file);
    const newFiles = files.slice();
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible])

  async function create(values) {
    try {
      values.type = type;
      values.preferredContactMethod = contactMethod;

      let visit = {
        location: selectedHospital,
        visitor: {
          contactNumber: values.contactNumber,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          preferredContactMethod: values.preferredContactMethod
        },
        type: values.type
      }


      const form = new FormData();
      message.loading('Creating visitor...', 0);
      let data = new Blob([JSON.stringify(visit)], {type: 'application/json'});
      form.append("data", data);
      form.append("file", values.file);

      const res = await createVisitor(form);
      console.log(res);


      // @TODO: Remove in production, or maybe change it later on

      // Object.keys(values).forEach(key => form.append(key, values[key]));
      // files.forEach(file => form.append("file", file, file.name));
      // const res = await createCollateral(form);
      message.destroy();
      message.success('New visitor created.');
      onSuccess();
      setVisibility(false)
    } catch (error) {
      message.destroy();
      message.error(error);
    }
  }

  function reset() {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  }

  const style = {width: '90%'};

  return (
    <Modal
      title={<Title level={4} className="mb-0" style={{display: "flex", alignItems: "center"}}>Scan QR Code</Title>}
      visible={visible}
      onCancel={() => setVisibility(false)}
      footer={null}
      width={'50%'}
    >
      <QrReader
        style={{width:'100%'}}
      />
    </Modal>
  );
};

export default ScanQrCode;
