import { createAxios } from './axios';
import { BASE_API_URL } from './config';

const brandingApi = createAxios({
    baseURL: `${BASE_API_URL}/branding`
});

export const getBrandingByTenant = tenantUniqueId => {
    return brandingApi.get(`/${tenantUniqueId}`);
};

export const getBrandingLogoByTenant = tenantUniqueId => {
    return `${BASE_API_URL}/branding/${tenantUniqueId}/logo`;
};

export const updateThemeSettings = (tenantUniqueId, payload) => {
    let branding = {...payload};
    delete branding.logo;

    let formData = new FormData();

    formData.append('logo', payload.logo);
    formData.append('branding', JSON.stringify(branding));

    return brandingApi({
        url: `${tenantUniqueId}`,
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}