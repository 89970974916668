import * as Msal from "msal";
import {BASE_URL, msalConfig} from "./config";

const msal = new Msal.UserAgentApplication({
    auth: {
        clientId: msalConfig.appId,
        authority: `https://login.microsoftonline.com/${msalConfig.tenantId}`,
        redirectUri: BASE_URL
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
    }
});

function login() {
    msal.loginRedirect({ scopes: msalConfig.scopes, } );
}

function logout() {
    console.log('logging out')
    msal.logout();
}

function validate() {
    return msal.ssoSilent({ scopes: msalConfig.scopes, sid: msalConfig.appId });
}

export { msal, login, logout, validate };