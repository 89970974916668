import {keycloak} from 'api/keycloak';
import {validate} from 'api/msal';
import {getToken} from 'components/token';
import {FORBIDDEN_URL, msalConfig} from 'api/config';

const instance = {}
export const AuthUtils = () => {
 return instance;
}

AuthUtils.keycloak = () => {
    return keycloak.init({
        onLoad: 'check-sso',
        // checkLoginIframe: false
    });
}

AuthUtils.getKeycloakToken = () => {
    return keycloak.token;
}

AuthUtils.handleKeycloakError = error => {
    // @todo make dialogue
    if (error.response?.status === 401) {
        console.log(error.response)
        // keycloak.logout();
    }
    // return Promise.reject(error);
}

AuthUtils.msAuth = () => {
    return validate();
}

AuthUtils.getMSAuthToken = () => {
    return getToken();
}

AuthUtils.handleMsAuthError = error => {
    if(error.response?.status === 401) {
        // clear all msal related data in local storage
        for(let i = 0 ; i < localStorage.length; ++ i) {
            if(localStorage.key(i).startsWith("msal")
                || localStorage.key(i).indexOf(msalConfig.appId) >= 0) {
                localStorage.removeItem(localStorage.key(i));
            }
        }

        // clear all cookies on this domain
        document.cookie.split(';').forEach(cookie => {
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        });

        window.location.href = FORBIDDEN_URL;
    }
}

// todo general method
//  supposedly be able to set what kind of authentication
//  should generally be used globally
AuthUtils.authenticate = () => {
}

// todo general method
//  supposedly be able to set what kind of authentication
//  should generally be used globally
AuthUtils.getToken = () => {
}