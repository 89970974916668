import React from 'react';

import { useSelector } from 'react-redux';
import FiveFacesLogo from 'assets/images/5f-dark-logo.png'

const Footer = () => {
    const tenantState = useSelector(state => state.tenant);

    return (
        // tenantState.branding.isPoweredByVisible === true || tenantState.branding.isPoweredByVisible === undefined ? 
        <>
            <div className='text-right'>
                <small>
                <strong style={{marginRight: '35px'}}>Powered by:</strong><br/>
                <a href='https://fivefaces.com.au' target="_blank">
                    <img src={FiveFacesLogo}
                        alt='5F Logo'
                        style={{width: '110px', height: 'auto'}}
                    />
                </a>
                </small>
            </div>
        </> 
        // : <div style={{ padding: '20px' }}></div>
    );
};

export default Footer;