import * as React from "react"

function ResourceIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.25 21H3.75A2.752 2.752 0 011 18.25V2.75A2.752 2.752 0 013.75 0h11.5A2.752 2.752 0 0118 2.75v15.5A2.752 2.752 0 0115.25 21z"
        fill="#1976d2"
      />
      <path
        d="M10 0H3.75A2.752 2.752 0 001 2.75v15.5A2.752 2.752 0 003.75 21H10z"
        fill="#1667b7"
      />
      <path
        d="M20.25 24H8.75A2.752 2.752 0 016 21.25V6.75A2.752 2.752 0 018.75 4h11.5A2.752 2.752 0 0123 6.75v14.5A2.752 2.752 0 0120.25 24z"
        fill="#eceff1"
      />
      <g fill="#607d8b">
        <path d="M18.25 17h-7.5a.75.75 0 010-1.5h7.5a.75.75 0 010 1.5zM18.25 21h-7.5a.75.75 0 010-1.5h7.5a.75.75 0 010 1.5zM18.25 13.5h-7.5a.75.75 0 010-1.5h7.5a.75.75 0 010 1.5zM18.25 9.5h-7.5a.75.75 0 010-1.5h7.5a.75.75 0 010 1.5z" />
      </g>
      <path
        d="M14.5 4H8.75A2.752 2.752 0 006 6.75v14.5A2.752 2.752 0 008.75 24h5.75v-3h-3.75a.75.75 0 010-1.5h3.75V17h-3.75a.75.75 0 010-1.5h3.75v-2h-3.75a.75.75 0 010-1.5h3.75V9.5h-3.75a.75.75 0 010-1.5h3.75z"
        fill="#cdd0d2"
      />
      <path
        d="M14.5 15.5h-3.75a.75.75 0 000 1.5h3.75zM14.5 19.5h-3.75a.75.75 0 000 1.5h3.75zM14.5 12h-3.75a.75.75 0 000 1.5h3.75zM14.5 8h-3.75a.75.75 0 000 1.5h3.75z"
        fill="#546d79"
      />
    </svg>
  )
}

export default ResourceIcon
