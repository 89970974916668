import {
    SET_EDITOR_UTILITIES,
    SET_EDITOR_FOCUSED,
    SET_CONTENT_LOADED
} from 'redux/actions/types/formBuilder';

const INITIAL_STATE = {
    utilities: null,
    isEditorFocused: false,
    isContentLoaded: false,
};

const formBuilderReducer = (state = INITIAL_STATE, action) => {
    const { type, utilities, isEditorFocused, isContentLoaded } = action;

    switch (type) {
        case SET_EDITOR_UTILITIES:
            return {
                ...state,
                utilities
            }

        case SET_EDITOR_FOCUSED:
            return {
                ...state,
                isEditorFocused
            }

        case SET_CONTENT_LOADED:
            return {
                ...state,
                isContentLoaded
            }

        default:
            return state
    }
};

export default formBuilderReducer;