import { BASE_API_URL } from 'api/config';
import { createAxios } from './axios';

const permissionsApi = createAxios({
    baseURL: BASE_API_URL + '/permission'
});

export const fetchAllPermissions = () => {
    return permissionsApi.get();
};

export const createPermission = (payload) => {
    return permissionsApi.post(
        '', 
        JSON.stringify(payload), 
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
};

export const updatePermission = (uniqueId, payload) => {
    return permissionsApi.put(
        `${uniqueId}`, 
        JSON.stringify(payload),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
}

export const deletePermission = (uniqueId) => {
    return permissionsApi.delete(`/${uniqueId}`);
};
