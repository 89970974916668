export function systemPropertiesHasErrored(state = false, action) {
    switch (action.type) {
        case 'SYSTEM_PROPERTIES_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function systemPropertiesIsLoading(state = false, action) {
    switch (action.type) {
        case 'SYSTEM_PROPERTIES_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function systemProperties(state = [], action) {
    switch (action.type) {
        case 'SYSTEM_PROPERTIES_FETCH_DATA_SUCCESS':
            return action.systemProperties;
        case 'SYSTEM_PROPERTIES_REMOVE_SYSTEM_PROPERTY_SUCCESS':
            const data = state.data;
            // this bit is fancy javascript to replace the data with a new array
            return {
                ...state,
                data: remove(data, data.findIndex(data => data.id === action.systemProperties))
            }
        default:
            return state;
    }
}

const remove = (systemProperties,index) => {
    return [...systemProperties.slice(0,index),
        ...systemProperties.slice(index+1,systemProperties.length)];
};