export const TOGGLE_SIDEBAR_VISIBILITY = 'TOGGLE_SIDEBAR_VISIBILITY';
export const SET_STATUS_MODAL_PROPS = 'SET_STATUS_MODAL_VISIBILITY';
export const SET_MESSAGE_MODAL_VISIBILITY = 'SET_MESSAGE_MODAL_VISIBILITY';
export const SET_COLLATERAL_MODAL_VISIBILITY = 'SET_COLLATERAL_MODAL_VISIBILITY';
export const SET_NOTE_MODAL_VISIBILITY = 'SET_NOTE_MODAL_VISIBILITY';
export const ACTIVE_APPOINTMENT_ID = 'ACTIVE_APPOINTMENT_ID';
export const SET_COLLATERAL_APPOINTMENT_ID = 'SET_COLLATERAL_APPOINTMENT_ID';
export const SET_COLLATERAL_DETAILS_MODAL_VISIBILITY = 'SET_COLLATERAL_DETAILS_MODAL_VISIBILITY';
export const SET_ACTIVE_COLLATERAL = 'SET_ACTIVE_COLLATERAL';
export const SET_COLLATERAL_DELETE_MODAL_VISIBILITY = 'SET_COLLATERAL_DELETE_MODAL_VISIBILITY';
export const SET_HELPER_ITEM_VISIBILITY = 'SET_HELPER_ITEM_VISIBILITY';
export const SET_HELPER_EDIT_ITEM = 'SET_HELPER_EDIT_ITEM';
export const SET_DELETE_MODAL_VISIBILITY = 'SET_DELETE_MODAL_VISIBILITY';
export const SET_TOPBAR_VISIBILITY = 'SET_TOPBAR_VISIBILITY';
export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';
export const SET_BREADCRUMBS_TEXT = 'SET_BREADCRUMBS_TEXT';
export const SET_FORM_BUILDER_ACTIVE_TAB = 'SET_FORM_BUILDER_ACTIVE_TAB';
export const SET_FORM_BUILDER_EVENT_SELECTING = 'SET_FORM_BUILDER_EVENT_SELECTING';