import {BASE_API_URL} from 'api/config';
import {createAxios} from './axios';

const visitorApi = createAxios({
    baseURL: BASE_API_URL + '/visits'
});

export const getVisitors = (params) => {
    return visitorApi.get('/datatable', {
        params: params
    });
}

export const getVisitorById = id=> {
    return visitorApi.get(`${id}`);
}

export const deleteVisit = id => {
    return visitorApi.delete(`/${id}`);
}

export const createVisitor = visitor => {
    return visitorApi.post('process', visitor, {
        headers: {
            'Content-Type': "application/octet-stream"
        },
    })
}

export const updateVisitor = (id, visitor) => {
    return visitorApi.put(`/${id}`, visitor);
}

export const cancelVisit = id => {
    return visitorApi.post(`${id}/cancel`);
}

export const checkIn = id => {
    return visitorApi.post(`${id}/checkIn`);
}

export const checkOut = id => {
    return visitorApi.post(`${id}/checkOut`);
}