import React from 'react';
import ColorPaletteItem from 'components/BrandingSettings/ColorPaletteItem';
import { useDispatch, useSelector } from 'react-redux';
import { setBrandingList } from 'redux/actions/branding';

export default ({ currentBranding, palettes, isReadOnly }) => {
    const brandingState = useSelector(state => state.branding)
    const dispatch = useDispatch();

    const renderGroupedOrSingleColorPalette = (paletteParent, paletteItems) => {
        if (Object.keys(paletteItems).length > 0) {
            return  <div 
                        key={paletteParent}
                        style={{ 
                            margin: '5px 10px'
                        }}
                    >
                        <p style={{ 
                            textTransform: 'capitalize', 
                            fontWeight: '700' 
                        }}>
                            {paletteParent}
                        </p>
                        {Object.keys(paletteItems).map((paletteItemKey) => {
                            return  <ColorPaletteItem
                                        key={paletteItemKey}
                                        label={paletteItemKey} 
                                        value={paletteItems[paletteItemKey]}
                                        onChangeComplete={(color) => {
                                            const { theme } = currentBranding;
                                            let brandingList = brandingState.list;

                                            const currentBrandingIdx = brandingList.findIndex(brandingItem => brandingItem.tenantUniqueId === currentBranding.tenantUniqueId);

                                            let newBrandingItemState = {...currentBranding};

                                            let newThemePalette = theme.palette;

                                            newThemePalette[paletteParent][paletteItemKey] = color.hex;

                                            newBrandingItemState = {
                                                ...newBrandingItemState,
                                                theme: {
                                                    ...theme,
                                                    palette: {...newThemePalette}
                                                }
                                            }

                                            brandingList[currentBrandingIdx] = newBrandingItemState;

                                            dispatch(setBrandingList(brandingList));
                                        }}
                                        isReadOnly={isReadOnly}
                                    />
                        })}
                    </div>;
        }
    }

    return <>
                {
                    palettes && Object.keys(palettes).map(paletteKey => {
                            return renderGroupedOrSingleColorPalette(paletteKey, palettes[paletteKey])
                        }    
                    )
                }
            </>
}