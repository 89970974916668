export function notesHasErrored(state = false, action) {
    switch (action.type) {
        case 'NOTES_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function notesIsLoading(state = false, action) {
    switch (action.type) {
        case 'NOTES_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function notes(state = [], action) {
    switch (action.type) {
        case 'NOTES_FETCH_DATA_SUCCESS':
            return action.notes;
        case 'NOTES_REMOVE_NOTE_SUCCESS':
            const data = state.data;
            // this bit is fancy javascript to replace the data with a new array
            return {
                ...state,
                data: remove(data, data.findIndex(data => data.id === action.notes))
            }
        default:
            return state;
    }
}

const remove = (notes,index) => {
    return [...notes.slice(0,index),
        ...notes.slice(index+1,notes.length)];
};