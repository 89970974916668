import { SET_STAGING } from 'redux/actions/types/systemProperties';

const INITIAL_STATE = {
    staging: {}
};

const systemPropertiesReducer = (
    state = INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case SET_STAGING: {
            return {
                ...state,
                staging: action.data
            }
        }

        default:
            return state;
    }
}

export default systemPropertiesReducer;
