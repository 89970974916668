import { Table, message, Empty } from 'antd';
import FormItems from 'pages/Settings/FormSelection/FormItems';
import { createGlobalStyle } from 'styled-components';
import { FcAbout } from 'react-icons/all';

message.config({
  duration: 2,
});

const GlobalStyle = createGlobalStyle`
    .appointment-form-list {
        .ant-empty-description {
            color: #a7a7a7;
        }
    }
`;

const List = ({ columns, listData }) => {
    return <>
            <GlobalStyle />
            {listData &&
            listData.length > 0 ? (
                <Table
                columns={columns}
                showHeader={false}
                bordered
                pagination={false}
                expandable={{
                    expandedRowRender: (record) => {
                    return (
                        <FormItems
                        appointmentCodeUniqueId={record.appointmentCodeUniqueId}
                        key={record.uniqueId}
                        data={record?.forms}
                        schedule={record?.schedule}
                        />
                    );
                    },
                    defaultExpandAllRows: true,
                }}
                dataSource={listData}
                />
            ) : (
                <Empty 
                    className='appointment-form-list'
                    description='No Appointment Type configured' 
                    image={
                        <FcAbout 
                            fontSize='6rem' 
                            style={{
                                opacity: '.5'
                            }} 
                        />
                    } 
                />
            )}
        </>
};

export default List;
