import { BASE_API_URL } from 'api/config';
import { createAxios } from './axios';

const appointmentCollateralsApi = createAxios({
  baseURL: BASE_API_URL + '/appointment-collaterals',
});

export const addCollateralToAppointment = (payLoad) => {
  return appointmentCollateralsApi.post('', payLoad);
};

export const getCollateralsDatatableById = (id, params) => {
  return appointmentCollateralsApi.get(``, {
    params: {
      appointmentId: id,
      ...params,
    },
  });
};

export const openAppointmentCollateralFile = (id) => {
  return appointmentCollateralsApi.get(`/${id}/url`);
};

export const deleteCollateralToAppointment = (id) => {
  return appointmentCollateralsApi.delete(`/${id}`);
};
