import {BASE_API_URL} from 'api/config';
import {createAxios} from './axios';

const settingsApi = createAxios({
  baseURL: BASE_API_URL + '/system-properties'
});

export const getSettingsDatatable = params => {
  return settingsApi.get(``, {
    params: params
  });
}

export const createSettings = settings => {
  return settingsApi.post(``, settings);
}

export const deleteSettings = name => {
  return settingsApi.delete(`/${name}`);
}

export const getOptions = () => {
  return settingsApi.get(`/options`);
}

export const getAllProperties = () => {
  return settingsApi.get(``, {
    params: {
      length: 100,
      sortColumn: 'name',
      sortDirection: 'ASC'
    }
  });
}